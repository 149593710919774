import {Component} from 'react';

import {localPlayer} from '../scripts/game/player.js';

/**
 * Handles the display of an arbitrary list of players.
 */
export default class PlayerList extends Component {
	constructor(props) {
		super(props);
	}

	shouldComponentUpdate(nextProps) {
		// only change if the number of players changes or the host changes
		// unlike room_browser.js, which can't check if the number of rooms changes for an update, we can check if the number of players updates here, because we receive an event for each player that joins / leaves
		return this.props.players.length !== nextProps.players.length
			|| this.props.host !== nextProps.host;
	}

	render() {
		return (
			<ul className="no-bullets">
				{
					this.props.players.length ?
						this.props.players.map(p => {
							let modifiers = '';
							if (p.uuid === localPlayer.uuid) modifiers += ' (you)';
							if (p.uuid === this.props.host) modifiers += ' (host)';

							return (
								<li key={p.uuid}>
									<span style={{color: `#${p.color.toString(16).padStart(6, '0')}`}}>⬤</span> {p.username}
									{
										modifiers
											&& <span className="italic">{modifiers}</span>
									}
								</li>
							);
						}) :
						<li className="italic">none</li>
				}
			</ul>
		);
	}
}
