import {ShockwaveFilter} from 'pixi-filters';
import {Container, Filter} from 'pixi.js';

/**
 * Manager for filters applied to a PIXI container.
 */
export default class FilterManager {
	/**
	 * @param {Container} container The container to apply filters to.
	 */
	constructor(container) {
		this.container = container;

		/**
		 * @type {Array<Filter>}
		 */
		this.managed = [];
	}

	/**
	 * Adds a filter to the container and returns a reference to it. The filter will be unmanaged; i.e. the caller is responsible for removing it from the container later, by calling {@link FilterManager#remove}.
	 * @param {Filter} filter The filter to add.
	 */
	add(filter) {
		if (!this.container.filters) this.container.filters = [];
		this.container.filters.push(filter);

		return filter;
	}

	/**
	 * Adds a filter to the container. The filter will be automatically animated and managed by the manager. To ensure progress is made on the filter's animation, the manager's {@link FilterManager#update} method must be called every frame.
	 * @param {Filter} filter The filter to add.
	 */
	addManaged(filter) {
		this.add(filter);
		this.managed.push(filter);
	}

	/**
	 * Removes a filter from the container.
	 * @param {Filter} filter The filter to remove.
	 */
	remove(filter) {
		if (!this.container.filters) return;
		this.container.filters = this.container.filters.filter(f => f !== filter);
	}

	/**
	 * Updates all managed filters.
	 */
	update() {
		for (let i = 0; i < this.managed.length; ++i) {
			const filter = this.managed[i];

			if (filter instanceof ShockwaveFilter) {
				filter.time += 0.06;
				filter.amplitude -= 0.4;

				if (filter.amplitude <= 0) {
					this.managed.splice(i, 1);
					this.remove(filter);
					--i;
				}
			}
		}
	}
}
