import camera from './camera.js';
import {app, state, tutorialUI, ui} from './global.js';
import {particleContainer, particleSystems} from './particles.js';
import {text} from './text.js';
import {connection} from '../net/ws.js';

let outdatedClient = false;
let fps = 0;

setInterval(() => fps = Math.round(app.ticker.FPS), 1000);

window.addEventListener('resize', () => {
	const canvas = document.getElementById('canvas');
	canvas.width = window.innerWidth;
	canvas.height = window.innerHeight;
});

const canvas = document.getElementById('canvas');
canvas.tabIndex = 0;

for (const key in particleSystems) {
	app.stage.addChild(particleSystems[key].graphics || particleSystems[key].sprite);
}

app.stage.addChild(camera.graphics, camera.shadowedGraphics, particleContainer, ui, tutorialUI, text.stats);
app.stage.filterArea = app.screen;
app.stage.interactiveChildren = false;

let updateGame, updateEditor;

app.ticker.add(_ => {
	text.stats.text = `Ping: ${connection?.pingProp || 'N/A'}\nFPS: ${fps}`;

	for (const key in particleSystems) {
		particleSystems[key].update?.();
		particleSystems[key].draw(camera.pos.display);
	}

	const translation = {x: window.innerWidth / 2 - camera.pos.display.x * camera.scale, y: window.innerHeight / 2 - camera.pos.display.y * camera.scale};

	camera.graphics
		.clear()
		.setTransform(translation.x, translation.y, camera.scale, camera.scale)
		.removeChildren();

	camera.shadowedGraphics
		.clear()
		.setTransform(translation.x, translation.y, camera.scale, camera.scale)
		.removeChildren();

	particleContainer.setTransform(translation.x, translation.y, camera.scale, camera.scale);

	ui.clear().removeChildren();
	tutorialUI.clear().removeChildren();

	if (state === 1) {
		if (updateGame) {
			updateGame();
		} else{
			import(/* webpackChunkName: 'game' */ './game.js')
				.then(({updateGame: updateGameModule}) => updateGame = updateGameModule);
		}
	} else if (state === 2) {
		if (updateEditor) {
			updateEditor();
		} else {
			import('./editor.js')
				.then(({updateEditor: updateEditorModule}) => updateEditor = updateEditorModule);
		}
	}

	return;

	noStroke();

	if (outdatedClient) {
		textInfo.outdatedClient = lerp(textInfo.outdatedClient, 80, 0.1);

		fill(255, 0, 0);
		textAlign(CENTER, CENTER);
		textSize(45);
		text('Your client was outdated with a recent server update.\nRefresh to apply the new changes.', halfWidth, textInfo.outdatedClient);
	}
});