import {PureComponent} from 'react';

import Button from '../base/button.js';
import {emitter as ui} from '../../scripts/game/editor.js';
import {idToName} from '../../scripts/game/entities.js';
import {focus} from '../../scripts/utils.js';

/**
 * See documentation for the Metadata component for explanation of this variable.
 * @type {{shape: string, x: number, y: number, color: number, index: number?} | {entity: *, index: number}?}
 */
let entity = null;
ui.addEventListener('add', e => entity = Object.assign({adding: true}, e.detail));
ui.addEventListener('select', e => entity = Object.assign({adding: false}, e.detail));

/**
 * Represents the UI used to add and edit entities in a level in the editor.
 */
export default class Entity extends PureComponent {
	constructor(props) {
		super(props);
		this.state = {
			/**
			 * Whether the entity is being added or selected.
			 */
			adding: false,

			/**
			 * The entity that is currently being added / selected. See `editor.addingEntity`.
			 */
			entity: -1,

			/**
			 * The shape of the entity. Can be either 'rect' or 'circle'.
			 *
			 * This is only used for walls.
			 */
			shape: 'rect',

			/**
			 * The x position of the entity.
			 */
			x: 0,

			/**
			 * The y position of the entity.
			 */
			y: 0,

			/**
			 * The width of the entity.
			 *
			 * This is only used for rectangular walls.
			 */
			w: 0,

			/**
			 * The height of the entity.
			 *
			 * This is only used for rectangular walls.
			 */
			h: 0,

			/**
			 * The radius of the entity.
			 *
			 * This is only used for circular walls.
			 */
			r: 0,

			/**
			 * The color of the entity.
			 *
			 * This is only used for portals.
			 */
			color: 0,

			/**
			 * The index of the entity.
			 *
			 * This is only used for finishes or spawns.
			 */
			index: 0,

			/**
			 * The delay of the entity.
			 *
			 * This is only used for spawns.
			 */
			spawnDelay: 0,

			/**
			 * The width and height of the level borders (in cells). This is used to determine the maximum values for the position fields.
			 */
			levelSize: {w: 60, h: 60},
		};

		this.onAdd = e => {
			this.setState({
				adding: true,
				entity: e.detail.entity,
				shape: e.detail.shape,
				x: e.detail.x,
				y: e.detail.y,
				color: e.detail.color,
				index: e.detail.index,
				levelSize: e.detail.levelSize,
			});
		};
		this.onUpdate = e => {
			this.setState({
				x: e.detail.x,
				y: e.detail.y,
				w: e.detail.w ?? this.state.w,
				h: e.detail.h ?? this.state.h,
				r: e.detail.r ?? this.state.r,
			});
		};
		this.onSelect = e => {
			this.setState({
				adding: false,
				entity: e.detail.entity,
				shape: e.detail.shape,
				x: e.detail.x,
				y: e.detail.y,
				w: e.detail.w ?? this.state.w,
				h: e.detail.h ?? this.state.h,
				r: e.detail.r ?? this.state.r,
				color: e.detail.color ?? this.state.color,
				index: e.detail.index ?? this.state.index,
				spawnDelay: e.detail.spawnDelay ?? this.state.spawnDelay,
				levelSize: e.detail.levelSize,
			});
		};

		// if we receive this event, it means that the user right-clicked on the entity to delete it
		// so we do not need to recommunicate to the editor to delete the entity (`this.props.onDelete()`, which would cause two entities to be deleted)
		// we just need to close the entity panel
		this.onDelete = _ => this.props.onCancel();
	}

	componentDidMount() {
		if (entity) {
			this.setState(entity);
			entity = null;
		}

		ui.addEventListener('add', this.onAdd);
		ui.addEventListener('update', this.onUpdate);
		ui.addEventListener('select', this.onSelect);
		ui.addEventListener('delete', this.onDelete);
	}

	componentWillUnmount() {
		ui.removeEventListener('add', this.onAdd);
		ui.removeEventListener('update', this.onUpdate);
		ui.removeEventListener('select', this.onSelect);
		ui.removeEventListener('delete', this.onDelete);
	}

	/**
	 * The user changed the position / size of the entity being added.
	 * @param {Event} e
	 */
	onChange(e) {
		switch (e.target.name) {
			case 'shape':
				this.setState({[e.target.name]: e.target.value});
				ui.change('entity', {shape: e.target.value});
				focus();
				break;

			case 'color':
				const value = parseInt(e.target.value.substring(1), 16);
				this.setState({[e.target.name]: value});
				ui.change('entity', {color: value});
				focus();
				break;

			default:
				this.setState({[e.target.name]: parseInt(e.target.value)});
				ui.change('entity', {[e.target.name]: parseInt(e.target.value)});
				break;
		}
	}

	render() {
		return (
			<>
				<h2 id="editor-info-header">{`${this.state.adding ? 'Adding' : 'Selected'} ${idToName(this.state.entity)}`}</h2>
				<div id="editor-pos">
					<label>Position</label>
					<input type="number" className="editor-x" placeholder="x position" name="x" min="0" max={this.state.levelSize.w * 10} step="10" value={this.state.x} onChange={this.onChange.bind(this)}/>
					<input type="number" className="editor-y" placeholder="y position" name="y" min="0" max={this.state.levelSize.h * 10} step="10" value={this.state.y} onChange={this.onChange.bind(this)}/>
				</div>

				{
					// show size for walls, portals, and finishes
					this.state.entity !== 3
						? <div id="editor-size">
							{
								this.state.entity === 0 && this.state.shape === 'circle'
									? <>
										<label>Radius</label>
										<input type="number" className="editor-field" placeholder="radius" name="r" min="10" max={this.state.levelSize.w * this.state.levelSize.h} step="10" value={this.state.r} onChange={this.onChange.bind(this)}/>
									</>
									: <>
										<label>Size</label>
										<input type="number" className="editor-x" placeholder="width" name="w" min="10" max={this.state.levelSize.w * 10} step="10" value={this.state.w} onChange={this.onChange.bind(this)}/>
										<input type="number" className="editor-y" placeholder="height" name="h" min="10" max={this.state.levelSize.h * 10} step="10" value={this.state.h} onChange={this.onChange.bind(this)}/>
									</>
							}
						</div>
						: null
				}

				{
					// show shape switcher for walls
					this.state.entity === 0
						? <div id="editor-shape">
							<label>Shape</label>
							<div id="shape-selector" className="switcher">
								<input type="radio" name="shape" value="rect" id="rect" checked={this.state.shape === 'rect'} onChange={this.onChange.bind(this)}/>
								<label htmlFor="rect">rectangle</label>
								<input type="radio" name="shape" value="circle" id="circle" checked={this.state.shape === 'circle'} onChange={this.onChange.bind(this)}/>
								<label htmlFor="circle">circle</label>
							</div>
						</div>
						: null
				}

				{
					// show color for portals
					this.state.entity === 1
						? <div id="editor-color">
							<label>Color</label>
							<input type="color" name="color" value={`#${this.state.color.toString(16).padStart(6, '0')}`} onChange={this.onChange.bind(this)}/>
						</div>
						: null
				}

				{
					// show index for finish and spawn
					this.state.entity === 2 || this.state.entity === 3
						? <div id="editor-index">
							<label>Index</label>
							<input type="number" className="editor-field" placeholder="index" name="index" min="0" max="255" value={this.state.index} onChange={this.onChange.bind(this)}/>
						</div>
						: null
				}

				{
					// show spawn delay for spawn
					this.state.entity === 3
						? <div id="editor-spawn-delay">
							<label>Spawn delay</label>
							<input type="number" className="editor-field" placeholder="spawn delay" name="spawnDelay" min="0" max="600" step="5" value={this.state.spawnDelay} onChange={this.onChange.bind(this)}/>
						</div>
						: null
				}

				{
					this.state.adding
						? <Button onClick={this.props.onCancel.bind(this)} value="cancel"/>
						: <>
							<Button onClick={this.props.onDelete.bind(this)} value="delete"/>
							<Button onClick={this.props.onCancel.bind(this)} value="close"/>
						</>
				}
			</>
		);
	}
}
