import {Component} from 'react';

/**
 * A button that handles animating itself when clicked.
 *
 * The props that can be passed to the component are:
 * - `onClick(MouseEvent)` - The function to call when the button is clicked. If the function is async, the component will be disabled while waiting for the function to resolve.
 * - `disabled` - Whether the button is disabled. The button is also disabled when `onClick` was called and is waiting for the function to resolve.
 * - `id` - The HTML ID to apply to the button.
 * - `style` - The style to apply to the button.
 * - `autoClickTime` - Adds an auto-clicker to the button which triggers every `autoClickTime` seconds.
 * - `hidden` - Whether the button is hidden. This is used to disable the auto clicker when the button is hidden.
 * - `value` - The value to display on the button.
 */
export default class Button extends Component {
	constructor(props) {
		super(props);
		this.state = {
			/**
			 * Whether or not the button's action is currently being processed.
			 */
			waiting: false,

			/**
			 * Whether the button has an auto-click timer enabled.
			 */
			autoClick: false,

			/**
			 * The interval ID for the auto-click timer.
			 * @type {number?}
			 */
			autoClickId: null,

			/**
			 * The time remaining on the auto-click timer. When it reaches 0, the button will be clicked.
			 * @type {number?}
			 */
			autoClickTimeRemaining: null,

			/**
			 * The time to reset to when the auto-click timer is reset.
			 */
			autoClickTimeReset: 0,
		};
	}

	componentDidMount() {
		// if the button was passed an auto-click time prop, start the auto-click timer
		if (this.props.autoClickTime) {
			this.setState({
				autoClick: true,
				autoClickId: setInterval(() => {
					if (!this.props.hidden && !this.state.waiting) {
						if (this.state.autoClickTimeRemaining > 0) {
							this.setState({autoClickTimeRemaining: this.state.autoClickTimeRemaining - 1});
						} else {
							this.setState({autoClickTimeRemaining: this.state.autoClickTimeReset});
							this.onClick();
						}
					}
				}, 1000),
				autoClickTimeRemaining: this.props.autoClickTime,
				autoClickTimeReset: this.props.autoClickTime,
			});
		}
	}

	/**
	 * Handle a mouse click on this button.
	 */
	onClick(event) {
		this.setState({
			waiting: true,
			autoClickTimeRemaining: this.state.autoClickTimeReset,
		});
		const result = this.props.onClick(event);

		if (result instanceof Promise) {
			result
				.then(() => this.setState({waiting: false}))
				.catch(e => {
					console.error(e);
					this.setState({waiting: false});
				});
		} else {
			this.setState({waiting: false});
		}
	}

	render() {
		// display the auto-click timer in the value if it's enabled
		const value = this.state.autoClick
			&& `${this.props.value} (${this.state.autoClickTimeRemaining})`
			|| this.props.value;

		return (
			// type="button" prevents button from submitting if used in a form
			<button type="button" id={this.props.id} style={this.props.style} disabled={this.props.disabled || this.state.waiting} onClick={this.onClick.bind(this)}>{value}</button>
		);
	}
}
