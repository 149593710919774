/**
 * Represents a packet sent between the client and server.
 */
export default class Packet {
	/**
	 * @param {string} type The type of the data being sent.
	 * @param {*} data The data being sent.
	 * @param {boolean} event Whether the data being sent is a server event (not a reply, default false). This is value is only used on incoming packets.
	 */
	constructor(type, data, event = false) {
		this.type = type;
		this.data = data;
		this.event = event;
	}
}
