import {Component} from 'react';

/**
 * The component that appears when the user has successfully logged in.
 */
export default class LoginSuccess extends Component {
	constructor(props) {
		super(props);
	}

	shouldComponentUpdate() {
		// only update if the component is visible
		return !this.props.hide;
	}

	render() {
		const className = this.props.signIn === 0 || this.props.hide ? 'dialog hidden' : 'dialog';
		return (
			<div id="sign-in-success" className={className}>
				<h1>welcome{this.props.signIn === 1 ? ' back' : ''}, {this.props.username}!</h1>
				<p>{this.props.signIn === 1 ? 'have fun!' : 'you have successfully registered an account!'}</p>
			</div>
		);
	}
}
