import {PureComponent} from 'react';

import pooled from '../assets/images/pooled.png';
import jas from '../assets/images/jas.png';

import Button from './base/button.js';
import logo from '../logo.png';

/**
 * Represents the about popup.
 */
export default class About extends PureComponent {
	constructor(props) {
		super(props);
	}

	render() {
		return (
			<div className={`${this.props.hide ? 'hidden' : ''} dialog`}>
				<img className="logo" src={logo} draggable="false" alt="logo"/>
				<h2 className="about-line">...exists thanks to these awesome people:</h2>
				<div id="about-credits">
					<div className="about-user-container">
						<img className="about-image" src={pooled} draggable="false" alt="Pooled"/>
						<h3>POOLED#9848</h3>
						<p>Programming</p>
					</div>
					<div className="about-user-container">
						<img className="about-image" src={jas} draggable="false" alt="Jas"/>
						<h3>jas#9295</h3>
						<p>Design, Logo</p>
					</div>
				</div>
				<h2 className="about-line">...including these awesome testers:</h2>
				<table id="about-testers">
					<tbody>
						<tr>
							<td>amu#2097</td>
							<td>banannea#1711</td>
							<td>Hem the Ant#2408</td>
							<td>YumYum#2146</td>
						</tr>
						<tr>
							<td>lam(e)#5785</td>
							<td>Yungkoomin#8419</td>
							<td>laurenlien#8401</td>
							<td>Le Honk#9511</td>
						</tr>
						<tr>
							<td>Amogha#6740</td>
							<td>GoodGuyDolan#6494</td>
							<td>Vikachubro21#3966</td>
							<td>jozh2417#3169</td>
						</tr>
					</tbody>
				</table>
				<Button onClick={this.props.onCloseAbout.bind(this)} value="close"/>
			</div>
		);
	}
}
