import {PureComponent} from 'react';

import Button from './base/button.js';

/**
 * The default options the user can select from in the main menu.
 */
const options = [
	{
		name: 'solo',
		description: 'challenge yourself in Pumpkin\'s classic game mode',
		svg: <>
			<path d="M 250,319.4 C 85.6,319.4 0,416.7 0,472.2 V 500 H 500 V 472.2 C 500,416.7 414.4,319.4 250,319.4 Z" fill="currentColor"/>
			<circle cx="250" cy="140" r="140" fill="currentColor"/>
		</>,
	},
	{
		name: 'multi',
		description: 'find or create a multiplayer room',
		svg: <>
			<defs>
				<clipPath id="trim">
					<path d="M 0,0 L 250,0 L 250,67.5 A 110,110 90 0 0 215,282.5 A 180,180 0 0 0 50,417.5 L 0,417.5 Z"/>
					<path d="M 500,0 L 250,0 L 250,67.5 A 110,110 0 0 1 285,282.5 A 180,180 0 0 1 450,417.5 L 500,417.5 Z"/>
				</clipPath>
			</defs>
			<path d="M 136.4,271.2 C 48,271.2 2,323.5 2,353.4 V 368.3 H 270.8 V 353.4 C 270.8,323.5 224.8,271.2 136.4,271.2 Z" fill="currentColor" clipPath="url(#trim)"/>
			<circle cx="136" cy="174.5" r="75" fill="currentColor" clipPath="url(#trim)"/>

			<path d="M 366.4,271.2 C 278,271.2 232,323.5 232,353.4 V 368.3 H 500.8 V 353.4 C 500.8,323.5 454.8,271.2 366.4,271.2 Z" fill="currentColor" clipPath="url(#trim)"/>
			<circle cx="364" cy="174.5" r="75" fill="currentColor" clipPath="url(#trim)"/>

			<path d="M 250,297.1 C 139.5,297.1 82,362.5 82,399.8 V 418.5 H 418 V 399.8 C 418,362.5 360.5,297.1 250,297.1 Z" fill="currentColor"/>
			<circle cx="250" cy="177.5" r="95" fill="currentColor"/>
		</>,
	},
	{
		name: 'tutorial',
		description: 'learn how to play the game',
		svg: <path d="M 243.8,63.8 C 241.6,64.6 188.2,88.7 125.1,117.3 C 0.7,173.9 3.9,172.1 1.2,181.2 C 0.3,184.3 0,208.2 0,282.3 L 0,379.4 L 2.6,384.6 C 6.9,393.4 16.3,397.7 25.8,395.3 C 32.4,393.8 39.3,387 40.8,380.6 C 41.8,377.2 42.1,354.6 42.1,298.3 C 42.1,249 42.5,220.5 43,220.8 C 43.6,220.9 52.6,225 63.4,229.9 L 82.9,238.7 L 83.3,299.2 L 83.6,359.7 L 85.7,363.9 C 87,366.1 89.4,369.1 91,370.5 C 92.8,371.7 128.2,387.3 169.7,405 C 236.2,433.7 245.6,437.5 250,437.5 C 254.7,437.5 264,433.7 331.6,404.8 C 386.5,381.2 408.8,371.1 410.5,369.2 C 411.9,367.7 413.8,365 414.7,363.3 C 416.5,360 416.6,356.1 416.9,299.2 L 417.2,238.7 L 452.1,223 C 471.2,214.4 488.7,206.3 490.9,204.8 C 496.7,201.3 500.7,193.3 499.9,186.5 C 499.4,180.4 496.6,175.1 492.2,171.7 C 487.2,167.6 257.4,63.6 252.1,62.9 C 249.4,62.5 246.2,62.9 243.8,63.8 Z M 340.3,147.4 C 389,169.5 428.7,187.7 428.7,187.8 C 427.4,188.8 251.1,268.4 250.2,268.4 C 249.4,268.4 178.5,236.5 74.9,189.3 L 71.4,187.7 L 160.4,147 C 209.5,124.8 250,106.6 250.6,106.8 C 251.1,106.8 291.4,125.3 340.3,147.4 Z M 250,312.4 C 255.1,312.4 259.9,310.3 315.3,285.1 L 375.1,258 L 375,299.4 L 374.6,340.9 L 312.4,367.6 L 250,394.3 L 187.9,367.6 L 125.5,340.9 L 125.2,299.4 L 125.1,257.8 L 184.8,285.1 C 239.9,310.2 245.2,312.4 250,312.4 Z" fill="currentColor"/>,
	},
];

/**
 * Represents the menu screen after pressing the "play" button.
 */
export default class PlayMenu extends PureComponent {
	constructor(props) {
		super(props);
	}

	render() {
		return (
			<div className={`${this.props.hide ? 'hidden ' : ''}flex-column window`}>
				<h1>play</h1>
				<div id="play-options" className="flex-column">
					{
						options.map((option, i) => (
							<div key={i} className="play-option modal" onClick={() => this.props.onOptionClick(option.name)}>
								<svg width="150" height="150" viewBox="0 0 500 500">{option.svg}</svg>
								<div>
									<h1>{option.name}</h1>
									<p>{option.description}</p>
								</div>
							</div>
						))
					}
				</div>
				<div className="button-row">
					<Button onClick={() => this.props.onClosePlayMenu()} value="close"/>
				</div>
			</div>
		);
	}
}
