import {Fragment, PureComponent} from 'react';

import {api} from '../scripts/net/api.js';
import Button from './base/button.js';
import {connection} from '../scripts/net/ws.js';

/**
 * Represents all settings within the settings menu.
 * @type {Object<string, {display: string, type: string, description: string, default: string, options: Array<{name: string, description: string}>}>}
 */
const settings = {
	movementStyle: { // key sent to server
		display: 'movement style', // name displayed to user
		type: 'switcher', // TODO: unused
		description: 'determines what happens to your velocity when you stop moving',
		default: 1, // id of default option
		options: [
			// descriptions of each option, shown when it is selected
			{name: 'free', description: 'when you stop pressing movement keys, your velocity will remain constant'},
			{name: 'friction', description: 'when you stop pressing horizontal / vertical movement keys, your velocity in that direction will be reduced at a constant rate'},
		],
	},
};

/**
 * Represents the settings menu.
 */
export default class Settings extends PureComponent {
	constructor(props) {
		super(props);
		this.state = {
			/**
			 * Whether the settings form is currently being submitted.
			 */
			submitting: false,

			/**
			 * The selected movement style.
			 */
			movementStyle: 1,

			/**
			 * The user's currently applied movement style.
			 */
			appliedMovementStyle: 1,
		};

		connection.registerConnectionListener((_, {movementStyle}) => {
			this.setState({movementStyle, appliedMovementStyle: movementStyle});
		});
	}

	/**
	 * The user clicked the apply button.
	 */
	async apply() {
		const form = document.getElementById('settings-list');
		this.setState({submitting: true});

		await api().auth.settings.postForm(form);

		const {localPlayer} = await import('../scripts/game/player.js');

		for (const [key, value] of new FormData(form)) {
			switch (key) {
				case 'movementStyle':
					localPlayer.movementStyle = parseInt(value);
					break;
			}
		}

		this.setState({submitting: false, appliedMovementStyle: this.state.movementStyle});
		this.props.onFinishSettings();
	}

	/**
	 * The user chose a new option for a setting.
	 * @param {string} name The name of the setting.
	 * @param {number} id The ID of the selected option.
	 */
	onOptionSelected(name, id) {
		this.setState({[name]: id});
	}

	/**
	 * The user clicked the close button.
	 */
	onClose() {
		// reset the settings to their previous values
		this.setState({movementStyle: this.state.appliedMovementStyle});
		this.props.onFinishSettings();
	}

	render() {
		return (
			<div className={`${this.props.hide ? 'hidden ' : ''}flex-column window`}>
				<h1>settings</h1>
				<form id="settings-list" onSubmit={this.apply.bind(this)}>
					{
						Object.entries(settings).map(([key, setting]) => {
							return <div className="setting" key={key}>
								<div className="setting-block">
									<h2>{setting.display}</h2>
									<p>{setting.description}</p>
									{
										typeof this.state[key] === 'number'
											? <p><span style={{fontWeight: 'bold'}}>{setting.options[this.state[key]].name}</span>: {setting.options[this.state[key]].description}</p>
											: null
									}
								</div>
								<div className="switcher">
									{
										setting.options.map(({name}, i) => {
											return <Fragment key={name}>
												<input type="radio" name={key} value={i} id={name} checked={this.state[key] === i} onChange={() => this.onOptionSelected(key, i)}/>
												<label htmlFor={name}>{name}</label>
											</Fragment>;
										})
									}
								</div>
							</div>;
						})
					}
				</form>
				<div className="button-row">
					<Button onClick={this.apply.bind(this)} disabled={this.state.submitting} value="apply"/>
					<Button onClick={this.onClose.bind(this)} disabled={this.state.submitting} value="close"/>
				</div>
			</div>
		);
	}
}
