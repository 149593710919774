import {PureComponent} from 'react';

import {api} from '../scripts/net/api.js';
import Button from './base/button.js';
import Chat from './chat.js';
import {connection} from '../scripts/net/ws.js';
import {focus} from '../scripts/utils.js';
import {wrapGameModeSvg} from './utils.js';
import MultiLevelSelect from './level_select/multi.js';
import {localPlayer} from '../scripts/game/player.js';
import PlayerList from './player_list.js';

/**
 * Checks if the player is currently in a multiplayer room through the API.
 */
async function inMultiplayerRoom() {
	try {
		await api().auth.rooms.me.get();
		return true;
	} catch {
		return false;
	}
}

/**
 * The UI for a multiplayer room.
 */
export default class Room extends PureComponent {
	constructor(props) {
		super(props);
		this.state = {
			/**
			 * The UUID of the room's host.
			 * @type {string}
			 */
			host: null,

			/**
			 * The name of the room.
			 */
			name: 'Untitled room',

			/**
			 * The room's current level.
			 * @type {import('../scripts/game/level.tsx').default}
			 */
			level: null,

			/**
			 * The ID of the chosen game mode.
			 * @type {number?}
			 */
			gameMode: null,

			/**
			 * Whether the user is currently in-game.
			 */
			inGame: false,

			/**
			 * Whether the user is currently choosing the next level to play.
			 */
			chooseMap: false,

			/**
			 * The list of players in the room. This or `this.spectators` should always contain the local player's data.
			 * @type {Array<{uuid: string, username: string, color: number}>}
			 */
			players: [],

			/**
			 * The list of spectators in the room. This or `this.players` should always contain the local player's data.
			 * @type {Array<{uuid: string, username: string, color: number}>}
			 */
			spectators: [],

			/**
			 * A map of all users that have entered the room previously, indexed by their UUID. This can include users that are no longer in the room.
			 * @type {Map<string, {uuid: string, username: string, color: number}>}
			 */
			users: new Map(),

			/**
			 * The chat messages.
			 * @type {Array<{uuid: string, content: string}>}
			 */
			chat: [],
		};

		connection.registerListener('ui', ['chat', 'player_join', 'spectator_join', 'player_disconnect', 'spectator_disconnect', 'switch_user_role', 'room_create', 'room_join', 'room_modify', 'room_start', 'room_abort', 'completed'], this.onMessage.bind(this));
	}

	/**
	 * Handle specific messages from the server.
	 */
	async onMessage(packet) {
		const {type, data} = packet;
		switch (type) {
			case 'chat':
				this.setState({chat: this.state.chat.concat(data)});
				break;

			case 'player_join':
				this.setState({
					players: [...this.state.players, data],
					users: this.state.users.set(data.uuid, {...data}),
				});
				break;

			case 'spectator_join':
				this.setState({
					spectators: [...this.state.spectators, data],
					users: this.state.users.set(data.uuid, {...data}),
				});
				break;

			case 'player_disconnect': {
				const {default: room} = await import('../scripts/game/room.js');
				const {uuid} = room.getDisconnected(data);
				this.setState({players: this.state.players.filter(player => player.uuid !== uuid)});
			} break;

			case 'spectator_disconnect': {
				const {default: room} = await import('../scripts/game/room.js');
				const {uuid} = room.getDisconnected(data);
				this.setState({spectators: this.state.spectators.filter(spectator => spectator.uuid !== uuid)});
			} break;

			case 'switch_user_role': {
				const {default: room} = await import('../scripts/game/room.js');
				room.switchRole(data.shortID, data.newRole);

				const uuid = room.shortIDs.get(data.shortID);
				if (data.newRole === 'player') { // remove user from spectators and add to players
					this.setState({
						players: [...this.state.players, this.state.spectators.find(spectator => spectator.uuid === uuid)],
						spectators: this.state.spectators.filter(spectator => spectator.uuid !== uuid),
					});
				} else { // remove user from players and add to spectators
					this.setState({
						players: this.state.players.filter(player => player.uuid !== uuid),
						spectators: [...this.state.spectators, this.state.players.find(obj => obj.uuid === uuid)],
					});
				}
			} break;

			case 'room_create':
				const localData = {
					uuid: localPlayer.uuid,
					username: localPlayer.username,
					color: localPlayer.color,
				};
				this.setState({
					host: localPlayer.uuid,
					players: [localData],
					users: this.state.users.set(localPlayer.uuid, {...localData}),
				});
				if (await inMultiplayerRoom()) this.props.onEnterRoom();
				break;

			case 'room_join':
				const players = [];
				const spectators = [];
				const users = new Map();

				for (const uuid in data.users.players) {
					const {username, color} = data.users.players[uuid];
					players.push({uuid, username, color});
					users.set(uuid, {uuid, username, color});
				}

				for (const uuid in data.users.spectators) {
					const {username, color} = data.users.spectators[uuid];
					spectators.push({uuid, username, color});
					users.set(uuid, {uuid, username, color});
				}

				const {default: Level} = await import('../scripts/game/level.tsx');
				this.setState({
					host: data.host,
					level: data.level ? Level.parse(data.level) : null,
					gameMode: data.gameMode ?? null,
					players,
					spectators,
					users,
				});
				if (await inMultiplayerRoom()) this.props.onEnterRoom();
				break;

			case 'room_modify':
				const newState = {};

				if (data.host) newState.host = data.host;

				if (data.level) {
					const {default: Level} = await import('../scripts/game/level.tsx');
					newState.level = Level.parse(data.level);
				}

				if (typeof data.gameMode === 'number') newState.gameMode = data.gameMode;

				this.setState(newState);
				break;

			case 'room_start':
				// we do not want to trigger this code if playtesting, otherwise the menubar will show "return to lobby"
				if (this.state.host && typeof this.state.gameMode === 'number' && this.state.level) {
					this.setState({inGame: true});
					this.props.onStart();
					focus();
				}
				break;

			case 'room_abort':
				this.props.onAbort();
				// missing break is intentional

			case 'completed':
				this.setState({inGame: false});
				break;
		}
	}

	/**
	 * Returns true if the local player can start spectating the current game.
	 */
	canSpectate() {
		return this.state.inGame && this.state.spectators.some(spectator => spectator.uuid === localPlayer.uuid);
	}

	/**
	 * Called when the user, who should be the host, clicks on the "choose map" button.
	 */
	chooseMap() {
		this.setState({chooseMap: true});
	}

	/**
	 * Called when the host closed the level select screen by clicking the "back" button or one of fthe levels in the level select screen.
	 */
	onCloseLevelSelect() {
		this.setState({chooseMap: false});
	}

	/**
	 * Called when the user clicks on the "move to players" or "move to spectators" button underneath the player / spectator list.
	 * @param {string} type The role to switch to. Can be either "player" or "spectator".
	 */
	async switchRole(type) {
		await api().auth.rooms.me.switch[type].post();
	}

	/**
	 * The user clicked the start button. Only the host can start the game.
	 */
	async start() {
		// notify the server to start the game
		// server will provide us with data to initialize the game mode over websocket
		await api().auth.rooms.me.start.post();
	}

	/**
	 * The user clicked the back to game button. This button appears when the user is in-game, but has re-opened the lobby.
	 */
	backToGame() {
		this.props.onStart();
	}

	/**
	 * The user clicked the spectate button. This is for spectators who joined the room while it was in-game.
	 */
	async spectate() {
		await api().auth.rooms.me.spectate.post();

		this.setState({inGame: true});
		this.props.onStart();

		focus();
	}

	/**
	 * The user clicked the abort game button. This will immediately stop the current game, regardless of whether it is in a round or not. Only the host can abort the game.
	 */
	async abort() {
		await api().auth.rooms.me.abort.post();
		this.setState({inGame: false});
	}

	/**
	 * The user clicked the leave button.
	 */
	async leave() {
		const {destroy} = await import('../scripts/game/game.js');
		const {default: room} = await import('../scripts/game/room.js');

		await api().auth.rooms.me.leave.post();
		destroy();
		room.clear();
		this.props.onLeave();

		this.setState({
			host: null,
			name: 'Untitled room',
			level: null,
			gameMode: null,
			inGame: false,
			chooseMap: false,
			players: [],
			spectators: [],
			users: new Map(),
			chat: [],
		});
	}

	render() {
		// layout
		// player list       map info
		// spectator list    play spectate buttons
		const className = !this.props.inRoom ? 'hidden window'
			: this.props.showRoom ? 'window'
			: 'hidden window';
		return ( // thanks jas!
			<div className={className}>
				<MultiLevelSelect hide={!this.state.chooseMap} onClose={this.onCloseLevelSelect.bind(this)}/>
				<div id="room" className="flex-column">
					<h1>{this.state.name}</h1>
					<div id="room-content">
						<div id="room-content-info" className="flex-column">
							<div id="room-content-users">
								<div className="modal">
									<h2>Players</h2>
									<PlayerList players={this.state.players} host={this.state.host}/>
									{
										this.state.spectators.some(player => player.uuid === localPlayer.uuid)
											? <Button onClick={() => this.switchRole('player')} value="move to players"/>
											: null
									}
								</div>
								<div className="modal">
									<h2>Spectators</h2>
									<PlayerList players={this.state.spectators} host={this.state.host}/>
									{
										this.state.players.some(player => player.uuid === localPlayer.uuid)
											? <Button onClick={() => this.switchRole('spectator')} value="move to spectators"/>
											: null
									}
								</div>
							</div>
							<div id="room-content-map" className="modal">
								<h2>Map</h2>
								<div id="room-content-map-info">
									{
										this.state.level ? <>
											{this.state.level.generateSVG(220)}
											<div>
												{wrapGameModeSvg(this.state.gameMode, 100)}
											</div>
											<div id="room-content-map-info-desc">
												<h3>{this.state.level.info.title} - {this.state.level.info.author}</h3>
												<p>
													recommended players: {this.state.level.info.players}
													<br/>
													difficulty: {this.state.level.generateDifficultyString()}
												</p>
												{
													localPlayer?.uuid === this.state.host
														&& <Button onClick={this.chooseMap.bind(this)} value="change map"/>
												}
											</div>
										</> :
										localPlayer?.uuid === this.state.host ? <>
											<h3>no map selected</h3>
											<Button onClick={this.chooseMap.bind(this)} value="choose map"/>
										</> : <p>waiting for host to choose a map</p>
									}
								</div>
							</div>
						</div>
						<Chat users={this.state.users} messages={this.state.chat}/>
					</div>
					<div className="button-row">
						{
							this.state.inGame
								? <Button onClick={this.backToGame.bind(this)} value="back to game"/>
								: <Button disabled={this.state.host !== localPlayer?.uuid || !this.state.level || typeof this.state.gameMode !== 'number'} onClick={this.start.bind(this)} value="start"/>
						}
						<Button disabled={!this.canSpectate()} onClick={this.spectate.bind(this)} value="start spectating"/>
						<Button disabled={this.state.host !== localPlayer?.uuid || !this.state.inGame} onClick={this.abort.bind(this)} value="abort game"/>
						<Button onClick={this.leave.bind(this)} value="leave"/>
					</div>
				</div>
			</div>
		);
	}
}
