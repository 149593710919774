import {PureComponent} from 'react';

import {api} from '../scripts/net/api.js';
import Button from './base/button.js';
import {formatTime, saveFile} from '../scripts/utils.js';

/**
 * Represents a completion screen after a game has ended. Currently only supports the Classic game mode.
 */
export default class Complete extends PureComponent {
	constructor(props) {
		super(props);
		this.state = {
			/**
			 * Whether to hide the completion screen.
			 */
			hidden: true,

			/**
			 * The results of the game.
			 * @type {Map<string, {score: number, timeSpent: number}>}
			 */
			results: null,

			/**
			 * Is the final replay ready?
			 * TODO: default to false when replays take longer to generate
			 */
			replay: true,
		};
	}

	componentDidMount() {
		import('../scripts/game/game.js')
			.then(({emitter: ui}) => {
				ui.addEventListener('complete', e => {
					this.setState({
						hidden: false,
						results: e.detail,
					});
				});
			});
	}

	/**
	 * The user clicked the "close" button.
	 */
	close() {
		this.setState({hidden: true});
	}

	/**
	 * The user clicked the "download replay" button.
	 */
	async replay() {
		const res = await api().auth.rooms.me.replay.get();
		saveFile('level.replay', 'application/octet-stream', res);
	}

	render() {
		return (
			<div id="complete" className={this.state.hidden ? 'hidden window' : 'window'}>
				<h1>complete!</h1>
				<table>
					<thead>
						<tr>
							<th>player</th>
							<th>score</th>
							<th>time spent</th>
						</tr>
					</thead>
					<tbody>
						{
							this.state.results ?
								Array.from(this.state.results.entries()).map(([player, {score, timeSpent}]) => (
									<tr key={player}>
										<td>{player}</td>
										<td>{score}</td>
										<td>{formatTime(timeSpent)}</td>
									</tr>
								)) :
								null
						}
					</tbody>
				</table>
				<div id="complete-options">
					<Button onClick={this.close.bind(this)} value="close"/>
					<Button disabled={!this.state.replay} onClick={this.replay.bind(this)} value="download replay"/>
				</div>
			</div>
		);
	}
}
