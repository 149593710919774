import {api} from './api.js';

/**
 * An array containing all official levels from the server.
 * @type {Array<import('../game/level.tsx').default>?}
 */
let levels = null;

/**
 * Returns all official levels from the server, fetching them if necessary.
 * @returns {Promise<Array<import('../game/level.tsx').default>>}
 */
export async function getLevels() {
	const {default: Level} = await import('../game/level.tsx');
	return levels || (levels = (await api().maps.get()).map(Level.parse));
}
