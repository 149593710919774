/**
 * A map of game mode IDs to their SVG icons.
 */
export const gameModeSvgs = [
	// classic
	<>
		<polygon points="30,20 36,30 30,40 24,30" fill="currentColor"/>,
		<polygon points="30,11 42,30 30,49 18,30" stroke="currentColor" strokeWidth="3"/>,
	</>,

	// freeze_tag
	<path d="M 47.6,38.2 L 42.1,35 L 47,34.2 C 47.5,34.1 47.8,33.8 48.1,33.4 C 48.3,33 48.4,32.6 48.3,32.2 C 48.1,31.3 47.3,30.7 46.4,30.9 L 39.4,33.4 L 32.6,30 L 39.4,26.6 L 46.4,29.1 C 47.3,29.3 48.1,28.7 48.3,27.8 C 48.5,26.9 47.9,26 47,25.8 L 42.1,25 L 47.6,21.8 C 48.3,21.3 48.6,20.3 48.1,19.5 C 47.6,18.7 46.7,18.4 45.9,18.9 L 40.4,22 L 42.1,17.3 C 42.4,16.5 41.9,15.5 41,15.2 C 40.2,14.9 39.2,15.4 39,16.2 L 37.6,23.6 L 31.7,27.1 V 20.1 L 37.4,15.4 C 38.1,14.7 38,13.6 37.3,13.1 C 36.6,12.4 35.5,12.5 34.9,13.1 L 31.7,17 V 10.6 C 31.7,9.7 30.9,9 30,9 S 28.3,9.7 28.3,10.6 V 17 L 25.1,13.1 C 24.5,12.5 23.4,12.4 22.7,13.1 C 22,13.6 22,14.7 22.6,15.4 L 28.3,20.1 V 27.1 L 22.4,23.6 L 21,16.2 C 20.8,15.4 19.9,14.9 19,15.2 C 18.1,15.5 17.6,16.5 17.9,17.3 L 19.6,22 L 14.1,18.9 C 13.3,18.4 12.4,18.7 11.9,19.5 S 11.7,21.3 12.4,21.8 L 17.9,25 L 13,25.8 C 12.1,26 11.5,26.9 11.7,27.8 C 11.9,28.7 12.7,29.3 13.6,29.1 L 20.6,26.6 L 27.4,30 L 20.6,33.4 L 13.6,30.9 C 12.7,30.7 11.9,31.3 11.7,32.2 C 11.5,33.1 12.1,34 13,34.2 L 17.9,35 L 12.4,38.2 C 11.7,38.7 11.4,39.7 11.9,40.5 S 13.3,41.6 14.1,41.1 L 19.6,38 L 17.9,42.7 C 17.8,43.2 17.8,43.7 18,44 C 18.2,44.4 18.6,44.7 19,44.8 C 19.9,45.1 20.8,44.6 21,43.8 L 22.4,36.4 L 28.3,32.9 V 39.9 L 22.6,44.6 C 22,45.3 22,46.4 22.7,46.9 C 23.4,47.6 24.5,47.5 25.1,46.9 L 28.3,43 V 49.4 C 28.3,50.3 29.1,51 30,51 S 31.7,50.3 31.7,49.4 V 43 L 34.9,46.9 C 35.2,47.2 35.7,47.4 36.2,47.4 C 36.6,47.4 36.9,47.2 37.3,46.9 C 38,46.4 38.1,45.3 37.4,44.6 L 31.7,39.9 V 32.9 L 37.6,36.4 L 39,43.8 C 39.2,44.6 40.2,45.1 41,44.8 C 41.9,44.5 42.4,43.5 42.1,42.7 L 40.4,38 L 45.9,41.1 C 46.7,41.6 47.6,41.3 48.1,40.5 C 48.6,39.7 48.3,38.7 47.6,38.2 Z" fill="currentColor"/>,
];

/**
 * Wraps a game mode SVG icon in a circle.
 * @param {number} gameMode The game mode ID.
 * @param {number} size The size of the icon, 60 by default.
 */
export function wrapGameModeSvg(gameMode, size = 60) {
	return (
		<svg width={size} height={size} viewBox="0 0 60 60" fill="none">
			<circle cx="30" cy="30" r="28" stroke="currentColor" strokeWidth="4"/>
			{gameModeSvgs[gameMode]}
		</svg>
	);
}
