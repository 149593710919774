import {AbstractType, CompoundType, KnownAbstractType, Type, abstract, compound, concrete, gameModeCompound, parse} from './type.js';

/**
 * Generic rule format interface.
 * @interface
 */
export class RuleFormat {
	/**
	 * Parse the given ArrayBuffer into a data object that will be inserted into the Packet.
	 * @param {ArrayBuffer} buffer
	 * @param {string} gameMode The game mode of the room the user is in, if any.
	 * @returns {*}
	 */
	parse() {
		throw new Error('Not implemented');
	}
}

/**
 * Indicates that the resulting packet data should be the given type.
 * @class
 */
export class TypeFormat extends RuleFormat {
	/**
	 * @param {Type} type
	 */
	constructor(type) {
		super();

		/**
		 * The concrete data type to parse the data into.
		 */
		this.type = type;
	}

	/**
	 * @param {ArrayBuffer} buffer
	 */
	parse(buffer) {
		return this.type.parse(buffer.slice(0, this.type.bytes));
	}
}

/**
 * Indicates that the resulting packet data should be an abstract type.
 * @class
 */
export class AbstractFormat extends RuleFormat {
	/**
	 * @param {AbstractType} type
	 * @param {Array<Type>} generics
	 */
	constructor(type, generics) {
		super();

		/**
		 * The abstract data type to parse the data into.
		 */
		this.type = type;

		/**
		 * The concrete data types to use when parsing the data.
		 */
		this.generics = generics;
	}

	/**
	 * @param {ArrayBuffer} buffer
	 */
	parse(buffer) {
		return this.type.parse(buffer, this.generics).value;
	}
}

/**
 * Indicates that the resulting packet data should be an object.
 * @class
 */
export class ObjectFormat extends RuleFormat {
	/**
	 * @param {Array<{type: Type | KnownAbstractType | CompoundType, key: string}>} types
	 */
	constructor(types) {
		super();

		/**
		 * An array of data types in the order they appear in the message, along with the key to use in the resulting object.
		 */
		this.types = types;
	}

	/**
	 * @param {ArrayBuffer} buffer
	 */
	parse(buffer) {
		const data = {};
		let pointer = 0;

		for (const {type, key} of this.types) {
			const {bytes, value} = parse(buffer.slice(pointer), type);
			data[key] = value;
			pointer += bytes;
		}

		return data;
	}
}

/**
 * Rules that define how game-mode-dependent binary packets received from the server are parsed.
 */
const gameModeRules = {
	// room_start
	0x13: {
		// classic
		0: new ObjectFormat([
			{type: new KnownAbstractType(abstract.Map, [concrete.Uuid, gameModeCompound.classic.UserInit]), key: 'room'},
			{type: new KnownAbstractType(abstract.Array, [ // paths generated so far
				new KnownAbstractType(abstract.Option, [
					compound.Path,
				]),
			]), key: 'data'},
		]),

		// freeze_tag
		1: new ObjectFormat([
			{type: new KnownAbstractType(abstract.Map, [concrete.Uuid, gameModeCompound.classic.UserInit]), key: 'room'}, // freeze tag also uses classic UserInit
			{type: new KnownAbstractType(abstract.Option, [ // uuid of tagger if set
				compound.Uuid,
			]), key: 'data'},
		]),
	},

	// start_data
	0xa2: {
		// freeze_tag
		1: new TypeFormat(concrete.Uuid),
	},

	// round_end
	0xc0: {
		// classic
		0: new ObjectFormat([
			{type: new KnownAbstractType(abstract.Map, [concrete.Uuid, gameModeCompound.classic.OldUserState]), key: 'oldState'},
			{type: new KnownAbstractType(abstract.Map, [concrete.Uuid, gameModeCompound.classic.NewUserState]), key: 'newState'},
			{type: concrete.f64, key: 'timeLeft'},
		]),

		// freeze_tag
		1: new AbstractFormat(abstract.Map, [
			concrete.Uuid,
			concrete.u8,
		]),
	},

	// completed
	0xc1: {
		// classic
		0: new AbstractFormat(abstract.Map, [
			concrete.Uuid,
			gameModeCompound.classic.Complete,
		]),
		// NOTE: freeze_tag does not have a packet
	},
};

/**
 * Indicates that the resulting packet data depends on the current room's game mode.
 *
 * This format is essentially a wrapper around the correct format for the current game mode.
 * @class
 */
export class GameModeFormat extends RuleFormat {
	/**
	 * @param {number} type
	 */
	constructor(type) {
		super();

		/**
		 * The type (first byte) of the binary packet.
		 */
		this.type = type;
	}

	/**
	 * @param {ArrayBuffer} buffer
	 * @param {number?} gameMode The ID of the game mode of the room the user is in, if any.
	 */
	parse(buffer, gameMode) {
		return gameModeRules[this.type][gameMode].parse(buffer);
	}
}
