import {Container, Text, TextStyle} from 'pixi.js';
import {green, red} from './global.js';
import {onKeyDown as registerKeyDown, onKeyUp as registerKeyUp} from './keys.js';
import {localPlayer} from './player.js';
import room from './room.js';
import {lerp} from '../utils.js';

/**
 * A utility function to map JS key codes to user-friendly strings.
 * @param {string} key
 * @returns {string}
 */
function keyName(key) {
	const keyNames = {
		' ': 'SPACEBAR',
	};
	return keyNames[key] || key.toUpperCase();
}

/**
 * A handler for the guide text. The guide text shows the buttons and actions the user can take at the start of the round, i.e. "SPACEBAR ... ready".
 *
 * The guide text can have multiple "sections" of keys, which the game mode can activate at different times. For example, the Classic game mode has separate sections for buttons displayed while watching a replay, showing the map overview, etc.
 *
 * A game mode uses this handler by defining buttons on the keyboard, and specifying callbacks to execute when the buttons are pressed / released. This handler listens for the defined keypresses, and calls the callbacks.
 *
 * The handler can be disabled to prevent any callbacks from being executed.
 * @class
 */
class GuideUI {
	constructor() {
		this.enabled = false;

		/**
		 * Whether to fade out the guide text. This can be used to make items behind the guide text more visible.
		 */
		this.fade = false;

		/**
		 * A map of all the sections of the guide text. The key for a section can be any string.
		 *
		 * Each section contains another map that contains the keypresses and their corresponding description. An optional callback can be provided to determine if the action should be displayed.
		 * @type {Map<string, Map<string, {description: string, enabled: (function() => boolean)?}>>}
		 */
		this.sections = new Map();

		/**
		 * The activated section of the guide text.
		 * @type {string?}
		 */
		this.section = null;

		const style = new TextStyle({
			fill: 0xffffff,
			fontFamily: 'Poppins',
			fontSize: 50,
		});

		/**
		 * Represents the hint text that can appear above the guide text.
		 */
		this.hint = new Text('', style);

		/**
		 * Text that displays the names of the buttons and the buttons' actions.
		 */
		this.text = {
			key: new Text('', style.clone()),
			action: new Text('', style.clone()),
		};

		/**
		 * The callbacks to execute on a keypress or keyrelease. Callbacks are only triggered if the guide text is enabled (`this.enabled` is true) AND the action is enabled (`this.actions.get(key).enabled()` is true, or no callback is provided).
		 * @type {{keyDown: function(string), keyUp: function(string)}}
		 */
		this.callbacks = {
			keyDown: null,
			keyUp: null,
		};

		registerKeyDown(event => {
			if (!this.enabled) return;
			const action = this.getAction(event.key);

			if (!action || action.enabled && !action.enabled()) return;

			this.callbacks.keyDown?.(event.key);
			event.preventDefault();
		});

		registerKeyUp(event => {
			if (!this.enabled) return;
			const action = this.getAction(event.key);

			if (!action || action.enabled && !action.enabled()) return;

			this.callbacks.keyUp?.(event.key);
			event.preventDefault();
		});
	}

	/**
	 * Attempts to retrieve the action associated with the given key code. Returns null if the key is not associated with an action or if the section does not contain the key.
	 * @param {string} key The key code to look up.
	 */
	getAction(key) {
		const section = this.sections.get(this.section);
		if (!section) return null;

		const action = section.get(key);
		if (!action) return null;

		return action;
	}

	/**
	 * Returns the number of enabled actions in the current section.
	 */
	get actionsCount() {
		const actions = this.sections.get(this.section);
		if (!actions) return 0;

		let count = 0;

		for (const action of actions.values()) {
			if (!action.enabled || action.enabled()) ++count;
		}

		return count;
	}

	/**
	 * Resets the position of the guide text.
	 */
	reset() {
		const sub = this.text.key.width + this.text.action.width + 100;
		this.text.key.x = this.enabled ? 50 : -sub;
		this.text.action.x = this.enabled ? 400 : 350 - sub;

		// the text appears in the bottom left, so we need to shift it up more whenever there are more actions
		this.resetY();
	}

	/**
	 * Only reset the Y-position of the guide text. This can be used to prevent the guide text from appearing while moving up / down.
	 */
	resetY() {
		this.text.key.y = this.text.action.y = window.innerHeight - 40 - this.actionsCount * 60;
	}

	/**
	 * Returns an approximate bounding box for the guide text. This can be used to determine if any items are behind the guide text.
	 */
	get boundingBox() {
		// only the top and right are provided because the text is always rendered in the bottom left
		return {
			top: this.hint.y - 30,
			right: this.text.action.x + this.text.action.width + 30,
		};
	}

	/**
	 * Sets the actions for a given section that the guide text can display. The keys of the map should be a string value that can be used for `KeyboardEvent.key`. An optional callback can be provided to determine if the action should be displayed.
	 * @param {string} section The section to set the actions for.
	 * @param {Array<[string, {description: string, enabled: (function() => boolean)?}]>} actions The actions to display.
	 */
	setActions(section, actions) {
		const map = new Map();

		for (const [key, action] of actions) map.set(key, action);
		this.sections.set(section, map);
	}

	/**
	 * Sets the current section of the guide text. This implicitly enables the guide text.
	 * @param {string} section The new section to set.
	 * @param {boolean} [enable=true] Whether to enable the guide text.
	 */
	setSection(section, enable = true) {
		this.section = section;
		this.enabled = enable;
	}

	/**
	 * Set the hint text that appears above the guide text.
	 * @param {string} text The text to display.
	 * @param {boolean} [success=false] Whether the hint text should be green (success) or red (fail) (default, false = fail).
	 */
	setHint(text, success = false) {
		this.hint.text = text;
		this.hint.style.fill = success ? green : red;
		this.hint.alpha = 1;
	}

	/**
	 * Update the guide text, calling the `enabled` callback for each action.
	 */
	update() {
		let text = {key: '', action: ''};

		for (const [key, action] of this.sections.get(this.section)) {
			if (action.enabled && !action.enabled()) continue;
			text.key += `${keyName(key)}\n`;
			text.action += `${action.description}\n`;
		}

		this.text.key.text = text.key.trimEnd();
		this.text.action.text = text.action.trimEnd();
	}

	/**
	 * Adds the guide text to the given container.
	 * @param {Container} c The container to add the guide text to.
	 */
	draw(c) {
		if (this.enabled) {
			this.text.key.x = lerp(this.text.key.x, 50, 0.1);
			this.text.action.x = lerp(this.text.action.x, 400, 0.1);
			this.hint.alpha -= 0.0012;
		} else {
			const sub = this.text.key.width + this.text.action.width + 400;
			this.text.key.x = lerp(this.text.key.x, -sub, 0.1);
			this.text.action.x = lerp(this.text.action.x, 350 - sub, 0.1);
			this.hint.alpha /= 1.1;
		}

		this.text.key.y = this.text.action.y = lerp(this.text.key.y, window.innerHeight - 40 - this.actionsCount * 60, 0.1);

		this.hint.x = this.text.key.x;
		this.hint.y = this.text.key.y - 60;

		if (this.hint.alpha) c.addChild(this.hint);

		if (this.fade) {
			this.text.key.alpha = this.text.action.alpha = lerp(this.text.key.alpha, 0.5, 0.1);
		} else {
			this.text.key.alpha = this.text.action.alpha = lerp(this.text.key.alpha, 1, 0.1);
		}

		// right boundary of action text
		// cull the text if it's completely off-screen
		const right = this.text.action.x + this.text.action.width;
		if (right < 0) return;

		c.addChild(this.text.key, this.text.action);
	}
}

/**
 * The global guide text handler.
 */
export const guide = new GuideUI();

/**
 * Returns the base guide UI section to use based on whether the local player is spectating or not. This assumes the existence of two sections: "init", the initial section, and "spectate", the section to use when spectating.
 * @param {boolean} [spectating=false] If true, "spectate" will be returned. This allows certain game modes that have special handling of spectating to force the spectating section.
 */
export function baseGuide(spectating = false) {
	return spectating || room.spectators.has(localPlayer.uuid) ? 'spectate' : 'init'
}
