/**
 * Returns true if the user is on a mobile device (i.e., devices with only touch input).
 */
export function isMobile() {
	return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
}

/**
 * Returns a string concatenating the given count and word. The word is pluralized if the count is not 1.
 * @param {number} count The count to use.
 * @param {string} word The word to pluralize.
 * @param {string?} plural The plural form of the word. If not specified, the word will be pluralized by adding an "s" to the end.
 */
export function pluralize(count, word, plural) {
	return count === 1 ? `${count} ${word}` : `${count} ${plural || word + 's'}`;
}

/**
 * Focus on the canvas.
 */
export function focus() {
	const [canvas] = document.getElementsByTagName('canvas');
	canvas.focus();
}

/**
 * Performs a shallow comparison of two objects, returning true if they are equal.
 * @param {object} a
 * @param {object} b
 */
export function shallowEqual(a, b) {
	const aKeys = Object.keys(a);
	const bKeys = Object.keys(b);

	if (aKeys.length !== bKeys.length) return false;
	for (const key of aKeys) {
		if (a[key] !== b[key]) return false;
	}

	return true;
}

/**
 * Given a byte array with length 16, return the corresponding UUID.
 * @param {Uint8Array} bytes
 */
export function bytesToUUID(bytes) {
	const reduce = array => Array.from(array)
		.map(n => n.toString(16).padStart(2, '0'))
		.join('');
	const a = reduce(bytes.slice(0, 4));
	const b = reduce(bytes.slice(4, 6));
	const c = reduce(bytes.slice(6, 8));
	const d = reduce(bytes.slice(8, 10));
	const e = reduce(bytes.slice(10, 16));
	return `${a}-${b}-${c}-${d}-${e}`;
}

/**
 * Opens a prompt to save the given data to the client's computer.
 * @param {string} fileName
 * @param {string} mimeType
 * @param {string} data
 */
export function saveFile(fileName, mimeType, data) {
	const blob = new Blob([data], {type: mimeType});
	const url = URL.createObjectURL(blob);
	const a = document.createElement('a');
	a.href = url;
	a.download = fileName;
	a.click();
	URL.revokeObjectURL(url);
}

/**
 * Opens a file prompt, then resolves with the contents of the file selected by the user.
 * @param {Array<string>} extensions An array of file extensions that the user can select, e.g. ['txt', 'json'].
 * @param {string} type The type the data within the file is encoded as. If 'txt' is given, the result is a `Promise<string>`; if 'bin' is given, the result is a `Promise<ArrayBuffer>`.
 * @return {Promise<string | ArrayBuffer>}
 */
export function openFile(extensions, type) {
	return new Promise((resolve, reject) => {
		const input = document.createElement('input');
		input.type = 'file';
		input.accept = extensions.map(ext => `.${ext}`).join(',');
		input.click();

		input.oninput = () => {
			const file = input.files[0];
			if (!file) {
				input.remove();
				return reject();
			}

			const reader = new FileReader();
			switch (type) {
				case 'txt':
					reader.readAsText(file);
					break;

				case 'bin':
					reader.readAsArrayBuffer(file);
					break;
			}
			reader.onload = () => resolve(reader.result);

			input.remove();
		};
	});
}

/**
 * Clamp a number between two values.
 * @param {number} n
 * @param {number} l
 * @param {number} r
 */
export function clamp(n, l, r) {
	return Math.max(Math.min(n, r), l);
}

/**
 * Linearly interpolate a value from `n1` to `n2` by a constant `t`.
 * @param {number} n1
 * @param {number} n2
 * @param {number} t
 */
export function lerp(n1, n2, t) {
	return t * (n2 - n1) + n1;
}

/**
 * Linearly interpolate a vector from `v1` to `v2` by a constant `t`.
 * @param {{x: number, y: number}} v1
 * @param {{x: number, y: number}} v2
 * @param {number} t
 */
export function lerpVec(v1, v2, t) {
	return {
		x: lerp(v1.x, v2.x, t),
		y: lerp(v1.y, v2.y, t),
	};
}

/**
 * Linearly interpolate a color from `c1` to `c2` by a constant `t`.
 * @param {number} c1
 * @param {number} c2
 * @param {number} t
 */
export function lerpColor(c1, c2, t) {
	const c1RGB = hexToRGB(c1);
	const c2RGB = hexToRGB(c2);

	return rgbToHex({
		r: lerp(c1RGB.r, c2RGB.r, t),
		g: lerp(c1RGB.g, c2RGB.g, t),
		b: lerp(c1RGB.b, c2RGB.b, t),
	});
}

/**
 * Generates a random number between `min` and `max`.
 * @param {number} min
 * @param {number} max
 */
export function randomRange(min, max) {
	return Math.random() * (max - min) + min;
}

/**
 * Generates a random color.
 */
export function randomColor() {
	return Math.floor(Math.random() * 0xffffff);
}

/**
 * Convert an RGB object to its hex equivalent.
 * @param {{r: number, g: number, b: number}} color
 */
export function rgbToHex(color) {
	return color.r << 16 | color.g << 8 | color.b;
}

/**
 * Convert a hex number to an RGB object.
 * @param {number} hex
 */
export function hexToRGB(hex) {
	return {
		r: hex >> 16 & 0xff,
		g: hex >> 8 & 0xff,
		b: hex & 0xff,
	};
}

/**
 * Mix two colors together.
 * @param {number} a
 * @param {number} b
 */
export function mixColors(a, b) {
	const aRGB = hexToRGB(a);
	const bRGB = hexToRGB(b);

	// from https://stackoverflow.com/questions/1351442/is-there-an-algorithm-for-color-mixing-that-works-like-mixing-real-colors
	const f = (n1, n2) => 255 - Math.sqrt(((255 - n1) ** 2 + (255 - n2) ** 2) / 2);
	return rgbToHex({
		r: f(aRGB.r, bRGB.r),
		g: f(aRGB.g, bRGB.g),
		b: f(aRGB.b, bRGB.b),
	});
}

/**
 * Returns the luma value of the color.
 * @param {number} color
 */
export function luma(color) {
	const rgb = hexToRGB(color);
	return 0.2126 * rgb.r + 0.7152 * rgb.g + 0.0722 * rgb.b;
}

/**
 * Returns the distance between two position objects.
 * @param {{x: number, y: number}} a
 * @param {{x: number, y: number}} b
 */
export function dist(a, b) {
	return Math.hypot(a.x - b.x, a.y - b.y);
}

/**
 * Caps the magnitude of a vector to a given value.
 * @param {{x: number, y: number}} vec
 * @param {number} maxMmag
 */
export function capMag(vec, maxMag) {
	const mag = Math.hypot(vec.x, vec.y);
	return mag > maxMag ? {
		x: vec.x / mag * maxMag,
		y: vec.y / mag * maxMag,
	} : {x: vec.x, y: vec.y};
}

/**
 * Given a time in seconds, format it in `mm:ss` format. If the time is below 60 seconds, display `ss.s` instead.
 * @param {number} time
 */
export function formatTime(time) {
	if (time < 60) return time.toFixed(1);
	const minutes = Math.floor(time / 60);
	const seconds = Math.floor(time % 60);
	return `${minutes}:${seconds.toString().padStart(2, '0')}`;
}

/**
 * Given a date, return a descriptive string of how long ago it was, e.g. "1 day ago".
 * @param {Date} date
 */
export function formatTimeSince(date) {
	const seconds = (Date.now() - date.getTime()) / 1000;
	if (seconds < 60) return 'just now';
	if (seconds < 60 * 60) return `${pluralize(Math.floor(seconds / 60), 'minute')} ago`;
	if (seconds < 60 * 60 * 24) return `${pluralize(Math.floor(seconds / 60 / 60), 'hour')} ago`;
	if (seconds < 60 * 60 * 24 * 7) return `${pluralize(Math.floor(seconds / 60 / 60 / 24), 'day')} ago`;
	if (seconds < 60 * 60 * 24 * 30) return `${pluralize(Math.floor(seconds / 60 / 60 / 24 / 7), 'week')} ago`;
	if (seconds < 60 * 60 * 24 * 365) return `${pluralize(Math.floor(seconds / 60 / 60 / 24 / 30), 'month')} ago`;
	return `${pluralize(Math.floor(seconds / 60 / 60 / 24 / 365), 'year')} ago`;
}
