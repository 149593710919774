import {PureComponent} from 'react';

import {api} from '../../scripts/net/api.js';
import Button from '../base/button.js';
import {connection} from '../../scripts/net/ws.js';
import {formatTime} from '../../scripts/utils.js';
import {getLevels} from '../../scripts/net/utils.js';

const levels = await getLevels();

/**
 * The names of the medals, in order of increasing value.
 */
const medalNames = ['bronze', 'silver', 'gold', 'platinum'];

/**
 * The colors of the medals, in order of increasing value.
 */
const medalColors = [0xcd7f32, 0xc0c0c0, 0xffd700, 0xe5e4e2];

/**
 * Represents the level selection screen for solo mode.
 */
export default class SoloLevelSelect extends PureComponent {
	constructor(props) {
		super(props);
		this.state = {
			/**
			 * The ID of the selected level.
			 * @type {number?}
			 */
			selectedLevel: null,

			/**
			 * Whether an API request is in progress.
			 */
			requestInProgress: false,
		};
	}

	/**
	 * The user selected a level from the list.
	 * @param {number} id The ID of the level.
	 */
	onLevelSelect(id) {
		this.setState({selectedLevel: id});
	}

	/**
	 * Returns the JSX for the level info section.
	 */
	getLevelInfo() {
		const l = levels.find(l => l.info.id.Official === this.state.selectedLevel);
		const personalBest = 0; // TODO
		const colorMedalAchieved = medalColors.findLast((_, i) => personalBest >= l.info.scoreGoals[i]) || 0xececec;

		return <>
			<div className="flex-column level-select-level-info">
				{l.generateSVG(200)}
				<h1>{l.info.title}</h1>
				<h2>by {l.info.author}</h2>
				<p>difficulty: {l.generateDifficultyString()}</p>
				<p>time limit: {formatTime(l.info.timeLimit)}</p>
			</div>
			<div className="medals">
				<h2>your personal best: {personalBest}</h2>
				<div className="progress-bar">
					<div className="progress-bar-inner" style={{width: `${personalBest / l.info.scoreGoals[3] * 100}%`, backgroundColor: `#${colorMedalAchieved.toString(16).padStart(6, '0')}`}}></div>
				</div>
				<div className="medal-indicators">
					{
						l.info.scoreGoals.slice(0, 3).map((goal, i) => (
							<div
								key={i}
								className="medal-goal-indicator"
								style={{left: `${goal / l.info.scoreGoals[3] * 100}%`}}
							>
								<div className="medal-goal-indicator-bar"></div>
								<p>{medalNames[i]}</p>
								<p>{goal}</p>
							</div>
						))
					}
				</div>
			</div>
		</>;
	}

	/**
	 * The user clicked the start button.
	 */
	async start() {
		this.setState({requestInProgress: true});

		const level = levels.find(l => l.info.id.Official === this.state.selectedLevel);

		try {
			// notify the server to start the game
			// server will provide us with data to initialize the game mode over websocket
			await api().auth.solo.post(level.info.id)

			const {game} = await import('../../scripts/game/game.js');
			game.initData.level = level;
			game.initData.gameMode = 0;
			connection.gameMode = 0; // TODO: not clean

			this.props.onStartSolo();
		} catch (e) {
			console.error(e);
		}

		this.setState({requestInProgress: false});
	}

	render() {
		return (
			<div id="level-select" className={`${this.props.hide ? 'hidden ' : ''}scrollable flex-column window`}>
				<h1>level select</h1>
				<div className="levels-list-content">
					<div className="scrollable perm-modal levels-list">
						<div id="levels-list-header">
							<h2>name</h2>
							<h2>difficulty</h2>
						</div>
						{
							levels.map((level, i) => (
								<div
									key={i}
									className={`${this.state.selectedLevel === level.info.id.Official ? 'selected ' : ''}option list-level`}
									onClick={() => this.onLevelSelect(level.info.id.Official)}
								>
									<h2>{level.info.title}</h2>
									<p>{level.generateDifficultyString()}</p>
								</div>
							))
						}
					</div>
					<div className="perm-modal levels-list-info" style={{width: '30em'}}>
						<div className="scrollable height-100">
							{
								this.state.selectedLevel !== null
									? this.getLevelInfo()
									: <div id="leaderboards-blank" className="flex-column"><h1>choose a level</h1></div>
							}
						</div>
					</div>
				</div>
				<div className="button-row">
					<Button disabled={this.state.requestInProgress || this.state.selectedLevel === null} onClick={this.start.bind(this)} value="start"/>
					<Button disabled={this.state.requestInProgress} onClick={this.props.onCloseSoloSelect.bind(this)} value="close"/>
				</div>
			</div>
		);
	}
}
