import {connection} from '../net/ws.js';
import {localPlayer, Player} from './player.js';

/**
 * Represents the room the local player is currently in.
 */
class Room {
	constructor() {
		/**
		 * The players in the room as a Map of UUID - Player mappings.
		 * @type {Map<string, Player>}
		 */
		this.players = new Map();

		/**
		 * Players that have disconnected from the room as a Map of UUID - Player mappings.
		 * @type {Map<string, Player>}
		 */
		this.disconnected = new Map();

		/**
		 * The spectators in the room as a Map of UUID - Player mappings.
		 * @type {Map<string, Player>}
		 */
		this.spectators = new Map();

		/**
		 * A helper map to map short IDs received from the server to user UUIDs.
		 * @type {Map<number, string>}
		 */
		this.shortIDs = new Map();
	}

	/**
	 * Clear all players from the room.
	 */
	clear() {
		this.players.clear();
		this.disconnected.clear();
		this.spectators.clear();
		this.shortIDs.clear();
	}

	/**
	 * Set the given short ID - user UUID mapping.
	 * @param {number} shortID
	 * @param {string} uuid
	 */
	setShortID(shortID, uuid) {
		this.shortIDs.set(shortID, uuid);
	}

	/**
	 * Set a Map of short ID - user UUID mappings. The generic arguments are reversed because the server sends the UUID first.
	 * @param {Map<string, number>} shortIDs
	 */
	setShortIDs(shortIDs) {
		for (const [uuid, shortID] of shortIDs) {
			this.shortIDs.set(shortID, uuid);
		}
	}

	/**
	 * Adds the local player to the room.
	 */
	addLocalPlayer() {
		this.players.set(localPlayer.uuid, localPlayer);
	}

	/**
	 * Add a non-local player to the room with the given data and returns the player.
	 * @param {string} uuid
	 * @param {string} username
	 * @param {number} color
	 * @param {string} movementStyle
	 */
	addPlayer(uuid, username, color, movementStyle) {
		// first, check if the player is in the disconnected list
		if (this.disconnected.has(uuid)) {
			const p = this.disconnected.get(uuid);
			p.disconnected = false;
			this.players.set(uuid, p);
			this.disconnected.delete(uuid);
			return p;
		} else {
			const p = new Player(uuid, username, color, movementStyle, false);
			this.players.set(uuid, p);
			return p;
		}
	}

	/**
	 * Add a non-local spectator to the room with the given data and returns the spectator.
	 * @param {string} uuid
	 * @param {string} username
	 * @param {number} color
	 * @param {string} movementStyle
	 */
	addSpectator(uuid, username, color, movementStyle) {
		// first, check if the spectator is in the disconnected list
		if (this.disconnected.has(uuid)) {
			const p = this.disconnected.get(uuid);
			p.disconnected = false;
			this.spectators.set(uuid, p);
			this.disconnected.delete(uuid);
			return p;
		} else {
			const p = new Player(uuid, username, color, movementStyle, false);
			this.spectators.set(uuid, p);
			return p;
		}
	}

	/**
	 * Set the player list to the given array of data. This is to be used when joining a room.
	 * @param {Object<string, {username: string, color: number, movementStyle: string}>} playerList
	 */
	setPlayers(playerList) {
		this.players.clear();
		this.disconnected.clear();

		for (const uuid in playerList) {
			const {username, color, movementStyle} = playerList[uuid];
			const p = uuid === localPlayer.uuid
				? localPlayer
				: new Player(uuid, username, color, movementStyle, false);
			this.players.set(uuid, p);
		}
	}

	/**
	 * Set the spectator list to the given array of data. This is to be used when joining a room.
	 * @param {Object<string, {username: string, color: number, movementStyle: string}>} spectatorList
	 */
	setSpectators(spectatorList) {
		this.spectators.clear();

		for (const uuid in spectatorList) {
			const {username, color, movementStyle} = spectatorList[uuid];
			const p = uuid === localPlayer.uuid
				? localPlayer
				: new Player(uuid, username, color, movementStyle, false);
			this.spectators.set(uuid, p);
		}
	}

	/**
	 * Get the player with the given short ID.
	 * @param {number} shortID
	 */
	getPlayer(shortID) {
		return this.players.get(this.shortIDs.get(shortID));
	}

	/**
	 * Get the spectator with the given short ID.
	 * @param {number} shortID
	 */
	getSpectator(shortID) {
		return this.spectators.get(this.shortIDs.get(shortID));
	}

	/**
	 * Get the disconnected player with the given short ID.
	 * @param {number} shortID
	 */
	getDisconnected(shortID) {
		return this.disconnected.get(this.shortIDs.get(shortID));
	}

	/**
	 * Mark the player / spectator with the given short ID as disconnected and returns the player.
	 * @param {number} shortID
	 */
	markDisconnected(shortID) {
		const uuid = this.shortIDs.get(shortID);
		const player = this.players.get(uuid) || this.spectators.get(uuid);
		player.disconnected = true;

		this.disconnected.set(uuid, player);
		this.players.delete(uuid);
		this.spectators.delete(uuid);
		return player;
	}

	/**
	 * Switches the role of the user with the given short ID to the given target role. No action is taken if the target role matches the user's current role.
	 * @param {number} shortID
	 * @param {string} newRole The role to switch the user to. Can be 'player' or 'spectator'.
	 */
	switchRole(shortID, newRole) {
		// if the user's role is already the same as the new role, do nothing
		const uuid = this.shortIDs.get(shortID);
		if (this.players.has(uuid) && newRole === 'player' || this.spectators.has(uuid) && newRole === 'spectator') {
			return;
		}

		// user's current role is different from the new role
		if (this.players.has(uuid)) {
			const user = this.players.get(uuid);
			this.spectators.set(uuid, user);
			this.players.delete(uuid);
		} else if (this.spectators.has(uuid)) {
			const user = this.spectators.get(uuid);
			this.players.set(uuid, user);
			this.spectators.delete(uuid);
		}
	}

	/**
	 * Returns an iterator over all non-local players in the room.
	 */
	*nonLocalPlayers() {
		for (const data of this.players) {
			if (!data[1].local) {
				yield data;
			}
		}
	}
}

/**
 * The room the local player is currently in. The room automatically handles the following WebSocket events:
 *
 * - `room_create`
 * - `room_join`
 * - `room_short_user_id`
 * - `room_short_user_ids`
 * - `player_join`
 * - `spectator_join`
 * - `player_disconnect`
 * - `spectator_disconnect`
 *
 * There should be no reason to interact with the functions that mutate the room directly.
 */
const room = new Room();
export default room;

connection.registerListener('internal', ['room_create', 'room_join', 'room_short_user_id', 'room_short_user_ids', 'player_join', 'spectator_join', 'player_disconnect', 'spectator_disconnect', 'switch_user_role'], packet => {
	const {type, data} = packet;
	switch (type) {
		case 'room_create':
			room.addLocalPlayer();
			break;

		case 'room_join':
			room.setPlayers(data.users.players);
			room.setSpectators(data.users.spectators);
			break;

		// server indicates short ID for a user
		case 'room_short_user_id':
			room.setShortID(data.shortID, data.uuid);
			break;

		// server indicates multiple short IDs for a user
		case 'room_short_user_ids':
			room.setShortIDs(data);
			break;

		case 'player_join':
			room.addPlayer(data.uuid, data.username, data.color, data.movementStyle);
			break;

		case 'spectator_join':
			room.addSpectator(data.uuid, data.username, data.color, data.movementStyle);
			break;

		case 'player_disconnect':
		case 'spectator_disconnect':
			room.markDisconnected(data);
			break;

		case 'switch_user_role':
			room.switchRole(data.shortID, data.newRole);
			break;
	}
});
