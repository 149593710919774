import Delaunator from 'delaunator';
import {Graphics, ParticleContainer, Sprite} from 'pixi.js';

import {app} from './global.js';
import {randomColor, randomRange} from '../utils.js';

/**
 * A triangle in the abstract geometric background.
 * @class
 */
class AbstractBackgroundTriangle {
	/**
	 * @param {Array<{x: number, y: number}>} points
	 * @param {{display: number, actual: number}} color
	 */
	constructor(points, color) {
		this.points = points;
		this.color = color;
	}
}

/**
 * Holds all the triangles in the abstract geometric background.
 * @class
 */
export class AbstractBackgroundTriangleSystem {
	constructor() {
		this.bound = {x: 0, y: 0};

		/**
		 * @type {Array<AbstractBackgroundTriangle>}
		 */
		this.triangles = [];

		this.opacity = 0.4;

		/**
		 * The sprite of the background.
		 * @type {Sprite}
		 */
		this.sprite = null;
	}

	/**
	 * Flash the background.
	 */
	flash() {
		this.opacity = 0.7;
	}

	/**
	 * Add approximately the specified number of vertices to the system.
	 * @param {number} count
	 */
	addVertices(count) {
		const points = [];

		for (let i = 0; i < count; ++i) {
			points.push([Math.random() * (window.innerWidth + 800) - 400, Math.random() * (window.innerHeight + 800) - 400]);
		}

		const t = Delaunator.from(points);

		for (let i = 0; i < t.triangles.length; i += 3) {
			const [x1, y1] = points[t.triangles[i]];
			const [x2, y2] = points[t.triangles[i + 1]];
			const [x3, y3] = points[t.triangles[i + 2]];
			const p1 = {x: x1, y: y1};
			const p2 = {x: x2, y: y2};
			const p3 = {x: x3, y: y3};
			this.triangles.push(new AbstractBackgroundTriangle([p1, p2, p3], {display: randomColor(), actual: randomColor()}));
		}
	}

	/**
	 * Generate the sprite of the background, storing it at `this.sprite`. Pre-generating the sprite like this makes it much faster to render the background.
	 */
	generateSprite() {
		const graphics = new Graphics();

		for (const t of this.triangles) {
			const {points, color} = t;
			const [p1, p2, p3] = points;

			graphics
				.beginFill(color.display, 1)
				.drawPolygon([
					p1.x, p1.y,
					p2.x, p2.y,
					p3.x, p3.y,
				]);
		}

		const texture = app.renderer.generateTexture(graphics);
		this.sprite = new Sprite(texture);
	}

	/**
	 * Updates the background system. The position of the camera is used to create a parallax effect.
	 * @param {{x: number, y: number}} cameraPos
	 */
	draw(cameraPos) {
		if (this.opacity > 0.4) this.opacity -= 0.005;

		const parallax = {x: (window.innerWidth / 2 - cameraPos.x) / 5, y: (window.innerHeight / 2 - cameraPos.y) / 5};
		this.sprite.position.set(parallax.x - 400, parallax.y - 400);
		this.sprite.alpha = this.opacity;
	}
}

/**
 * Representation of a particle in the background.
 */
class BackgroundParticle {
	constructor(pos, vel, radius, color, shape) {
		this.pos = pos;
		this.vel = vel;
		this.radius = radius;
		this.color = color;
		this.shape = shape;
	}

	/**
	 * Update the position of the particle.
	 */
	update() {
		this.pos.x += this.vel.x;
		this.pos.y += this.vel.y;

		const bounds = {
			left: -this.radius,
			right: window.innerWidth + this.radius,
			top: -this.radius,
			bottom: window.innerHeight + this.radius
		}

		if (this.pos.x < bounds.left) {
			this.pos.x = bounds.right;
		} else if (this.pos.x > bounds.right) {
			this.pos.x = bounds.left;
		}

		if (this.pos.y < bounds.top) {
			this.pos.y = bounds.bottom;
		} else if (this.pos.y > bounds.bottom) {
			this.pos.y = bounds.top;
		}
	}
}

/**
 * Holds all the particles in the background.
 * @class
 */
export class BackgroundParticleSystem {
	constructor() {
		/**
		 * @type {Array<BackgroundParticle>}
		 */
		this.particles = [];
		this.opacity = 0.2;

		/**
		 * @type {Graphics}
		 */
		this.graphics = new Graphics();
	}

	/**
	 * Flash the background.
	 */
	flash() {
		this.opacity = 1;
	}

	/**
	 * Add the specified number of particles to the system.
	 * @param {number} count
	 */
	addParticles(count) {
		for (let i = 0; i < count; ++i) {
			const pos = {x: Math.random() * window.innerWidth, y: Math.random() * window.innerHeight};
			const vel = {x: randomRange(-0.5, 0.5), y: randomRange(-0.5, 0.5)};
			const radius = randomRange(2, 4);
			const c = randomColor();
			const shape = Math.random() < 0.5 ? 'circle' : 'square';
			this.particles.push(new BackgroundParticle(pos, vel, radius, c, shape));
		}
	}

	/**
	 * Update the position of all particles.
	 */
	update() {
		for (const p of this.particles) p.update();
	}

	/**
	 * Draw all particles in the system. The position of the camera is used to create a parallax effect.
	 * @param {{x: number, y: number}} cameraPos
	 */
	draw(cameraPos) {
		this.graphics.clear();

		const parallax = {x: (window.innerWidth / 2 - cameraPos.x) / 5, y: (window.innerHeight / 2 - cameraPos.y) / 5};

		if (this.opacity > 0.2) this.opacity -= 0.015;
		for (const p of this.particles) {
			this.graphics.beginFill(p.color, this.opacity);

			switch (p.shape) {
				case 'circle':
					this.graphics.drawCircle(p.pos.x + parallax.x, p.pos.y + parallax.y, p.radius);
					break;

				case 'square':
					this.graphics.drawRect(p.pos.x + parallax.x, p.pos.y + parallax.y, p.radius, p.radius);
					break;
			}
		}
	}
}

/**
 * All non-PIXI particle systems.
 */
export const particleSystems = {
	geomBg: new AbstractBackgroundTriangleSystem(), // cool abstract geometric background
	bg: new BackgroundParticleSystem(), // background particles
};

particleSystems.geomBg.addVertices(40);
particleSystems.geomBg.generateSprite();
particleSystems.bg.addParticles(120);

/**
 * A particle container that holds all particles handled with @pixi/particle-emitter.
 * @type {ParticleContainer}
 */
export const particleContainer = new ParticleContainer();

/**
 * Flash all background particle systems.
 */
export function flash() {
	particleSystems.geomBg.flash();
	particleSystems.bg.flash();
}
