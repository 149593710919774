import {PureComponent} from 'react';

import {api} from '../scripts/net/api.js';
import Button from './base/button.js';
import RoomList from './room_list.js';

/**
 * Represents the screen that allows users to browse publicly available rooms / create rooms.
 */
export default class RoomBrowser extends PureComponent {
	constructor(props) {
		super(props);
		this.state = {
			/**
			 * Available rooms to join.
			 * @type {Array<{uuid: string, name: string, host: string, mode: number, players: number, inGame: boolean}>}
			 */
			rooms: [],

			/**
			 * The UUID of the room the user has selected.
			 * @type {string}
			 */
			selectedRoom: null,
		};
	}

	/**
	 * Fetch the list of rooms from the server.
	 */
	async fetchRooms() {
		const json = await api().rooms.get();
		const rooms = [];

		for (const uuid in json) {
			const room = json[uuid];
			const {username: hostName} = await api().users[room.host].get();
			rooms.push({
				uuid,
				name: room.name,
				host: hostName,
				mode: room.mode,
				players: room.users,
				inGame: room.inGame,
			});
		}

		this.setState({rooms});
	}

	componentDidMount() {
		this.fetchRooms();
	}

	/**
	 * The user clicked on one of the rooms in the room list. Clicking on a room toggles its selection status.
	 * @param {string} uuid The UUID if the room that was clicked.
	 */
	onRoomClick(uuid) {
		this.setState({
			selectedRoom: this.state.selectedRoom === uuid ? null : uuid
		});
	}

	/**
	 * The user clicked the "+ create room" button.
	 */
	async onCreateRoom() {
		await api().auth.rooms.create.post();
	}

	/**
	 * The user clicked the "join room" button.
	 */
	async onJoinRoom() {
		await api().auth.rooms[this.state.selectedRoom].post();
	}

	/**
	 * The user clicked the "back" button.
	 */
	onCloseBrowser() {
		this.props.onCloseBrowser();
	}

	render() {
		return (
			<div className={`${this.props.hide ? 'hidden ' : ''}flex-column window`}>
				<h1>room browser</h1>
				<RoomList rooms={this.state.rooms} onRoomClick={this.onRoomClick.bind(this)} selectedRoom={this.state.selectedRoom}/>
				<div className="button-row">
					<Button id="room-browser-refresh" onClick={this.fetchRooms.bind(this)} autoClickTime={15} hidden={this.props.hide} value="refresh"/>
					<Button onClick={this.onCreateRoom.bind(this)} value="+ create room"/>
					<Button onClick={this.onJoinRoom.bind(this)} disabled={!this.state.selectedRoom} value="join room"/>
					<Button onClick={this.onCloseBrowser.bind(this)} value="back"/>
				</div>
			</div>
		);
	}
}
