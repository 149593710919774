import {Application, Graphics} from 'pixi.js';
import {DropShadowFilter} from 'pixi-filters';

import {connection} from '../net/ws.js';
import FilterManager from './filter.js';
import {randomColor} from '../utils.js';

/**
 * The server ID.
 * @type {string}
 */
export let serverID = null;

/**
 * The tick interval received from the server. This value represents the length of a loop tick in milliseconds. It is equal to 1000 divided by the frame rate at which the loop runs at.
 * @type {number?}
 */
export let tickInterval = null;

/**
 * The update tick interval received from the server. This value represents the length of an update tick in milliseconds.
 * @type {number?}
 */
export let updateTickInterval = null;

connection.registerConnectionListener(({serverID: id, tickInterval: interval, updateTickInterval: updateInterval}) => {
	serverID = id;
	tickInterval = interval;
	updateTickInterval = updateInterval;
});

/**
 * The shade of red used in the game, mainly used for the Classic game mode and the score. The client may add slight variations to this color.
 */
export const red = 0xff0a37;

/**
 * The shade of green used in the game, mainly used the score. The client may add slight variations to this color.
 */
export const green = 0x0aff54;

export const app = new Application({
	width: window.innerWidth,
	height: window.innerHeight,
	backgroundColor: randomColor(),
	antialias: true,
	view: document.getElementById('canvas'),
	resizeTo: document.getElementById('canvas'),
});

/**
 * A manager to add and remove filters from the app's stage.
 */
export const filters = new FilterManager(app.stage);

/**
 * The position of the mouse, relative to the upper-left corner of the page.
 */
export const mouse = {x: 0, y: 0};
window.addEventListener('mousemove', e => {
	mouse.x = e.clientX;
	mouse.y = e.clientY;
});

/**
 * Creates a PIXI graphics object that can be used to draw UI elements and graphics.
 */
function createUI() {
	const ui = new Graphics();
	ui.filters = [
		new DropShadowFilter({alpha: 1, blur: 1, distance: 2})
	];
	return ui;
}

/**
 * A PIXI graphics object that can be used to draw UI elements and graphics.
 */
export const ui = createUI();

/**
 * Special PIXI graphics object for tutorial UI elements. Tutorial UI elements are drawn on top of everything else.
 */
export const tutorialUI = createUI();

/**
 * The current state of the game. It can be one of three values:
 * `0`: menu
 * `1`: game
 * `2`: editor
 */
export let state = 0;

/**
 * Change the current state of the game.
 * @param {number} newState
 */
export function changeState(newState) {
	state = newState;

	for (const child of app.stage.children) {
		child.clear?.();
		child.removeChildren?.();
	}
}
