import {Graphics} from 'pixi.js';

/**
 * An interface for any possible game mode in a level. It is similar in use case to the GameMode trait on the server-side, but with differences meant to help with client-side development.
 * @interface
 */
export class GameMode {
	/**
	 * Construct a fresh instance of the game mode.
	 * @param {Level} level The level for the game mode to use.
	 */
	constructor() { }

	/**
	 * Initialize the game mode's data.
	 * @param {object} roomState An object describing the state of all users in the room.
	 * @param {object} data An object containing user data needed to run the game mode.
	 */
	init() {
		throw new Error('GameMode is an abstract class');
	}

	/**
	 * Initialize the game mode's initial guide UI.
	 */
	initGuide() {
		throw new Error('GameMode is an abstract class');
	}

	/**
	 * Initialize a replay of this game mode.
	 * @param {object} data The data of the replay.
	 */
	initReplay() {
		throw new Error('GameMode is an abstract class');
	}

	/**
	 * Process a mode update from the server.
	 * @param {string} type The type of the update.
	 * @param {object} data
	 */
	updateState() {
		throw new Error('GameMode is an abstract class');
	}

	/**
	 * Indicate the time remaining in the round to the game mode.
	 * @param {number} time The time remaining in the round, in seconds.
	 */
	setTimeRemaining() {
		throw new Error('GameMode is an abstract class');
	}

	/**
	 * Handle a new user joining the game.
	 * @param {string} uuid The UUID of the user.
	 * @param {object} data
	 * @return {Player}
	 */
	handleUserJoin() {
		throw new Error('GameMode is an abstract class');
	}

	/**
	 * Handle a user leaving the game.
	 * @param {string} uuid The UUID of the user.
	 */
	handleUserLeave() {
		throw new Error('GameMode is an abstract class');
	}

	/**
	 * Handle starting data from the server.
	 * @param {object} data
	 */
	handleStartData() {
		throw new Error('GameMode is an abstract class');
	}

	/**
	 * Returns the spawn of the user with the given UUID.
	 * @param {string} uuid The UUID of the user.
	 * @returns {Spawn}
	 */
	getSpawn() {
		throw new Error('GameMode is an abstract class');
	}

	/**
	 * Returns true if the game mode is currently in a round.
	 * @returns {boolean}
	 */
	hasStarted() {
		throw new Error('GameMode is an abstract class');
	}

	/**
	 * Start the round. This function can be used to initialize user data for the round.
	 */
	start() {
		throw new Error('GameMode is an abstract class');
	}

	/**
	 * Returns true if the camera can be freely moved by the player.
	 * @returns {boolean}
	 */
	canMoveCamera() {
		throw new Error('GameMode is an abstract class');
	}

	/**
	 * Returns true if the player with the given UUID can move.
	 * @param {string} uuid The UUID of the player.
	 * @returns {boolean}
	 */
	canMove() {
		throw new Error('GameMode is an abstract class');
	}

	/**
	 * Returns true if the client should send its input to the server.
	 * @returns {boolean}
	 */
	shouldSendInput() {
		throw new Error('GameMode is an abstract class');
	}

	/**
	 * Returns true if the user with the given UUID should be skipped in the game loop.
	 * @param {string} uuid The UUID of the user.
	 * @returns {boolean}
	 */
	shouldSkip() {
		throw new Error('GameMode is an abstract class');
	}

	/**
	 * Handle a key down event.
	 * @param {string} key The key that was pressed.
	 */
	onKeyDown() {
		throw new Error('GameMode is an abstract class');
	}

	/**
	 * Handle a key up event.
	 * @param {string} key The key that was released.
	 */
	onKeyUp() {
		throw new Error('GameMode is an abstract class');
	}

	/**
	 * Simulates a fixed update tick in the round. This function is called at the same frequency as physics computations, but only after they have been completed.
	 *
	 * This function may be called even if the round is not in progress to handle game mode-specific logic that happens at a fixed rate.
	 *
	 * Anything that should only be done during a round should be guarded by a call to `hasStarted()`.
	 */
	tick() {
		throw new Error('GameMode is an abstract class');
	}

	/**
	 * Render game-mode specific elements to the given graphics object.
	 * @param {Graphics} g The graphics object to draw to.
	 * @param {Graphics} gs The graphics object to draw to. All elements drawn to this object will have a drop shadow effect applied to them.
	 */
	draw() {
		throw new Error('GameMode is an abstract class');
	}

	/**
	 * Render game-mode specific UI to the given graphics object.
	 * @param {Graphics} g The graphics object to draw to.
	 */
	drawUI() {
		throw new Error('GameMode is an abstract class');
	}
}

/**
 * An array containing the names of all existing game modes.
 */
export const gameModeNames = ['Classic', 'Freeze Tag'];
