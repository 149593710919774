import {BitmapText, Container, Graphics, Sprite} from 'pixi.js';

import playerSprite from '../../assets/sprites/player.png';
import physics from './game_loop.js';
import {updateTickInterval} from './global.js';
import {connection} from '../net/ws.js';
import {lerpVec} from '../utils.js';

/**
 * Represents a player in the game.
 * @class
 * @property {number} maxVelocity The maximum velocity the player can move.
 */
export class Player {
	/**
	 * @param {string} uuid The player's UUID.
	 * @param {string} username The player's username.
	 * @param {number} color The color of the player.
	 * @param {number} movementStyle The movement style of the player.
	 * @param {boolean} local Is this player controlled by the local player?
	 */
	constructor(uuid = '', username = '', color = 0, movementStyle = 'friction', local = true) {
		this.uuid = uuid;
		this.username = username;

		/**
		 * Position of the player. These values are used for entity interpolation and are considered the source of truth for **non-local** players.
		 */
		this.pos = {
			/**
			 * The most recent position of the player received from the server.
			 */
			current: {x: 0, y: 0},

			/**
			 * The 2nd-most recent position of the player received from the server.
			 */
			prev: {x: 0, y: 0},
		};

		/**
		 * The player's movement style. Can be one of the following:
		 *
		 * `0`: The player's velocity will remain constant even if the player has not pressed any keys.
		 * `1`: The player's velocity will reduce when the player has not pressed any keys. This is the default.
		 */
		this.movementStyle = movementStyle;

		this.color = color;
		this.disconnected = false;
		this.local = local;

		/**
		 * PIXI text object for the player's username.
		 */
		this.text = new BitmapText(this.displayName, {fontName: 'Poppins', fontSize: 15});
		this.text.tint = this.color;
		this.text.anchor.set(0.5, 0.5);

		/**
		 * The player's sprite.
		 */
		this.sprite = Sprite.from(playerSprite);
		this.sprite.tint = this.color;
		this.sprite.anchor.set(0.5, 0.5);

		/**
		 * Information to help display the player's boost.
		 */
		this.boost = {
			/**
			 * The opacity of the boost bar.
			 */
			opacity: 0,

			/**
			 * The Graphics object that displays the player's boost.
			 */
			bar: new Graphics(),

			/**
			 * The percentage of boost the player has. This should be a number between 0 and 1, where 0 is no boost and 1 is full boost.
			 */
			percentage: 0,

			/**
			 * The previous percentage of boost the player had. This is used to determine if the player's boost has changed.
			 */
			prevPercentage: 0,

			/**
			 * The number of update ticks that the current and previous boost percentages have been the same. This is used to determine whether to show the boost bar.
			 */
			sameBoostTicks: Infinity,
		}
	}

	/**
	 * Returns the player's display name. This is the player's username if set, otherwise it is their UUID.
	 */
	get displayName() {
		return this.username || this.uuid;
	}

	/**
	 * Add the player's sprite to the given container.
	 * @param {Container} c The container to add the player's sprite to.
	 * @param {{x: number, y: number}} pos The position to draw the player at.
	 * @param {number} opacity
	 */
	draw(c, pos, opacity = 1) {
		this.text.alpha = this.sprite.alpha = opacity;
		this.text.x = this.sprite.x = pos.x;
		this.text.y = pos.y - 25;
		this.sprite.y = pos.y;
		c.addChild(this.text, this.sprite);

		// draw boost bar beneath the player
		if (this.boost.percentage !== this.boost.prevPercentage) {
			this.boost.sameBoostTicks = 0;
		} else {
			++this.boost.sameBoostTicks;
		}

		if (this.boost.sameBoostTicks > 120) { // when the player's boost has been the same for a while (~2 sec), we start to fade out the boost bar
			this.boost.opacity = Math.max(this.boost.opacity - 0.05, 0);
		} else { // otherwise, we fade in the boost bar, but more quickly
			this.boost.opacity = Math.min(this.boost.opacity + 0.1, 1);
		}

		this.boost.bar.clear()
			.beginFill(0xffffff, Math.max(this.boost.opacity - 0.6, 0))
			.drawRect(pos.x - 30, pos.y + 25, 60, 10)
			.beginFill(0xffffff, this.boost.opacity)
			.drawRect(pos.x - 30, pos.y + 25, 60 * this.boost.percentage, 10)
			.endFill();
		c.addChild(this.boost.bar);

		this.boost.prevPercentage = this.boost.percentage;
	}

	/**
	 * Returns a position interpolated between the last known positions of the player, based on the update tick interval.
	 */
	computeInterpolatedPos() {
		const parameter = (updateTickInterval - physics.timeToUpdate) / updateTickInterval;
		return lerpVec(this.pos.prev, this.pos.current, parameter);
	}

	/**
	 * Forcibly set the player's position. Both the player's current and previous positions will be set to the given position, effectively removing any interpolation.
	 * @param {{x: number, y: number}} vec The position to set the player to.
	 */
	setPos(vec) {
		this.pos.current.x = this.pos.prev.x = vec.x;
		this.pos.current.y = this.pos.prev.y = vec.y;
	}

	/**
	 * Set the amount of boost the player has.
	 * @param {number} boost The percentage of boost the player has. This should be a number between 0 and 1.
	 */
	setBoost(boost) {
		this.boost.percentage = boost;
	}
}

Object.defineProperty(Player, 'maxVelocity', {value: 4});

/**
 * The local player. It is marked as nullable, but it will get set to a valid player after the user makes a WebSocket connection to the server.
 * @type {Player?}
 */
export let localPlayer = null;

connection.registerConnectionListener((_, {username, color, movementStyle}) => {
	localPlayer = new Player(connection.uuid, username, color, movementStyle);
});

/**
 * If the given player is the local player, return the username with "(you)" appended. Otherwise, return the player's username.
 * @param {Player} player
 */
export function name(player) {
	return player === localPlayer ? `${player.username} (you)` : player.username;
}
