import {Component} from 'react';
import * as ReactDOM from 'react-dom/client';

import About from './about.js';
import Complete from './complete.js';
import EditorMain from './editor/main.js';
import LeaderboardMain from './leaderboard/main.js';
import Login from './login.js';
import LoginSuccess from './login_success.js';
import Menu from './menu.js';
import Post from './post.js';
import Room from './room.js';
import RoomBrowser from './room_browser.js';
import Settings from './settings.js';
import SoloLevelSelect from './level_select/solo.js';
import PlayMenu from './play_menu.js';
import {api} from '../scripts/net/api.js';
import {compound} from '../scripts/net/type.js';
import {isMobile, openFile} from '../scripts/utils.js';

/**
 * Handles the display of the UI.
 */
class App extends Component {
	constructor(props) {
		super(props);
		this.state = {
			/**
			 * Whether to hide the login success window or not.
			 */
			hideSuccess: false,

			/**
			 * If the user has successfully signed in / registered. The value is a number from 0 to 2, with the following meaning:
			 * `0`: not signed in or registered
			 * `1`: signed in
			 * `2`: registered
			 */
			signIn: 0,

			/**
			 * Whether to show the main menu.
			 */
			showMenu: false,

			/**
			 * Whether the user is in the play menu.
			 */
			inPlayMenu: false,

			/**
			 * Whether the user is selecting a level to play (in solo mode).
			 */
			selectLevel: false,

			/**
			 * Whether or not the user is in the room browser.
			 */
			browsingRooms: false,

			/**
			 * Whether or not the user is viewing the leaderboards.
			 */
			showLeaderboards: false,

			/**
			 * Whether or not the user is in the level editor. This indicates to the level editor UI that it should begin responding to user commands in the editor.
			 */
			inEditor: false,

			/**
			 * Whether or not the user is changing settings.
			 */
			showSettings: false,

			/**
			 * Whether or not the user is viewing the about page.
			 */
			showAbout: false,

			/**
			 * Whether or not the user is currently in a room.
			 */
			inRoom: false,

			/**
			 * Whether or not to force the <Room/> component to open.
			 */
			showRoom: false,
		};
	}

	/**
	 * Called by <Login/> after the user has successfully signed in / registered and connected to the server.
	 * @param {boolean} signIn True if the user was logging in, false if the user registered an account.
	 * @param {string} username The username the user signed in with.
	 */
	onSignInSuccess(signIn, username) {
		this.setState({signIn: signIn ? 1 : 2, showMenu: true, username});
		setTimeout(() => this.setState({hideSuccess: true}), 2000);
	}

	/**
	 * Called by <Menu/> when the user clicks on the toggle button on the menu's sidebar.
	 */
	onToggleMenu() {
		this.setState({showMenu: !this.state.showMenu});
	}

	/**
	 * Called by <Menu/> when the user clicked one of the options.
	 * @param {string} name The name of the option the user clicked.
	 */
	async onOptionClick(name) {
		const {changeState} = await import('../scripts/game/global.js');

		switch (name) {
			case 'lobby':
				this.setState({showRoom: true});
				break;

			case 'play':
				this.setState({inPlayMenu: true});
				break;

			case 'leaderboards':
				this.setState({showLeaderboards: true});
				break;

			case 'edit':
				const {editor, initEditorGuide} = await import('../scripts/game/editor.js');

				this.setState({showMenu: false, inEditor: true});

				editor.level.centerCamera();
				initEditorGuide();
				changeState(2);
				break;

			case 'replay':
				const {game} = await import('../scripts/game/game.js');
				const data = await openFile(['replay'], 'bin');

				const {value} = compound.Replay.parse(data);

				await game.loadReplay(value);
				changeState(1);
				this.setState({showMenu: false});
				break;

			case 'settings':
				this.setState({showSettings: true});
				break;

			case 'about':
				this.setState({showAbout: true});
				break;
		}
	}

	/**
	 * Called by <PlayMenu/> when the user clicks the "close" button.
	 */
	onClosePlayMenu() {
		this.setState({inPlayMenu: false});
	}

	/**
	 * Called by <SoloLevelSelect/> when the user clicks the "start" button.
	 */
	onStartSolo() {
		this.setState({showMenu: false, inPlayMenu: false, selectLevel: false});
	}

	/**
	 * Called by <SoloLevelSelect/> when the user clicks the "close" button.
	 */
	onCloseSoloSelect() {
		this.setState({inPlayMenu: true, selectLevel: false});
	}

	/**
	 * Called by <PlayMenu/> when the user clicks one of the options.
	 * @param {string} name The name of the option the user clicked.
	 */
	async onPlayMenuOptionClick(name) {
		switch (name) {
			case 'solo':
				this.setState({inPlayMenu: false, selectLevel: true});
				break;

			case 'multi':
				this.setState({inPlayMenu: false, browsingRooms: true});
				break;

			case 'tutorial':
				const {game} = await import('../scripts/game/game.js');
				await game.tutorial.load();
				this.setState({inPlayMenu: false, showMenu: false});
				break;
		}
	}

	/**
	 * Called by <Settings/> when the user has applied their settings and closed the settings menu.
	 */
	onFinishSettings() {
		this.setState({showSettings: false});
	}

	/**
	 * Called by <About/> when the user clicks the "close" button.
	 */
	onCloseAbout() {
		this.setState({showAbout: false});
	}

	/**
	 * Called by <LeaderboardMain> -> <Leaderboard> when the user clicks the "watch" button on a replay entry in the leaderboard.
	 * @param {number} levelID The ID of the level the replay was played on.
	 * @param {string} uuid The UUID of the entry in the leaderboard.
	 */
	async onReplay(levelID, uuid) {
		const {game} = await import('../scripts/game/game.js');
		const {changeState} = await import('../scripts/game/global.js');
		const data = await api().replays[levelID][uuid].get();

		const {value: replay} = compound.Replay.parse(data);

		await game.loadReplay(replay);
		changeState(1);
		this.setState({showMenu: false, showLeaderboards: false});
	}

	/**
	 * Called by <Room/> when the user clicks the "start" or "back to game" button.
	 */
	onStart() {
		this.setState({showMenu: false, showRoom: false});
	}

	/**
	 * Called by <Room/> when the user clicks the "abort game" button.
	 */
	onAbort() {
		this.setState({showRoom: true});
	}

	/**
	 * Called by <Room/> when the user clicks the "leave" button.
	 */
	onLeave() {
		this.setState({browsingRooms: true, inRoom: false, showRoom: false});
	}

	/**
	 * Called by <RoomBrowser/> when the user clicks the "+create room" or "join room" button.
	 */
	onEnterRoom() {
		this.setState({browsingRooms: false, inRoom: true, showRoom: true});
	}

	/**
	 * Called by <RoomBrowser/> when the user clicks the "back" button.
	 */
	onCloseBrowser() {
		this.setState({inPlayMenu: true, browsingRooms: false});
	}

	render() {
		return isMobile()
			? <div id="mobile-unsupported" className="window">
				<h1>mobile platforms are not yet supported! :(</h1>
				<h2>please use a desktop computer / browser to play <b>Pumpkin</b>!</h2>
			</div>
			: <div style={{position: 'fixed'}}>
				<Menu hide={!this.state.showMenu} inRoom={this.state.inRoom} onOptionClick={this.onOptionClick.bind(this)} onToggleMenu={this.onToggleMenu.bind(this)}/>
				<PlayMenu hide={!this.state.inPlayMenu} onOptionClick={this.onPlayMenuOptionClick.bind(this)} onClosePlayMenu={this.onClosePlayMenu.bind(this)}/>
				<SoloLevelSelect hide={!this.state.selectLevel} onStartSolo={this.onStartSolo.bind(this)} onCloseSoloSelect={this.onCloseSoloSelect.bind(this)}/>
				<EditorMain/>
				<Complete/>
				<RoomBrowser onCloseBrowser={this.onCloseBrowser.bind(this)} hide={!this.state.browsingRooms}/>
				<Room onEnterRoom={this.onEnterRoom.bind(this)} inRoom={this.state.inRoom} showRoom={this.state.showRoom} onStart={this.onStart.bind(this)} onAbort={this.onAbort.bind(this)} onLeave={this.onLeave.bind(this)}/>
				<LeaderboardMain hide={!this.state.showLeaderboards} onCloseLeaderboards={() => this.setState({showLeaderboards: false})} onReplay={this.onReplay.bind(this)}/>
				<Settings hide={!this.state.showSettings} onFinishSettings={this.onFinishSettings.bind(this)}/>
				<Login onSignInSuccess={this.onSignInSuccess.bind(this)} succeeded={this.state.signIn !== 0}/>
				<LoginSuccess signIn={this.state.signIn} username={this.state.username} hide={this.state.hideSuccess}/>
				<Post/>
				<About hide={!this.state.showAbout} onCloseAbout={this.onCloseAbout.bind(this)}/>
			</div>;
	}
}

const container = document.getElementById('ui');
const root = ReactDOM.createRoot(container);
root.render(<App/>);
