const endpoint = process.env.DOMAINS.split(',')[0];
let token = '';

/**
 * Standard fetch(), but a timeout in milliseconds can be set as well; if the request takes longer than the given timeout to complete, the function rejects with `null`.
 * If no timeout is provided, the function cannot reject due to a timeout.
 */
async function fetchTimeout(path, opts, timeout = 8000) {
	const controller = new AbortController();
	const t = setTimeout(() => controller.abort(), timeout);
	const res = await fetch(path, {...opts, signal: controller.signal});
	clearTimeout(t);
	return res;
}

const handler = {
	/**
	 * Extend the endpoint path with `prop` as a path segment. The request can be ended by calling one of the available HTTP methods: GET, POST, PUT, DELETE.
	 *
	 * There are a few special values for `prop` that can be used to modify the request:
	 * - `postForm`: Same as a POST request, but the body is a FormData object instead of a JSON object.
	 * - `param`: Returns a function that when called, appends the given key / data pair as a search parameter string to the endpoint path.
	 * - `path`: The endpoint path is returned instead of the request being sent.
	 * @param {{path: Array<string>, params: URLSearchParams}} target
	 * @param {string} prop
	 * @param {Proxy} proxy
	 */
	get(target, prop, proxy) {
		switch (prop) {
			case 'get':
			case 'post':
			case 'put':
			case 'delete':
			case 'postForm':
				const form = prop === 'postForm';
				return async (data, json = true) => {
					const opts = {headers: {}};
					if (token) opts.headers['Authorization'] = `Bearer ${token}`;

					if (form) {
						opts.method = 'post';
						opts.body = new URLSearchParams(new FormData(data));
						opts.headers['Content-Type'] = 'application/x-www-form-urlencoded';
					} else {
						opts.method = prop;
						opts.body = JSON.stringify(data);
						opts.headers['Content-Type'] = 'application/json';
					}

					const res = await fetchTimeout(proxy.path, opts);
					if (res.ok) {
						// check if response contains JSON data
						if (json && res.headers.get('Content-Type')?.includes('application/json')) {
							return res.json();
						} else if (res.headers.get('Content-Type')?.includes('application/octet-stream')) { // check if response contains binary data
							return res.arrayBuffer();
						} else {
							return res;
						}
					} else {
						throw res;
					}
				};

			case 'param':
				return (key, data) => {
					target.params.append(key, data);
					return proxy;
				};

			case 'path':
				const params = target.params.toString();
				return `${endpoint}/${target.path.join('/')}${params ? `?${params}` : ''}`;

			default:
				target.path.push(prop);
				return proxy;
		}
	},
};

/**
 * Allows easy creation of requests to API endpoints, using Proxy.
 */
export function api() {
	return new Proxy({
		path: ['api'],
		params: new URLSearchParams(),
	}, handler);
}

/**
 * Set the authorization token to use for requests.
 * @param {string} t
 */
export function setAuth(t) {
	token = t;
}