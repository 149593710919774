import {Component} from 'react';

import {api} from '../../scripts/net/api.js';
import Button from '../base/button.js';
import {formatTime, formatTimeSince} from '../../scripts/utils.js';

/**
 * Cache of users that have been fetched from the server, mapping their UUID to their username.
 * @type {Object<string, string>}
 */
const userCache = {};

/**
 * Represents a leaderboard for a map.
 */
export default class Leaderboard extends Component {
	constructor(props) {
		super(props);
		this.state = {
			/**
			 * The ID of the level to show the leaderboard for.
			 */
			level: null,

			/**
			 * The entries in the leaderboard.
			 * @type {Array<{user: string, details: {score: number, timeSpent: number}, timestamp: Date, hasReplay: boolean}>?}
			 */
			entries: null,
		};
	}

	/**
	 * Helper to fetch all leaderboard entries from the server and set the state with them.
	 */
	async setEntries() {
		const leaderboard = await api().maps.leaderboard[this.props.level].get();
		for (const entry of leaderboard.entries) {
			const username = userCache[entry.user] || (await api().users[entry.user].get()).username;
			userCache[entry.user] = entry.user = username;
			entry.timestamp = new Date(entry.timestamp);
		}

		this.setState({
			level: this.props.level,
			entries: leaderboard.entries,
		});
	}

	render() {
		if (!this.state.entries || this.props.level !== this.state.level) this.setEntries();
		if (!this.state.entries) return <div id="leaderboards-blank"><h1>loading...</h1></div>;
		return (
			this.state.entries.length === 0
				? <div id="leaderboards-blank">
					<h1>no entries yet :(</h1>
					<h2>you can be the first to submit a score!</h2>
				</div>
				: <table>
					<thead>
						<tr>
							<th>rank</th>
							<th>username</th>
							<th>score</th>
							<th>time spent</th>
							<th>date</th>
							<th>replay</th>
						</tr>
					</thead>
					<tbody>
						{
							this.state.entries.map((obj, i) => {
								return <tr key={obj.uuid}>
									<td><b>{i + 1}</b></td>
									<td>{obj.user}</td>
									<td>{obj.details.score}</td>
									<td>{formatTime(obj.details.timeSpent)}</td>
									<td title={obj.timestamp.toLocaleString()}>{formatTimeSince(obj.timestamp)}</td>
									<td>
										{
											obj.hasReplay
												? <Button onClick={() => this.props.onReplay(this.state.level, obj.uuid)} value="watch"/>
												: <Button disabled value="unavailable"/>
										}
									</td>
								</tr>;
							})
						}
					</tbody>
				</table>
		);
	}
}
