import {Component} from 'react';

import arrow from '../assets/sprites/arrow.svg';
import {connection} from '../scripts/net/ws.js';
import {localPlayer} from '../scripts/game/player.js';

/**
 * Represents the chat window in a room.
 */
export default class Chat extends Component {
	constructor(props) {
		super(props);
		this.state = {
			/**
			 * Whether the user is scrolled to the bottom of the chat.
			 */
			userAtBottom: true,
		};
	}

	componentDidMount() {
		const messages = document.getElementById('chat-messages');
		messages.addEventListener('scroll', () => {
			const atBottom = messages.scrollTop + messages.clientHeight >= messages.scrollHeight - 2; // 2px buffer
			if (this.state.userAtBottom !== atBottom) this.setState({userAtBottom: atBottom});
		});
	}

	componentDidUpdate() {
		// scroll to the bottom of the chat if the user is scrolled to the bottom
		if (this.state.userAtBottom) this.scrollToBottom();
	}

	/**
	 * Scroll to the bottom of the chat window.
	 * @param {boolean} instant Whether to scroll instantly (true, default) or with an animation (false).
	 */
	scrollToBottom(instant = true) {
		const messages = document.getElementById('chat-messages');
		messages.scroll({top: messages.scrollHeight, behavior: instant ? 'auto' : 'smooth'});
	}

	/**
	 * The user pressed a key in the chat input.
	 * @param {KeyboardEvent} e
	 */
	async onKeyDown(e) {
		if (e.key === 'Enter') {
			const input = document.getElementById('chat-input');
			const content = input.value.trim();
			if (!content) return;

			await connection.send('chat', content);
			input.value = '';
		}
	}

	render() {
		return (
			<div id="chat" className="flex-column modal">
				<h2>Chat</h2>
				<div id="chat-messages" className="scrollable">
					{
						this.props.messages.map((message, i) => {
							const user = this.props.users.get(message.uuid);
							return (
								<div className="chat-message" key={i}>
									<p>
										<span style={{color: `#${user.color.toString(16).padStart(6, '0')}`}}>
											{user.username}
											{message.uuid === localPlayer.uuid ? ' (you)' : ''}
										</span>
										&nbsp;
										{message.content}
									</p>
								</div>
							);
						})
					}
				</div>
				<div id="chat-scroll-to-bottom" className={this.state.userAtBottom ? 'hidden' : ''} onClick={() => this.scrollToBottom(false)}>
					<img id="chat-scroll-to-bottom-icon" src={arrow} className={this.state.userAtBottom ? 'flip' : ''}/>
				</div>
				<input id="chat-input" type="text" autoComplete="off" placeholder="type a message..." onKeyDown={this.onKeyDown.bind(this)}/>
			</div>
		);
	}
}
