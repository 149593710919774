import {Component} from 'react';

import Entity from './entity.js';
import {focus} from '../../scripts/utils.js';
import Metadata from './metadata.js';

/**
 * Represents the UI that helps users make fine-grained edits in the level editor.
 */
export default class EditorMain extends Component {
	constructor(props) {
		super(props);
		this.state = {
			/**
			 * Whether to hide the UI.
			 */
			hide: true,

			/**
			 * The screen that is currently being shown. Can be either 'main' or 'metadata'.
			 */
			screen: 'main',
		};

		this.showMain = _ => this.setState({hide: false, screen: 'main'});
		this.showMetadata = _ => this.setState({hide: false, screen: 'metadata'});
		this.close = _ => this.setState({hide: true});
	}

	componentDidMount() {
		import('../../scripts/game/editor.js')
			.then(({emitter: ui}) => {
				ui.addEventListener('add', this.showMain);
				ui.addEventListener('select', this.showMain);
				ui.addEventListener('metadata', this.showMetadata);
				ui.addEventListener('close', this.close);
			});
	}

	componentWillUnmount() {
		import('../../scripts/game/editor.js')
			.then(({emitter: ui}) => {
				ui.removeEventListener('add', this.showMain);
				ui.removeEventListener('select', this.showMain);
				ui.removeEventListener('metadata', this.showMetadata);
				ui.removeEventListener('close', this.close);
			});
	}

	/**
	 * The user clicked on the "delete" button when adding / selecting an entity.
	 */
	async onDelete() {
		const {emitter: ui} = await import('../../scripts/game/editor.js');
		this.setState({hide: true});
		ui.delete2();
		focus();
	}

	/**
	 * The user clicked on the "cancel" / "close" button when adding / editing an entity or when editing the level metadata.
	 */
	async onCancel() {
		const {emitter: ui} = await import('../../scripts/game/editor.js');
		this.setState({hide: true});
		ui.cancel();
		focus();
	}

	render() {
		return (
			<div id="editor-info" className={`${this.state.hide ? 'hidden ' : ''}flex-column`}>
				{
					this.state.screen === 'main'
						? <Entity onDelete={this.onDelete.bind(this)} onCancel={this.onCancel.bind(this)}/>
						: <Metadata onCancel={this.onCancel.bind(this)}/>
				}
			</div>
		);
	}
}
