import {AbstractFormat, GameModeFormat, ObjectFormat, RuleFormat, TypeFormat} from './format.js';
import Packet from './packet.js';
import {abstract, compound, concrete, KnownAbstractType} from './type.js';

/**
 * Represents a rule that describes how to parse an binary event message from the server.
 *
 * Note that while every binary message from the server begins with one byte that indicates the type of the message, this class skips that byte and only parses the rest of the message.
 * @class
 */
export class Rule {
	/**
	 * @param {string?} type
	 * @param {RuleFormat?} format
	 * @param {function(*) => *} map
	 */
	constructor(type, format, map) {
		/**
		 * The type the resulting packet will have. If this is null, the packet will be marked as being non-event.
		 */
		this.type = type;

		/**
		 * A format that describes how to format the resulting packet data. If this is null, this indicates that the packet has no additional data.
		 */
		this.format = format;

		/**
		 * An optional function that maps the resulting packet data to different data.
		 */
		this.map = map;
	}

	/**
	 * Parses the given ArrayBuffer into a Packet.
	 * @param {ArrayBuffer} buffer
	 * @param {string} gameMode The game mode of the room the user is in, if any.
	 */
	parse(buffer, gameMode) {
		const data = this.format ? this.format.parse(buffer, gameMode) : null;
		return new Packet(this.type, this.map ? this.map(data) : data, !!this.type);
	}
}

/**
 * Rules that define how **event binary packets** received from the server are parsed.
 */
export const eventRules = {
	0x10: new Rule('room_create'),
	0x11: new Rule('room_join', new ObjectFormat([
		{type: concrete.Uuid, key: 'host'},
		{type: new KnownAbstractType(abstract.Option, [concrete.u8]), key: 'gameMode'},
		{type: new KnownAbstractType(abstract.Option, [compound.Level]), key: 'level'},
	])),
	0x12: new Rule('room_modify', new ObjectFormat([
		{type: new KnownAbstractType(abstract.Option, [concrete.Uuid]), key: 'host'},
		{type: new KnownAbstractType(abstract.Option, [concrete.u8]), key: 'gameMode'},
		{type: new KnownAbstractType(abstract.Option, [compound.Level]), key: 'level'},
	])),
	0x13: new Rule('room_start', new GameModeFormat(0x13)),
	0x14: new Rule('room_abort'),
	0x15: new Rule('chat', new ObjectFormat([
		{type: concrete.Uuid, key: 'uuid'},
		{type: compound.String, key: 'content'},
	])),
	0x1a: new Rule('room_short_user_id', new ObjectFormat([
		{type: concrete.Uuid, key: 'uuid'},
		{type: concrete.u8, key: 'shortID'},
	])),
	0x1b: new Rule('room_short_user_ids', new AbstractFormat(abstract.Map, [concrete.Uuid, concrete.u8])),
	0x1c: new Rule('player_join', new TypeFormat(concrete.Uuid)),
	0x1d: new Rule('spectator_join', new TypeFormat(concrete.Uuid)),
	0x1e: new Rule('round_in_progress', new TypeFormat(concrete.u16)),
	0xa1: new Rule('start_countdown'),
	0xa2: new Rule('start_data', new GameModeFormat(0xa2)),
	0xa3: new Rule('countdown', new TypeFormat(concrete.u8)),
	0xa4: new Rule('player_states', new ObjectFormat([
		{type: concrete.u16, key: 'tick'},
		{type: concrete.u16, key: 'seq'},
		{type: new KnownAbstractType(abstract.Map, [concrete.u8, compound.State]), key: 'states'},
	])),
	0xa5: new Rule('player_states_for_spectators', new AbstractFormat(abstract.Map, [concrete.u8, compound.State])),
	0xa6: new Rule('physics', new TypeFormat(concrete.u8), num => num ? 1 : -1),
	0xa7: new Rule('warning', new TypeFormat(concrete.u8)),
	0xb0: new Rule('player_ready', new TypeFormat(concrete.u8)),
	0xb1: new Rule('player_disconnect', new TypeFormat(concrete.u8)),
	0xb2: new Rule('spectator_disconnect', new TypeFormat(concrete.u8)),
	0xb3: new Rule('switch_user_role', new ObjectFormat([
		{type: concrete.u8, key: 'shortID'},
		{type: concrete.u8, key: 'newRole'},
	]), obj => {
		return {
			shortID: obj.shortID,
			newRole: ['player', 'spectator'][obj.newRole],
		};
	}),
	0xc0: new Rule('round_end', new GameModeFormat(0xc0)),
	0xc1: new Rule('completed', new GameModeFormat(0xc1)),
};

/**
 * Rules that define how **non-event binary packets** received from the server are parsed.
 *
 * Binary packets whose first byte is `0xf0` are **non-event packets**, which are packets sent in response to a client's request.
 *
 * In the event the client sends a binary packet using `Connection#send`, and has received a valid acknowledgement, parsing said acknowledgement will begin by matching the second byte to one of the base rule objects below. When the correct rule is found, parsing will continue at the third byte, using the rules defined below (since the first byte is `0xf0` and the second byte is the client's packet type).
 *
 * The rules defined below intentionally omit the type of the packet, compared to the rules defined above. Prior to this change, the server responded to clients by sending custom packet types with no data (see server's response to `ready` packet), and this wasn't standardized at all. This change instead causes those custom packet types to be considered as data, and indirectly makes Packet objects with a null `type` field easily identifiable as response packets.
 */
export const nonEventRules = {
	0xa0: new Rule(null),
	0xa1: new Rule(null, new TypeFormat(concrete.u8), num => {
		return {
			0xa0: 'starting',
			0xa1: 'waiting',
			0xb0: 'noop',
			0xb1: 'not_enough_players',
		}[num];
	}),
};

/**
 * Rules that define how unique **game-mode event binary packets** received from the server are parsed.
 *
 * Game-mode event packets all begin with `0xf2`.
 *
 * When the client receives a game-mode event packet, parsing will begin by matching the second byte to the base rule object for the client's current game mode. When the correct rule is found, parsing will continue at the third byte, using the rules defined below (since the first byte is `0xf2` and the second byte is the client's packet type), like the above non-event rules.
 */
export const gameModeEventRules = {
	// classic
	0: {
		0xa1: new Rule('player_passed', new TypeFormat(concrete.Uuid)),
		0xa2: new Rule('player_failed', new ObjectFormat([
			{type: concrete.Uuid, key: 'uuid'},
			{type: new KnownAbstractType(abstract.Option, [concrete.Uuid]), key: 'target'},
			{type: concrete.u8, key: 'reason'},
		]), ({uuid, target, reason}) => {
			return {
				uuid,
				target,
				reason: ['collide', 'collidePast', 'outOfBounds', 'froze', 'neverMoved'][reason],
			};
		}),
	},

	// freeze_tag
	1: {
		0xa1: new Rule('tag', new ObjectFormat([
			{type: concrete.Uuid, key: 'a'},
			{type: concrete.Uuid, key: 'b'},
			{type: concrete.u32, key: 'aScore'},
			{type: concrete.Vector, key: 'bPos'},
		])),
		0xa2: new Rule('scores', new AbstractFormat(abstract.Map, [concrete.Uuid, concrete.u32])),
		0xa3: new Rule('multiplier', new TypeFormat(concrete.u8)),
		0xa4: new Rule('finish', new TypeFormat(concrete.u8)),
	},
};

/**
 * Given binary data received from the server, parse it according to the rules defined above.
 * @param {ArrayBuffer} buffer The binary data received from the server.
 * @param {string} gameMode The game mode of the room the user is in, if any.
 * @returns {Packet}
 */
export function parseData(buffer, gameMode) {
	const view = new DataView(buffer);
	const firstByte = view.getUint8(0);

	try {
		switch (firstByte) {
			case 0xf0: // non-event packet
				// if we are here, we have already verified that this received data is an acknowledgement
				// all that is left is parsing the packet data
				return nonEventRules[view.getUint8(1)].parse(buffer.slice(2), gameMode); // start at third byte

			case 0xf2: // game-mode event packet
				// same as above
				return gameModeEventRules[gameMode][view.getUint8(1)].parse(buffer.slice(2), gameMode); // start at third byte

			default:
				return eventRules[firstByte].parse(buffer.slice(1), gameMode); // start at second byte
		}
	} catch (e) {
		console.error('Failed to parse packet content:', buffer, e);
	}
}
