import {Component} from 'react';

import Button from '../base/button.js';
import Leaderboard from './map.js';
import {getLevels} from '../../scripts/net/utils.js';

/**
 * Represents the screen with the leaderboards for each map.
 */
export default class LeaderboardMain extends Component {
	constructor(props) {
		super(props);
		this.state = {
			/**
			 * The ID and names of all levels fetched from the server.
			 * @type {Array<{id: number, name: string}>}
			 */
			levels: [],

			/**
			 * The ID of the selected level.
			 * @type {number?}
			 */
			selectedLevel: null,
		};
	}

	shouldComponentUpdate(nextProps, nextState) {
		// only update if the hide prop changes or if the state changes at all
		return !this.props.hide || this.props.hide !== nextProps.hide
			|| this.state.levels.length !== nextState.levels.length;
	}

	/**
	 * Helper to fetch all levels from the server and set the state with them.
	 */
	async setLevels() {
		const levels = (await getLevels()).map(level => ({id: level.info.id.Official, name: level.info.title}));
		this.setState({levels});
	}

	/**
	 * The user selected a level from the list.
	 * @param {number} id The ID of the level.
	 */
	async onLevelSelect(id) {
		this.setState({selectedLevel: id});
	}

	render() {
		if (!this.state.levels.length && !this.props.hide) this.setLevels();
		return (
			<div className={`${this.props.hide ? 'hidden ' : ''}flex-column window`}>
				<h1>leaderboards</h1>
				<div className="levels-list-content">
					<div className="scrollable perm-modal levels-list">
						{
							this.state.levels.map(level => (
								<div className={`option${this.state.selectedLevel === level.id ? ' selected' : ''}`} key={level.id} onClick={() => this.onLevelSelect(level.id)}>
									<h2>{level.name}</h2>
								</div>
							))
						}
					</div>
					<div className="perm-modal levels-list-info">
						<div className="scrollable height-100">
							{
								this.state.selectedLevel !== null
									? <Leaderboard level={this.state.selectedLevel} onReplay={this.props.onReplay.bind(this)}/>
									: <div id="leaderboards-blank" className="flex-column"><h1>select a level to view its leaderboards</h1></div>
							}
						</div>
					</div>
				</div>
				<div className="button-row">
					<Button onClick={() => this.props.onCloseLeaderboards()} value="close"/>
				</div>
			</div>
		);
	}
}
