import {PureComponent} from 'react';
import sanitizeHtml from 'sanitize-html';

import {api} from '../scripts/net/api.js';
import Button from './base/button.js';

/**
 * Sanitize a string containing HTML.
 */
function sanitize(html) {
	return sanitizeHtml(html, {
		allowedTags: ['h2', 'p', 'a', 'ol', 'ul', 'li', 'b', 'i', 'span', 'img', 'audio', 'figure', 'figcaption'],
		allowedAttributes: {
			'a': ['href', 'target'],
			'span': ['style'],
			'img': ['src', 'alt', 'style', 'width'],
			'audio': ['src', 'controls'],
		},
	});
}

/**
 * Represents a blog-like post.
 */
export default class Post extends PureComponent {
	constructor(props) {
		super(props);
		this.state = {
			/**
			 * Whether to hide the post.
			 */
			hide: true,

			/**
			 * The ID of the post.
			 * @type {number?}
			 */
			id: null,

			/**
			 * The title of the post.
			 */
			title: '',

			/**
			 * The date and time the post was created.
			 * @type {Date}
			 */
			date: null,

			/**
			 * The content of the post (HTML).
			 */
			text: '',
		};
	}

	async componentDidMount() {
		const data = await api().latest_post.get();
		this.setState({hide: false, ...data, id: data._id, date: new Date(data.date)});
	}

	/**
	 * Change the post to the previous or next one.
	 * @param {number} delta The delta to change the post by, < 0 for the previous post, >= 0 for the next post.
	 */
	async changePost(delta) {
		const incr = delta < 0 ? -1 : 1;
		const newId = this.state.id + incr;
		const data = await api().this_post[newId].get();
		this.setState({...data, id: newId, date: new Date(data.date)});
	}

	/**
	 * The user clicked the "close" button.
	 */
	onClose() {
		this.setState({hide: true});
	}

	render() {
		return (
			<div className={`${this.state.hide ? 'hidden' : ''} scrollable dialog`} id="post">
				<h1>{this.state.title}</h1>
				<div className="content">
					<p><i>This window is scrollable. Scroll down to see more content.</i></p>
					{
						this.state.date
							&& <p><i>Created: {this.state.date.toLocaleString()}</i></p>
					}
					<div dangerouslySetInnerHTML={{__html: sanitize(this.state.text)}}/>
				</div>
				<div className="button-row" style={{margin: 0}}>
					<Button style={{margin: '0 1em'}} onClick={() => this.changePost(-1)} value="previous"/>
					<Button style={{margin: '0 1em'}} onClick={this.onClose.bind(this)} value="close"/>
					<Button style={{margin: '0 1em'}} onClick={() => this.changePost(1)} value="next"/>
				</div>
			</div>
		);
	}
}
