import {Component} from 'react';

import {gameModeNames} from '../scripts/game/game_mode/main.js';

/**
 * Handles the display of the room list.
 */
export default class RoomList extends Component {
	constructor(props) {
		super(props);
	}

	render() {
		return <div id="room-list" className="flex-column perm-modal">
			<table>
				<thead>
					<tr>
						<th>Name</th>
						<th>Host</th>
						<th>Mode</th>
						<th>Players</th>
						<th>In-game?</th>
					</tr>
				</thead>
				<tbody>{
					this.props.rooms.map((obj, i) =>
						<tr key={i} className={`room-list-entry${this.props.selectedRoom === obj.uuid ? ' selected': ''}`} onClick={() => this.props.onRoomClick(obj.uuid)}>
							<td>{obj.name}</td>
							<td>{obj.host}</td>
							{
								typeof obj.mode === 'number' ?
									<td>{gameModeNames[obj.mode]}</td> :
									<td className="italic">none</td>
							}
							<td>{obj.players}/255</td>
							<td>{obj.inGame ? '●' : '○'}</td>
						</tr>
					)
				}</tbody>
			</table>
			{
				this.props.rooms.length === 0 &&
					<h1 className="italic">No rooms found</h1>
			}
		</div>;
	}
}