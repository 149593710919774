import {PureComponent} from 'react';

import arrow from '../assets/sprites/arrow.svg';
import logo from '../logo.png';

/**
 * The default options the user can select from in the main menu.
 */
const options = [
	{
		name: 'play',
		description: 'find or create a room',
		svg: <path d="M 0,15 Q 0,0 15,0 L 492,242 Q 500,250 492,258 L 15,500 Q 0,500 0,485 Z" fill="currentColor"/>,
	},
	{
		name: 'leaderboards',
		description: 'view the leaderboards',
		svg: <>
			<path d="M 0,253 L -4,258 L -4,379 L -4,500 L 59,500 L 122,500 L 122,379 L 122,258 L 118,253 L 114,249 L 59,249 L 4,249 L 0,253 Z" fill="currentColor"/>
			<path d="M 192,0 C 190,1 188,4 187,5 C 186,7 186,89 186,254 L 186,500 L 248,500 L 311,500 L 311,254 C 311,89 310,7 310,5 C 306,-2 304,-3 248,-3 C 204,-2 195,-2 192,0 Z" fill="currentColor"/>
			<path d="M 382,125 C 374,128 375,117 375,317 L 375,500 L 437,500 L 500,500 L 500,316 L 500,133 L 497,128 L 494,124 L 439,124 C 409,123 383,124 382,125 Z" fill="currentColor"/>
		</>,
	},
	{
		name: 'edit',
		description: 'open the level editor',
		svg: <>
			<path d="M 389,409 Q 389,400 395,395 Q 401,389 409,389 Q 417,389 422,395 Q 428,400 428,409 Q 428,416 422,422 Q 417,428 409,428 Q 401,428 395,422 Q 389,416 389,409 Z" fill="currentColor"/>
			<path d="M 193,282 L 401,488 Q 412,500 428,500 Q 444,500 456,488 L 488,455 Q 500,444 500,428 Q 500,412 488,400 L 281,194 Q 268,223 245,246 Q 223,269 193,282 Z" fill="currentColor"/>
			<path d="M 1,149 Q 1,161 7,182 Q 22,222 58,248 Q 94,273 136,273 Q 192,273 232,233 Q 272,193 272,137 Q 272,80 232,40 Q 192,0 136,0 Q 119,0 100,5 Q 80,10 67,19 Q 62,22 62,28 Q 62,33 67,37 L 156,88 L 156,156 L 97,189 Q 96,188 73,174 Q 51,160 32,149 Q 13,138 11,138 Q 6,138 4,141 Q 1,144 1,149 Z" fill="currentColor"/>
		</>,
	},
	{
		name: 'replay',
		description: 'load a replay file',
		svg: <>
			<path d="M 168,143 L 225,85 V 325 A 25,25 0 0 0 275,325 V 85 L 332,143 A 25,25 0 0 0 368,143 A 25,25 0 0 0 368,107 L 268,7 A 25,25 0 0 0 259,2 A 25,25 0 0 0 240,2 A 25,25 0 0 0 232,7 L 132,107 A 25,25 0 1 0 168,143 Z" fill="currentColor"/>
			<path d="M 475,250 A 25,25 0 0 0 450,275 V 425 A 25,25 0 0 1 425,450 H 75 A 25,25 0 0 1 50,425 V 275 A 25,25 0 0 0 0,275 V 425 A 75,75 0 0 0 75,500 H 425 A 75,75 0 0 0 500,425 V 275 A 25,25 0 0 0 475,250 Z" fill="currentColor"/>
		</>,
	},
	{
		name: 'settings',
		description: 'change your experience',
		svg: <path d="M 470,199 H 450 C 434,199 420,185 420,168 C 420,160 424,152 430,147 L 443,134 C 456,122 456,101 443,89 L 414,60 C 408,54 400,51 391,51 C 382,51 374,54 368,60 L 356,72 C 350,79 342,82 334,82 C 317,82 303,69 303,53 V 33 C 303,15 289,0 271,0 H 232 C 214,0 200,15 200,33 V 53 C 200,69 186,82 169,82 C 161,82 153,79 148,73 L 135,60 C 129,54 121,51 112,51 S 95,54 89,60 L 60,89 C 47,101 47,122 60,134 L 72,146 C 79,152 82,160 82,168 C 82,185 69,199 53,199 H 33 C 15,199 0,213 0,230 V 250 V 270 C 0,288 15,302 33,302 H 53 C 69,302 82,316 82,332 C 82,341 79,348 72,354 L 60,366 C 47,379 47,399 60,412 L 89,441 C 95,447 103,450 112,450 C 120,450 129,446 135,441 L 147,428 C 153,422 161,419 169,419 C 186,419 200,432 200,448 V 468 C 200,486 214,501 232,501 H 271 C 289,501 303,486 303,468 V 448 C 303,432 317,419 334,419 C 342,419 350,422 356,429 L 368,441 C 374,447 382,450 391,450 C 399,450 408,447 414,441 L 443,412 C 455,399 455,379 443,366 L 430,354 C 424,348 420,341 420,332 C 420,316 434,302 450,302 H 470 C 487,302 500,288 500,270 V 250 V 230 C 500,213 488,199 470,199 Z M 356,250 L 356,250 C 356,308 309,355 251,355 C 194,355 147,308 147,250 L 147,250 L 147,250 C 147,193 194,146 251,146 C 309,146 356,193 356,250 L 356,250 Z" fill="currentColor"/>,
	},
	{
		name: 'about',
		description: 'learn more',
		svg: <>
			<path d="M 200,100 A 50,50 0 1 1 300,100 A 50,50 0 1 1 200,100 Z" fill="currentColor"/>
			<path d="M 200,240 A 50,50 0 1 1 300,240 L 300,400 A 50,50 0 1 1 200,400 Z" fill="currentColor"/>
		</>,
	},
];

/**
 * The options the user can select while in a room.
 */
const roomOptions = [
	{
		name: 'lobby',
		description: 'show the lobby',
		svg: <path d="M 0,15 Q 0,0 15,0 L 492,242 Q 500,250 492,258 L 15,500 Q 0,500 0,485 Z" fill="currentColor"/>,
	},
];

/**
 * Represents the main menu.
 */
export default class Menu extends PureComponent {
	constructor(props) {
		super(props);
		this.state = {
			hide: true,
		};
	}

	/**
	 * The user clicked the toggle button on the menu.
	 */
	toggleMenu() {
		this.props.onToggleMenu();
	}

	/**
	 * The user clicked one of the options in the menu.
	 * @param {string} option The option that was clicked.
	 */
	onOptionClick(option) {
		this.setState({hide: true});
		this.props.onOptionClick(option);
	}

	render() {
		const opts = this.props.inRoom ? roomOptions : options;
		return (
			<div id="menu" className={this.props.hide ? 'hidden scrollable' : 'scrollable'}>
				<div id="menu-toggle" className={this.props.hide ? 'hidden' : ''} onClick={this.toggleMenu.bind(this)}>
					<img id="menu-toggle-icon" src={arrow} draggable="false" className={this.props.hide ? '' : 'flip'}/>
				</div>
				<img className="logo" src={logo} draggable="false" alt="logo"/>
				<div id="menu-options">
					{
						opts.map(option =>
							<div key={option.name} className="option" onClick={() => this.onOptionClick(option.name)}>
								<svg width="80" height="80" viewBox="0 0 500 500">{option.svg}</svg>
								<h2>{option.name}</h2>
								<p>{option.description}</p>
							</div>
						)
					}
				</div>
			</div>
		);
	}
}
