import {BitmapText, Container, Graphics, Texture} from 'pixi.js';
import {Emitter} from '@pixi/particle-emitter';

import particleUrl from '../../assets/sprites/particle.png';
import Movement from './movement.js';
import {Player} from './player.js';
import {dist, luma} from '../utils.js';

// TODO: this is stupid but we need it
import {BitmapFont} from 'pixi.js';
BitmapFont.from('Poppins', {
	fontFamily: 'Poppins',
	fontSize: 50,
	fill: 0xffffff,
}, {
	chars: [['a', 'z'], ['A', 'Z'], ['0', '9'], ' ', '\'', '+', '-', '_', '(', ')', ':', '.'],
});

/**
 * Returns true if the given point lies within the given rectangle.
 * @param {{x: number, y: number}} p The point to check.
 * @param {{x: number, y: number, w: number, h: number}} r The rectangle to check. The rectangle is assumed to be centered at (x, y).
 */
function pointInRect(p, r) {
	return p.x > r.x - r.w / 2
		&& p.x < r.x + r.w / 2
		&& p.y > r.y - r.h / 2
		&& p.y < r.y + r.h / 2;
}

/**
 * The JSON representation of a WallShape.
 * @typedef {{Rect: {x: number, y: number, w: number, h: number}} | {Circle: {x: number, y: number, r: number}}} RawWallShape
 */

/**
 * Represents the shape of a wall.
 * @class
 */
export class WallShape {
	/**
	 * @param {string} type The name of the shape. Can be 'rect' or 'circle'.
	 * @param {{x: number, y: number, w: number, h: number} | {x: number, y: number, r: number}} data The data for the shape.
	 */
	constructor(type, data) {
		this.type = type;
		this.data = data;
	}

	/**
	 * Returns the initial position (center) of the wall.
	 * @returns {{x: number, y: number}}
	 */
	get pos() {
		return {x: this.data.x, y: this.data.y};
	}

	/**
	 * Returns the appropriate collider shape for the wall.
	 * @returns {Rapier.ColliderDesc}
	 */
	collider() {
		let collider = null;
		switch (this.type) {
			case 'rect':
				collider = Rapier.ColliderDesc.cuboid(this.data.w / 2, this.data.h / 2);
				break;

			case 'circle':
				collider = Rapier.ColliderDesc.ball(this.data.r);
				break;
		}

		return collider
			.setFriction(0)
			.setCollisionGroups(0b01 << 16 | 0xffff); // membership | filter
	}

	/**
	 * Returns true if the given point lies within the wall. This is intended for use in the level editor.
	 * @param {{x: number, y: number}} p The point to check.
	 */
	containsPoint(p) {
		switch (this.type) {
			case 'rect':
				return pointInRect(p, this.data);

			case 'circle':
				return dist(p, this.data) < this.data.r;
		}
	}

	/**
	 * Returns the JSON representation of the WallShape.
	 */
	toJSON() {
		return {[this.type.charAt(0).toUpperCase() + this.type.substring(1)]: this.data};
	}

	/**
	 * Parses the given JSON into a WallShape.
	 * @param {RawWallShape} data
	 */
	static parse(data) {
		if (data.Rect) {
			return new WallShape('rect', data.Rect);
		} else if (data.Circle) {
			return new WallShape('circle', data.Circle);
		}
	}
}

/**
 * Represents a wall in a level.
 * @class
 */
export class Wall {
	/**
	 * @param {WallShape} s The shape of the wall.
	 * @param {Movement} m The optional movement of the wall.
	 */
	constructor(s, m = null) {
		this.s = s;
		this.m = m;
	}

	/**
	 * Convert the wall's shape to another type with a reasonable resulting position. This is intended for use in the level editor.
	 * @param {string} type The new type. Can be 'rect' or 'circle'.
	 */
	convertShape(type) {
		if (this.s.type === type) return this;

		switch (type) {
			case 'rect':
				this.s = new WallShape('rect', {
					x: this.s.data.x + this.s.data.r,
					y: this.s.data.y + this.s.data.r,
					w: this.s.data.r * 2,
					h: this.s.data.r * 2,
				});
				break;

			case 'circle':
				this.s = new WallShape('circle', {
					x: this.s.data.x - this.s.data.w / 2,
					y: this.s.data.y - this.s.data.h / 2,
					r: Math.max(this.s.data.w, this.s.data.h) / 2,
				});
				break;
		}
	}

	/**
	 * Returns true if the given point lies within the wall. This is intended for use in the level editor.
	 * @param {{x: number, y: number}} p The point to check.
	 */
	containsPoint(p) {
		return this.s.containsPoint(p);
	}

	/**
	 * Get the computed position of the wall. If the wall's movement property is set, the current physics tick must be passed to the function.
	 * @param {number} tick The current physics tick, required if the wall has a movement property.
	 */
	getComputedPosition(tick) {
		const pos = this.s.pos;
		if (this.m && tick !== undefined) {
			return this.m.computePosition(pos, tick);
		} else {
			return pos;
		}
	}

	/**
	 * Draws the wall to the given graphics object. If the wall's movement property is set, the current physics tick must be passed to the function.
	 * @param {Graphics} g The graphics object to draw to.
	 * @param {number} tick The current physics tick, required if the wall has a movement property.
	 * @param {number} opacity The opacity to draw the wall with.
	 */
	draw(g, tick, opacity = 0.78) {
		const pos = this.getComputedPosition(tick);
		g.beginFill(0, opacity)
			.lineStyle(1, 0, opacity / 2);

		const d = this.s.data;
		switch (this.s.type) {
			case 'rect':
				g.drawRect(pos.x - d.w / 2, pos.y - d.h / 2, d.w, d.h);
				break;

			case 'circle':
				g.drawCircle(pos.x, pos.y, d.r);
				break;
		}

		g._lineStyle.reset(); // TODO: better way?
	}

	/**
	 * Returns the JSON representation of the Wall.
	 */
	toJSON() {
		return {s: this.s.toJSON(), m: this.m?.toJSON()};
	}

	/**
	 * Parses the given JSON into a Wall.
	 * @param {RawWallShape & {m: *?}} data
	 */
	static parse(data) {
		const s = WallShape.parse(data.s);
		const m = data.m ? Movement.parse(data.m) : null;
		return new Wall(s, m);
	}
}

/**
 * Represents a portal in a level.
 */
export class Portal {
	/**
	 * All coordinate values should be in multiples of 10.
	 * @param {number} color The color of the portal.
	 * @param {number} x The x-coordinate of the center of the portal.
	 * @param {number} y The x-coordinate of the center of the portal.
	 * @param {number} w The width of the portal.
	 * @param {number} h The height of the portal.
	 */
	constructor(color, x, y, w, h) {
		this.color = color;
		this.x = x;
		this.y = y;
		this.w = w;
		this.h = h;

		/**
		 * Particle emitter for the portal. It will be initialized when the portal is drawn for the first time, since this is when the Portal has access to the rendering container.
		 * @type {Emitter}
		 */
		this.emitter = null;
	}

	/**
	 * Returns true if the given point lies within the portal. This is intended for use in the level editor.
	 * @param {{x: number, y: number}} p The point to check.
	 */
	containsPoint(p) {
		return pointInRect(p, this);
	}

	/**
	 * Initialize the portal's particle emitter. Does nothing if the emitter has already been initialized.
	 * @param {Container} container The container to draw to.
	 */
	initEmitter(container) {
		if (this.emitter) return;
		this.emitter = new Emitter(
			container,
			{
				addAtBack: true,
				autoUpdate: true,
				frequency: 0.18,
				lifetime: {min: 8, max: 10},
				maxParticles: 50,
				pos: {x: this.x - this.w / 2, y: this.y - this.h / 2},
				behaviors: [
					{
						type: 'alpha',
						config: {
							alpha: {
								list: [{value: 1, time: 0}, {value: 0, time: 1}],
							},
						},
					},
					{
						type: 'scale',
						config: {
							scale: {
								list: [{value: 0.15, time: 0}, {value: 0.05, time: 1}],
							},
						},
					},
					{
						type: 'color',
						config: {
							color: {
								list: [
									{value: `${this.color.toString(16).padStart(6, '0')}`, time: 0},
									{value: `#ffffff`, time: 1},
								],
							},
						},
					},
					{
						type: 'moveSpeed',
						config: {
							speed: {
								list: [{value: 10, time: 0}, {value: 5, time: 1}],
							},
						},
					},
					{
						type: 'rotationStatic',
						config: {min: 0, max: 360},
					},
					{
						type: 'noRotation',
						config: {},
					},
					{
						type: 'spawnShape',
						config: {
							type: 'rect',
							data: {x: 0, y: 0, w: this.w, h: this.h},
						},
					},
					{
						type: 'textureSingle',
						config: {
							texture: Texture.from(particleUrl),
						},
					},
				],
			},
		);
	}

	/**
	 * Stops the portal's particle emitter.
	 */
	stopEmitter() {
		this.emitter.destroy();
		this.emitter = null;
	}

	/**
	 * Draws the portal to the given graphics object. None of its visual effects are drawn.
	 * @param {Graphics} g The graphics object to draw to.
	 */
	draw(g) {
		g.beginFill(this.color, 1)
			.lineStyle(1, 0)
			.drawRect(this.x - this.w / 2, this.y - this.h / 2, this.w, this.h)
			.endFill()
			._lineStyle.reset(); // TODO: better way?
	}

	/**
	 * Returns the JSON representation of the portal.
	 */
	toJSON() {
		return {
			color: this.color,
			x: this.x,
			y: this.y,
			w: this.w,
			h: this.h,
		};
	}
}

/**
 * Represents a finish block in a level.
 * @class
 */
export class Finish {
	/**
	 * All values should be in multiples of 10.
	 * @param {number} x The x-coordinate of the center of the finish.
	 * @param {number} y The x-coordinate of the center of the finish.
	 * @param {number} w The width of the block.
	 * @param {number} h The height of the block.
	 */
	constructor(x, y, w, h) {
		this.x = x;
		this.y = y;
		this.w = w;
		this.h = h;

		/**
		 * A PIXI text object to display the finish's index when in the editor.
		 */
		this.text = new BitmapText('', {fontName: 'Poppins', fontSize: 20});
		this.text.anchor.set(0.5, 0.5);
		this.text.align = 'center';
	}

	/**
	 * Returns true if the given point lies within the finish block. This is intended for use in the level editor.
	 * @param {{x: number, y: number}} p The point to check.
	 */
	containsPoint(p) {
		return pointInRect(p, this);
	}

	/**
	 * Draws the finish block to the given graphics object.
	 * @param {Graphics} g The graphics object to draw to.
	 * @param {number} color
	 * @param {number} opacity
	 */
	draw(g, color = 0xa500, opacity = 1) {
		g.beginFill(color, opacity)
			.lineStyle(1, 0)
			.drawRect(this.x - this.w / 2, this.y - this.h / 2, this.w, this.h)
			.endFill()
			._lineStyle.reset(); // TODO: better way?
	}

	/**
	 * Draws the finish block with the color of the specified player to the given graphics object.
	 * @param {Graphics} g The graphics object to draw to.
	 * @param {Player} player The player to use.
	 * @param {number} opacity
	 */
	drawAsPlayer(g, player, opacity = 1) {
		this.draw(g, player.color, opacity);
	}

	/**
	 * Draws the finish block to the given graphics object. This is intended for use in the level editor.
	 * @param {Graphics} g The graphics object to draw to.
	 * @param {Graphics} gs The shadowed graphics object to draw to. This is used for the text.
	 * @param {number} index The index of the finish block.
	 * @param {boolean} selected Whether the block has been selected. If this is false, the opacity of the block is lowered.
	 */
	drawInEditor(g, gs, index, selected = true) {
		const opacity = selected ? 1 : 0.24;

		this.draw(g, 0xa500, opacity);

		this.text.alpha = opacity;
		this.text.text = index;
		this.text.x = this.x;
		this.text.y = this.y;
		gs.addChild(this.text);
	}


	/**
	 * Returns the JSON representation of the Finish.
	 */
	toJSON() {
		return {x: this.x, y: this.y, w: this.w, h: this.h};
	}
}

/**
 * Represents a location the player can spawn at during the level.
 * @class
 */
export class Spawn {
	/**
	 * @param {number} x The x-coordinate of the center of the spawn.
	 * @param {number} y The y-coordinate of the center of the spawn.
	 * @param {number} t The time delay until the spawn is "activated", that is, the player can begin moving.
	 */
	constructor(x, y, t) {
		this.x = x;
		this.y = y;
		this.t = t;

		/**
		 * A PIXI text object to display the spawn delay when in the editor.
		 */
		this.text = new BitmapText('', {fontName: 'Poppins', fontSize: 20});
		this.text.anchor.set(0.5, 0.5);
		this.text.align = 'center';
	}

	/**
	 * Returns true if the given point lies within the spawn. This is intended for use in the level editor.
	 * @param {{x: number, y: number}} p The point to check.
	 */
	containsPoint(p) {
		return dist(p, this) < 15;
	}

	/**
	 * Draws the spawn with the color of the specified player to the given graphics object. This is intended for use in the level editor.
	 * @param {Graphics} g The graphics object to draw to.
	 * @param {Graphics} gs The shadowed graphics object to draw to. This is used for the text.
	 * @param {Player} player The player to use.
	 * @param {number} index The index of the spawn.
	 * @param {boolean} selected Whether the spawn has been selected. If this is false, the opacity of the spawn is lowered.
	 */
	drawInEditor(g, gs, player, index, selected = true) {
		const opacity = selected ? 1 : 0.2;

		g.beginFill(player.color, opacity)
			.lineStyle(1, 0)
			.drawCircle(this.x, this.y, 15)
			.endFill()
			._lineStyle.reset(); // TODO: better way?

		const brightLocalColor = luma(player.color) > 80;
		this.text.alpha = opacity;
		this.text.tint = brightLocalColor ? 0 : 0xffffff;
		this.text.text = `${index}\n${this.t}`;
		this.text.x = this.x;
		this.text.y = this.y;
		gs.addChild(this.text);
	}

	/**
	 * Returns the JSON representation of the Spawn.
	 */
	toJSON() {
		return {x: this.x, y: this.y, t: this.t};
	}
}

/**
 * Convert an entity's level editor ID to the entity's name.
 * @param {number} id The level editor ID.
 */
export function idToName(id) {
	return ['wall', 'portal', 'finish', 'spawn'][id];
}

/**
 * Convert an entity to its level editor ID.
 * @param {Wall | Portal | Finish | Spawn} entity The entity to convert.
 */
export function entityToId(entity) {
	if (entity instanceof Wall) return 0;
	if (entity instanceof Portal) return 1;
	if (entity instanceof Finish) return 2;
	if (entity instanceof Spawn) return 3;
	return -1;
}
