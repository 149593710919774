import {bytesToUUID} from '../utils.js';

/**
 * Represents a concrete data type that can be received in a binary message from the server. A concrete data type in this context has a fixed size, and usually represents primitive types like numbers.
 * @class
 */
export class Type {
	/**
	 * @param {number} bytes
	 * @param {function(ArrayBuffer) => *} parse
	 */
	constructor(bytes, parse) {
		/**
		 * The number of bytes this type takes up in a binary message.
		 */
		this.bytes = bytes;

		/**
		 * A function that parses this type from binary data. The function takes a single ArrayBuffer, that has the number of bytes specified by the type, sliced from the original message, and returns the parsed data.
		 */
		this.parse = parse;
	}
}

/**
 * Represents an abstract data type that can be received in a binary message from the server, where the exact generic arguments **are unknown**. An abstract data type in this context has a variable size, and usually represents a collection of other types, like a map.
 *
 * Since the size of an abstract data type is variable, it is not possible to know how many bytes to slice from the original message. Instead, the parsing function simply provides an ArrayBuffer that has been sliced from the start of where to read from, up to the end of the message.
 * @class
 */
export class AbstractType {
	/**
	 * @param {number} numGenerics
	 * @param {function(ArrayBuffer, Array<Type | AbstractType>) => {bytes: number, value: *}} parse
	 */
	constructor(numGenerics, parse) {
		/**
		 * The number of generic arguments this type takes.
		 */
		this.numGenerics = numGenerics;

		/**
		 * A function that parses this type from binary data. It accepts the message as an ArrayBuffer, which is a slice of the original message, and an array of data types used by the type. The function **must** return an object with the following properties:
		 *
		 * - `bytes`: The number of bytes the resulting value takes up in the message.
		 * - `value`: The resulting value.
		 */
		this.parse = parse;
	}
}

/**
 * Represents an abstract data type that can be received in a binary message from the server, where the exact generic arguments **are known**. This is useful inside of compound types, where the layout of the compound type is always the same.
 * @class
 */
export class KnownAbstractType {
	/**
	 * @param {AbstractType} type
	 * @param {Array<Type | KnownAbstractType>} generics
	 */
	constructor(type, generics) {
		/**
		 * The underlying abstract data type whose generic arguments are known.
		 */
		this.type = type;

		/**
		 * An array of data types used by the underlying abstract data type.
		 */
		this.generics = generics;
	}

	/**
	 * Parses this type from binary data. Returns the number of bytes the resulting value takes up in the message, and the resulting value.
	 * @param {ArrayBuffer} buffer
	 */
	parse(buffer) {
		return this.type.parse(buffer, this.generics);
	}
}

/**
 * Represents a compound data type that can be received in a binary message from the server. A compound data type in this context has a variable size, and has no generic arguments. However, its layout can always be described by the same types.
 * @class
 */
export class CompoundType {
	/**
	 * @param {Array<Type | KnownAbstractType>} types
	 * @param {function(Array<*>) => *} map
	 */
	constructor(types, map) {
		/**
		 * An array of types in the order they appear in the message.
		 */
		this.types = types;

		/**
		 * A function that maps the values of the types in the order they appear in the message to a value.
		 */
		this.map = map;
	}

	/**
	 * Parses this type from binary data. Returns the number of bytes the resulting value takes up in the message, and the resulting value.
	 * @param {ArrayBuffer} buffer
	 */
	parse(buffer) {
		const values = [];
		let bytes = 0;

		for (const type of this.types) {
			const {bytes: typeBytes, value} = parse(buffer.slice(bytes), type);
			bytes += typeBytes;
			values.push(value);
		}

		return {
			bytes,
			value: this.map(values),
		};
	}
}

/**
 * Type definitions for concrete data types received from the server.
 */
export const concrete = {
	bool: new Type(1, buffer => !!new DataView(buffer).getUint8(0)),
	u8: new Type(1, buffer => new DataView(buffer).getUint8(0)),
	u16: new Type(2, buffer => new DataView(buffer).getUint16(0)),
	u32: new Type(4, buffer => new DataView(buffer).getUint32(0)),
	i32: new Type(4, buffer => new DataView(buffer).getInt32(0)),
	f32: new Type(4, buffer => new DataView(buffer).getFloat32(0)),
	f64: new Type(8, buffer => new DataView(buffer).getFloat64(0)),
	Uuid: new Type(16, buffer => bytesToUUID(new Uint8Array(buffer.slice(0, 16)))),
	Vector: new Type(8, buffer => {
		const view = new DataView(buffer);
		return {
			x: view.getFloat32(0),
			y: view.getFloat32(4),
		};
	}),
	Keys: new Type(1, buffer => new DataView(buffer).getUint8(0)),
};

/**
 * Utility function to parse any data type from the start of a buffer.
 * @param {ArrayBuffer} buffer The buffer to parse from.
 * @param {Type | AbstractType | KnownAbstractType | CompoundType} type The type to parse.
 * @param {Array<Type | AbstractType>} generics If the type is an abstract type, the types of the generic arguments.
 */
export function parse(buffer, type, generics) {
	if (type instanceof Type) {
		return {
			bytes: type.bytes,
			value: type.parse(buffer),
		};
	} else {
		return type.parse(buffer, generics);
	}
}



// ABSTRACT TYPES

/**
 * Type definitions for abstract data types received from the server.
 */
export const abstract = {
	/**
	 * Represents a single variant of a Rust enumeration.
	 *
	 * This type is special, in that its generic argument is actually an array of types, where the variant index in the buffer describes which type to actually parse.
	 */
	Enum: new AbstractType(1, (buffer, types) => {
		const view = new DataView(buffer);
		const variantIndex = view.getUint8(0);

		// Enum is special in that it has a variant index, which identifies the format of the data we must parse next
		// this works by passing multiple "generics" to the first argument of the `types` array
		const {bytes, value} = parse(buffer.slice(1), types[0][variantIndex]);
		return {
			bytes: 1 + bytes,
			value,
		};
	}),
	Map: new AbstractType(2, (buffer, types) => {
		const view = new DataView(buffer);
		const map = new Map();

		// the first byte is the number of entries in the map
		const numEntries = view.getUint8(0);
		let current = 1;

		for (let i = 0; i < numEntries; ++i) {
			// parse the key
			const {value: key, bytes: keyBytes} = parse(buffer.slice(current), types[0]);
			current += keyBytes;

			// parse the value
			const {value, bytes: valueBytes} = parse(buffer.slice(current), types[1]);
			current += valueBytes;

			// add the pair to the map
			map.set(key, value);
		}

		return {
			bytes: current,
			value: map,
		};
	}),
	Array: new AbstractType(1, (buffer, types) => {
		const view = new DataView(buffer);
		const array = [];

		// the first two bytes are the number of values in the array
		const numValues = view.getUint16(0);
		let current = 2;

		for (let i = 0; i < numValues; ++i) {
			// parse the value
			const {value, bytes} = parse(buffer.slice(current), types[0]);
			current += bytes;

			// add the value to the array
			array.push(value);
		}

		return {
			bytes: current,
			value: array,
		};
	}),
	Option: new AbstractType(1, (buffer, types) => {
		const view = new DataView(buffer);
		const exists = !!view.getUint8(0);

		if (exists) {
			const {value, bytes} = parse(buffer.slice(1), types[0]);
			return {
				bytes: 1 + bytes,
				value,
			};
		} else {
			return {
				bytes: 1,
				value: null,
			};
		}
	}),
};



// COMPOUND TYPES

/**
 * A variably-sized String with valid UTF-8 characters.
 */
const String = new CompoundType([
	new KnownAbstractType(abstract.Array, [concrete.u8]),
], ([bytes]) => window.String.fromCharCode(...bytes));

/**
 * Describes an identifier for a level.
 */
const LevelId = [
	new CompoundType([concrete.u32], ([id]) => ({Official: id})),
	new CompoundType([concrete.Uuid], ([uuid]) => ({User: uuid})),
];

/**
 * Describes a function that calculates the x or y position of an entity during a game.
 */
const MArgs = [
	new CompoundType([concrete.f32], args => ({Lin: args})),
	new CompoundType([
		concrete.f32,
		concrete.f32,
		concrete.f32,
	], args => ({Sin: args})),
	new CompoundType([
		concrete.f32,
		concrete.f32,
		concrete.f32,
	], args => ({Cos: args})),
];

/**
 * Describes the movement of an entity during a game.
 */
const Movement = new CompoundType([
	new KnownAbstractType(abstract.Enum, [MArgs]),
	concrete.u16,
	new KnownAbstractType(abstract.Enum, [MArgs]),
	concrete.u16,
], ([x, xEase, y, yEase]) => ({
	x: {
		def: x,
		ease: xEase,
	},
	y: {
		def: y,
		ease: yEase,
	},
}));

/**
 * Represents the shape of a wall.
 */
const WallShape = [
	new CompoundType([
		concrete.f32,
		concrete.f32,
		concrete.f32,
		concrete.f32,
	], ([x, y, w, h]) => ({Rect: {x, y, w, h}})),
	new CompoundType([
		concrete.f32,
		concrete.f32,
		concrete.f32,
	], ([x, y, r]) => ({Circle: {x, y, r}})),
];

/**
 * A wall in a level.
 */
const Wall = new CompoundType([
	new KnownAbstractType(abstract.Enum, [WallShape]),
	new KnownAbstractType(abstract.Option, [Movement]),
], ([s, m]) => ({s, m}));

/**
 * A portal in a level.
 */
const Portal = new CompoundType([
	concrete.u32,
	concrete.f32,
	concrete.f32,
	concrete.f32,
	concrete.f32,
], ([color, x, y, w, h]) => ({color, x, y, w, h}));

/**
 * A finish in a level.
 */
const Finish = new CompoundType([
	concrete.f32,
	concrete.f32,
	concrete.f32,
	concrete.f32,
], ([x, y, w, h]) => ({x, y, w, h}));

/**
 * A spawn in a level.
 */
const Spawn = new CompoundType([
	concrete.f32,
	concrete.f32,
	concrete.u16,
], ([x, y, t]) => ({x, y, t}));

/**
 * Represents a path of a player took in a round.
 */
const RawPath = new CompoundType([
	concrete.u16,
	new KnownAbstractType(abstract.Array, [concrete.Keys]),
	new KnownAbstractType(abstract.Array, [concrete.Vector]),
	new KnownAbstractType(abstract.Option, [concrete.u16]),
],
	([snapshotInterval, inputs, snapshots, slowDownTick]) => ({snapshotInterval, inputs, snapshots, slowDownTick}));

/**
 * Represents a path a player took during a round.
 */
const Path = new CompoundType([
	concrete.Uuid,
	RawPath,
],
	([uuid, raw]) => ({uuid, raw}));

/**
 * Type definitions for compound data types used by different game modes.
 */
export const gameModeCompound = {
	classic: {
		UserInit: new CompoundType([
			concrete.u8,
			concrete.i32,
		], ([spawnIndex, score]) => ({spawnIndex, score})),
		OldUserState: new CompoundType([
			concrete.u8,
			RawPath,
			concrete.bool,
		], ([spawnIndex, path, passed]) => ({spawnIndex, path, passed})),
		NewUserState: new CompoundType([
			new KnownAbstractType(abstract.Option, [concrete.u8]),
			concrete.i32,
		], ([spawnIndex, score]) => ({spawnIndex, score})),
		Complete: new CompoundType([
			concrete.u8,
			RawPath,
			concrete.i32,
			concrete.f64,
		], ([spawnIndex, path, score, timeSpent]) => ({spawnIndex, path, score, timeSpent})),
	},
};

/**
 * Type definitions for compound data types received from the server.
 */
export const compound = {
	String,
	Path,
	State: new CompoundType([
		concrete.bool,
		concrete.u8,
		concrete.Vector,
		concrete.Vector,
	], ([teleported, boost, pos, vel]) => ({teleported, boost, pos, vel})),
	Level: new CompoundType([
		// level metadata, maps to Level struct
		new KnownAbstractType(abstract.Enum, [LevelId]), // id
		String, // title
		String, // author
		concrete.u32, // width
		concrete.u32, // height
		concrete.u8, // difficulty
		String, // players
		concrete.u32, // bronze medal score goal
		concrete.u32, // silver medal score goal
		concrete.u32, // gold medal score goal
		concrete.u32, // platinum medal score goal
		concrete.u8, // time limit
		new KnownAbstractType(abstract.Array, [concrete.u8]), // modes

		new KnownAbstractType(abstract.Array, [Wall]), // walls
		new KnownAbstractType(abstract.Array, [Portal]), // portals
		new KnownAbstractType(abstract.Array, [Finish]), // finishes
		new KnownAbstractType(abstract.Array, [Spawn]), // spawns
	], ([id, title, author, width, height, difficulty, players, bronze, silver, gold, platinum, timeLimit, modes, walls, portals, finishes, spawns]) => ({
		info: {
			id,
			title,
			author,
			width,
			height,
			difficulty,
			players,
			scoreGoals: [bronze, silver, gold, platinum],
			timeLimit,
			modes,
		},
		walls,
		portals,
		finishes,
		spawns,
	})),
	Replay: new CompoundType([
		concrete.u8,
		new KnownAbstractType(abstract.Enum, [LevelId]),
		new KnownAbstractType(abstract.Array, [Path]),
	],
		([version, id, paths]) => ({version, id, paths})),
};
