import {PureComponent} from 'react';

import {api} from '../../scripts/net/api.js';
import Button from '../base/button.js';
import {gameModeNames} from '../../scripts/game/game_mode/main.js';
import {gameModeSvgs, wrapGameModeSvg} from '../utils.js';
import {getLevels} from '../../scripts/net/utils.js';

const levels = await getLevels();

/**
 * Represents the screen that allows room hosts to choose the level for their room.
 */
export default class MultiLevelSelect extends PureComponent {
	constructor(props) {
		super(props);
		this.state = {
			/**
			 * Whether to disable all level options and buttons.
			 */
			disabled: false,

			/**
			 * The ID of the selected game mode.
			 */
			gameMode: 0,
		};
	}

	/**
	 * The user clicked one of the game mode options.
	 * @param {number} gameMode The ID of the game mode that was selected.
	 */
	onGameModeSelect(gameMode) {
		this.setState({gameMode});
	}

	/**
	 * The user clicked one of the levels.
	 * @param {number} level The ID of the level that was selected.
	 * @param {number} gameMode The ID of the game mode that was selected.
	 */
	async onLevelClick(level, gameMode) {
		this.setState({disabled: true});

		await api().auth.rooms.me.modify.post({
			gameMode,
			mapPool: 'official',
			map: level + '',
		});

		this.setState({disabled: false});
		this.props.onClose();
	}

	render() {
		return (
			<div id="level-select" className={`${this.props.hide ? 'hidden ' : ''}scrollable flex-column window`}>
				<div id="level-select-init" className="modal">
					<h1>level select</h1>
					<h2 style={{margin: '0.4em 0'}}>choose game mode:</h2>
					<div id="game-mode-select">
						{
							[...Array(gameModeSvgs.length).keys()].map(i =>
								<div className={this.state.gameMode === i ? 'game-mode-option selected' : 'game-mode-option'} key={i} onClick={() => this.onGameModeSelect(i)}>
									{wrapGameModeSvg(i)}
								</div>
							)
						}
					</div>
					<p>{gameModeNames[this.state.gameMode]}</p>
				</div>
				<div className="levels-grid">
					{
						levels.map((l, i) => {
							const hasSelectedMode = l.info.modes.includes(this.state.gameMode);
							const canClick = hasSelectedMode && !this.state.disabled;
							return <div
								className={canClick ? 'grid-level modal' : 'grid-level modal disabled'}
								key={i}
								onClick={canClick ? () => this.onLevelClick(i, this.state.gameMode) : null}
							>
								{l.generateSVG(200)}
								<div className="flex-column level-select-level-info">
									<h2>{l.info.title}</h2>
									<h3>by {l.info.author}</h3>
									<p>
										recommended players: {l.info.players}
										<br/>
										difficulty: {l.generateDifficultyString()}
									</p>
									<div>
										{
											l.info.modes.map(i => {
												const svg = gameModeSvgs[i];
												return <svg key={i} style={i > 0 ? {margin: '0 0 0 0.8em'} : {}} width="60" height="60" viewBox="0 0 60 60" fill="none">
													<circle cx="30" cy="30" r="28" stroke="currentColor" strokeWidth="4"/>
													{svg}
												</svg>;
											})
										}
									</div>
								</div>
							</div>
						})
					}
				</div>
				<div className="button-row" style={{bottom: '1em'}}>
					<Button disabled={this.state.disabled} onClick={this.props.onClose.bind(this)} value="close"/>
				</div>
			</div>
		);
	}
}
