import {BitmapFont, Text, TextStyle} from 'pixi.js';
import {green, red} from './global.js';

document.fonts.ready.then(() => {
	// it just defaults to sad times new roman if it's not loaded
	BitmapFont.from('Poppins', {
		fontFamily: 'Poppins',
		fontSize: 50,
		fill: 0xffffff,
	}, {
		chars: [['a', 'z'], ['A', 'Z'], ['0', '9'], ' ', '\'', '+', '-', '_', '(', ')', ':', '.'],
	});
});

/**
 * The base text style that all PIXI.Text objects should imitate.
 * @type {TextStyle}
 */
export const baseStyle = new TextStyle({
	dropShadow: true,
	dropShadowAngle: Math.PI / 4,
	dropShadowBlur: 2,
	dropShadowDistance: 2,
	fill: 0xffffff,
	fontFamily: 'Poppins',
	fontSize: 20,
});

/**
 * The base text style that all PIXI.BitmapText objects should imitate.
 */
export const baseBitmapStyle = {
	fontName: 'Poppins',
	fontSize: 30,
	tint: 0xffffff,
};

// info on text and other related values
export const textInfo = {
	outdatedClient: 0, // y location of the outdated client warning text

	previousReplayBar: window.innerHeight + 100, // seek bar that allows players to navigate the past replay

	levelCompleteFlash: 255, // opacity of the flash that shows when a level is completed
};

/**
 * Create a text style that derives from the base style.
 */
function createStyle(func) {
	const style = baseStyle.clone();
	if (typeof func === 'function') func(style);
	return style;
}

/**
 * Text styles that derive from `base`.
 */
const textStyles = {
	/**
	 * Style for showing dimensions of an entity in the level editor.
	 */
	dimen: createStyle(s => {
		s.dropShadow = false;
		s.fontSize = 20;
	}),
};

/**
 * Create a text object with a clone of an optional style. If the style is not provided, the style is a clone of the base text style.
 */
export function createText(func, style) {
	const text = new Text('', (style || baseStyle).clone());
	if (typeof func === 'function') func(text);
	return text;
}

/**
 * PIXI text objects.
 */
export const text = {
	/**
	 * FPS and ping display.
	 */
	stats: createText(t => t.x = 5),

	/**
	 * Text that appears if player tries to ready up and the room accepts the player's request, but the round is not ready to begin.
	 */
	success: createText(t => {
		t.alpha = 0;
		t.anchor.set(0, 0.5);
		t.style.fill = green;
	}, textStyles.guide),

	/**
	 * Text that appears if player tries to ready up, but the room refuses the player's request.
	 */
	error: createText(t => {
		t.alpha = 0;
		t.anchor.set(0, 0.5);
		t.style.fill = red;
	}, textStyles.guide),

	/**
	 * Text that displays the level name and author in the initial overview animation.
	 */
	levelInfo: {
		name: createText(t => {
			t.x = window.innerWidth + 1000;
			t.anchor.set(1, 0.5);
			t.style.fontSize = 140;
		}),

		author: createText(t => {
			t.x = window.innerWidth + 1000;
			t.anchor.set(1, 0.5);
			t.style.fontSize = 70;
		}),
	},

	/**
	 * "Spectate" text that appears when the player is spectating.
	 */
	spectate: createText(t => {
		t.text = 'you are spectating';
		t.anchor.set(0, 0.5);
		t.style.fontSize = 50;
	}, textStyles.guide),

	/**
	 * "move camera with movement keys" text that appears when the player is spectating.
	 */
	spectateGuide: createText(t => {
		t.text = 'move camera with movement keys';
		t.anchor.set(0, 0.5);
		t.style.fontSize = 40;
	}, textStyles.guide),

	/**
	 * Text that appears if the user is playtesting a level.
	 */
	playtest: createText(t => {
		t.text = 'playtesting\npress T return to the editor';
		t.anchor.set(1, 0.5);
		t.style.align = 'right';
		t.style.fill = 0xffff00;
	}, textStyles.guide),

	/**
	 * Text objects that show the dimensions of an entity being added in the level editor.
	 */
	dimen: {
		top: createText(t => t.anchor.set(0.5, 1), textStyles.dimen),
		bottom: createText(t => t.anchor.set(0.5, 0), textStyles.dimen),

		left: createText(t => t.anchor.set(1.2, 0.5), textStyles.dimen),
		right: createText(t => t.anchor.set(-0.2, 0.5), textStyles.dimen),

		center: createText(t => t.anchor.set(0.5, 0.5), textStyles.dimen),
	},

	/**
	 * Text objects that show the keybindings to press to select different entities in the level editor.
	 */
	entityKey: [
		createText(t => {
			t.text = '1';
			t.anchor.set(0.5, 0.5);
		}, textStyles.dimen),
		createText(t => {
			t.text = '2';
			t.anchor.set(0.5, 0.5);
		}, textStyles.dimen),
		createText(t => {
			t.text = '3';
			t.anchor.set(0.5, 0.5);
		}, textStyles.dimen),
		createText(t => {
			t.text = '4';
			t.anchor.set(0.5, 0.5);
		}, textStyles.dimen),
	],

	/**
	 * Text that shows the selected spawn index in the level editor.
	 */
	selected: createText(t => t.anchor.set(0.5, 0.5), textStyles.dimen),
};
