import {Component} from 'react';

import {api, setAuth} from '../scripts/net/api.js';
import Button from './base/button.js';
import {initConnection} from '../scripts/net/ws.js';

/**
 * Handles the display of the login UI.
 */
export default class Login extends Component {
	constructor(props) {
		super(props);
		this.state = {
			/**
			 * Whether or not to hide the component.
			 */
			hide: true,

			/**
			 * Whether the sign in form is currently being submitted.
			 */
			submitting: false,

			/**
			 * Whether the user is logging in, or creating a new account.
			 */
			signIn: true,

			/**
			 * The username the user signed in with.
			 */
			username: '',

			/**
			 * Whether the user's HTTP request timed out.
			 */
			timeOut: false,

			/**
			 * The HTTP error code returned by the server, if any.
			 * @type {number?}
			 */
			errorCode: null,

			/**
			 * The error message returned by the server, if any.
			 * @type {string?}
			 */
			errorMessage: null,
		};
	}

	componentDidMount() {
		const cb = () => this.setState({hide: false});
		if (document.readyState === 'complete') { // on phone, the load event is fired before render is called, so the event listener below will not trigger
			cb();
		} else {
			window.addEventListener('load', cb);
		}
	}

	shouldComponentUpdate() {
		// only continue updating if the user has not successfully logged in / registered
		return !this.props.succeeded;
	}

	/**
	 * The user clicked on the submit button.
	 * @param {boolean} [guest=false] Whether the user is signing in as a guest (default, false).
	 */
		async onSubmit(guest = false) {
		const form = document.getElementById('sign-in-form');
		this.setState({username: form.elements.namedItem('username').value, submitting: true, usernameTaken: false, timeOut: false});

		// guest === true -> user is signing in as a guest
		// state.signIn === true -> user logging in
		// state.signIn === false -> user creating a new account
		try {
			// login
			const response = await (guest ? api().guest.postForm
				: this.state.signIn ? api().login.postForm
				: api().register.postForm)(form);
			setAuth(response.uuid);

			// establish websocket connection
			await initConnection(response);
			this.props.onSignInSuccess(this.state.signIn, this.state.username);
		} catch (err) {
			console.error(err);
			if (err instanceof Response) {
				// format error message in the style of the game
				// lowercase, no period at the end
				const errorMessage = (await err.text()).toLowerCase().replace(/\.$/, '');
				this.setState({submitting: false, errorCode: err.status, errorMessage});
			} else {
				this.setState({submitting: false, timeOut: true});
			}
		}
	}

	/**
	 * The user clicked the "no account" / "already have an account" button.
	 */
	onNewAccount() {
		this.setState({signIn: !this.state.signIn});
	}

	/**
	 * The user focused on an input field.
	 */
	onFocusField(field) {
		this.setState({timeOut: false, invalidLogin: false});

		switch (field) {
			case 'username':
				this.setState({usernameTaken: false});
				break;
		}
	}

	render() {
		return (
			<div className={`${this.state.hide || this.props.succeeded ? 'hidden ' : ''}dialog`}>
				<h1>welcome!</h1>
				<p>{this.state.signIn ? 'please sign in to continue:' : 'enter your account details and select a player color below:'}</p>
				<form id="sign-in-form" className="flex-column">
					{
						this.state.timeOut ?
							<p className="error">request timeout, please try again in a moment</p> :
							null
					}
					{
						// unauthorized
						this.state.errorCode === 401 ?
							<p className="error">the username or password was incorrect</p> :
							null
					}
					<input type="text" id="sign-in-username" className={this.state.errorCode === 400 || this.state.errorCode === 409 ? 'error' : ''} name="username" maxLength={512} autoComplete="off" placeholder="username" onFocus={() => this.onFocusField('username')} required/>
					{
						// bad request or conflict
						this.state.errorCode === 400 || this.state.errorCode === 409
							? <p className="error">{this.state.errorMessage}</p>
							: null
					}
					<input type="password" id="sign-in-password" name="password" maxLength={512} placeholder="password" required={this.state.signIn}/>
					{
						!this.state.signIn && <input type="color" name="color" defaultValue="#0000ff"/>
					}
					<Button onClick={this.onSubmit.bind(this, false)} disabled={this.state.submitting} value="go!"/>
					{
						!this.state.signIn ?
							<Button onClick={this.onSubmit.bind(this, true)} disabled={this.state.submitting} value="go as guest"/> :
							null
					}
					<Button onClick={this.onNewAccount.bind(this)} disabled={this.state.submitting} value={this.state.signIn ? 'no account?' : 'already have an account?'}/>
				</form>
			</div>
		);
	}
}