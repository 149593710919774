import {tickInterval, updateTickInterval} from './global.js';
import {clamp} from '../utils.js';

/**
 * Implementation of an accurate (+-1 millisecond margin) game loop for the browser.
 */
class GameLoop {
	constructor() {
		/**
		 * A function that is called on every iteration of the loop.
		 * @type {function}
		 */
		this.callback = null;

		/**
		 * The time (in milliseconds) the game loop started.
		 */
		this.startTime = 0;

		/**
		 * The time (in milliseconds) the next tick will execute.
		 */
		this.nextTick = 0;

		/**
		 * The time (in milliseconds) the last state update was received from the server.
		 */
		this.lastUpdateTick = 0;

		/**
		 * The scale at which time passes. For example, a value of 0.5 causes the loop to run at half speed.
		 */
		this.timeScale = 1;

		this.adjusterMulti = 1000;

		/**
		 * The server and client game loop will inevitably drift out of sync. This value should be set to the number of milliseconds the client is ahead of the server, and the loop will automatically adjust to match the server.
		 *
		 * negative: the client is behind the server
		 * positive: the client is ahead of the server
		 */
		this.offset = 0;

		/**
		 * The number of ticks the offset will be used for before being reset to 0.
		 */
		this.offsetFor = 0;

		this._stop = false;
	}

	/**
	 * Resets the game loop's start time.
	 */
	resetStartTime() {
		const now = performance.now();
		this.startTime = now;
		this.nextTick = now;
	}

	/**
	 * Adjust the speed of the game loop in accordance with `physics` messages received from the server.
	 * @param {number} faster If `1`, the game loop should run faster. If `0`, the game loop should run slower.
	 */
	adjustScale(faster) {
		this.timeScale += faster / this.adjusterMulti;
		this.adjusterMulti = Math.max(this.adjusterMulti - 1, 500);
	}

	/**
	 * Returns the elapsed time (in milliseconds) since the game loop started.
	 */
	get elapsed() {
		return performance.now() - this.startTime;
	}

	/**
	 * Returns a fractional value (between 0 and 1) representing how far along the current tick is.
	 */
	get tickProgress() {
		return clamp((this.nextTick - performance.now()) / tickInterval, 0, 1);
	}

	/**
	 * Call this function when a state update is received from the server.
	 */
	updateTick() {
		this.lastUpdateTick = performance.now();
	}

	/**
	 * Returns the amount of time (in milliseconds) before a state update should be received from the server. For example, when this value is `0`, a state update should be received from the server.
	 */
	get timeToUpdate() {
		return Math.max(0, updateTickInterval - (performance.now() - this.lastUpdateTick));
	}

	_callback() {
		if (this.offsetFor > 0) {
			--this.offsetFor;
			if (this.offsetFor === 0) this.offset = 0;
		}

		this.callback();
	}

	_loop() {
		if (this._stop) {
			this._stop = false;
			return;
		}

		const scaledLen = tickInterval || 16.666666 / this.timeScale;
		const now = performance.now();
		if (now > this.nextTick) {
			// since requestAnimationFrame always runs at approximately 60 FPS, if we want to temporarily go faster, we will miss some ticks
			const diff = now - this.nextTick;
			if (diff > scaledLen) {
				const missedTicks = Math.floor(diff / scaledLen); // the number of ticks that would be missed if we were going faster then requestAnimationFrame
				const remainder = diff % scaledLen; // the amount of time left over after the missed ticks
				for (let i = 0; i < missedTicks; ++i) this._callback();
				this.nextTick = now + scaledLen - remainder + this.offset;
			} else {
				this.nextTick += scaledLen + this.offset;
			}

			this._callback();
			this.offset = 0;
		}

		requestAnimationFrame(this._loop.bind(this));
	}

	/**
	 * Start the game loop with the specified callback.
	 * @param {function} callback The function to call on every iteration of the loop.
	 */
	start(callback) {
		if (!this.callback) this.callback = callback;
		const now = performance.now();
		this.startTime = now;
		this.nextTick = now;
		this._loop();
	}

	/**
	 * Stops the game loop as soon as possible.
	 */
	stop() {
		this._stop = true;
	}
}

/**
 * The physics game loop, responsible for updating the physics simulation.
 */
const physics = new GameLoop();
export default physics;
