/**
 * Computes the scaled tick if there is easing.
 *
 * When there is easing, the entity's speed starts at 0 and increases to its normal speed (the speed the entity moves at if there were no easing). It reaches this normal speed in `ease` physics ticks.
 * While the current physics tick is less than `ease`, a scaled tick is computed with the formula `a*tick^2`. Once the current physics tick is greater than `ease`, the scaled tick returns to increasing linearly.
 * In order to compute `a` in `a*tick^2`, we must look for a value `a` that makes the derivative of `a*tick^2` equal to 1. The steps are:
 *
 * f(tick) = a*tick^2
 * f'(tick) = 2*a*tick
 * 1 = 2*a*tick
 * a = 1/(2*tick)
 *
 * Thus, the scaled tick function is defined with the given piecewise function:
 * scaledTick(ease, tick) =
 *   tick < ease = a*tick^2
 *   tick >= ease = tick - a*ease^2
 *
 * @param {number} ease The number of physics ticks until the entity begins moving at normal speed.
 * @param {number} tick The current, un-scaled physics tick.
 */
function scaledTick(ease, tick) {
	const a = 1 / (2 * ease);

	if (tick < ease) {
		return a * tick ** 2;
	} else {
		return tick - a * ease ** 2;
	}
}

/**
 * Returns the result of the function called with the given arguments.
 * @param {string} func
 * @param {Array<number>} args
 * @param {number} ease The number of physics ticks until the entity begins moving at normal speed.
 * @param {number} tick
 */
function calc(func, args, ease, tick) {
	if (ease) tick = scaledTick(ease, tick);

	switch (func) {
		case 'lin':
			return args[0] * tick;

		case 'sin':
			return args[0] * Math.sin(Math.PI * 2 / args[2] * (tick - args[1]));

		case 'cos':
			return args[0] * Math.cos(Math.PI * 2 / args[2] * (tick - args[1]));
	}
}

/**
 * Represents how an entity moves in the map.
 */
export default class Movement {
	/**
	 * @param {string} funcX The function used to calculate the entity's position in the x-axis during the round.
	 * @param {Array<number>} argsX The parameters used by `funcX`.
	 * @param {number} easeX How many physics ticks until the entity moves at normal velocity in the x-axis.
	 * @param {string} funcY The function used to calculate the entity's position in the y-axis during the round.
	 * @param {Array<number>} argsY The parameters used by `funcY`.
	 * @param {number} easeY How many physics ticks until the entity moves at normal velocity in the y-axis.
	 */
	constructor(funcX, argsX, easeX, funcY, argsY, easeY) {
		/**
		 * The functions used to calculate the entity's position during the round. Can be one of:
		 *
		 * `lin` - linear movement (velocity is constant)
		 * `sin` - sinusoidal movement (velocity varies)
		 * `cos` - cosinusoidal movement (velocity varies)
		 */
		this.func = {x: funcX, y: funcY};

		/**
		 * The arguments used by the functions. For each function type, the parameters are:
		 *
		 * `lin` - [m = velocity] (y=mx)
		 * `sin` - [a = amplitude, b = offset, t = period] (y = a * sin(tau / t * (x - b)))
		 * `cos` - [a = amplitude, b = offset, t = period] (y = a * cos(tau / t * (x - b)))
		 *
		 * where:
		 * `x` - the current physics tick in the round
		 * `y` - the calculated offset of the entity
		 * `t` - the number of physics ticks for the cycle to repeat
		 */
		this.args = {x: argsX, y: argsY};

		/**
		 * Determines the easing in the movement at the start of the round.
		 *
		 * The value should be a number that indicates how many physics ticks it will take for the entity to reach full speed. As reference, the player takes 40 physics ticks to reach maximum speed on standard settings.
		 */
		this.ease = {x: easeX, y: easeY};

		/**
		 * The previous position of the entity (one physics tick ago).
		 * @type {{x: number, y: number}}
		 */
		this.prev = null;

		/**
		 * The current position of the entity.
		 * @type {{x: number, y: number}}
		 */
		this.cur = null;

		/**
		 * The cached offset of the entity at a certain tick. If the game loop attempts to calculate the offset of the entity at the stored tick again, this value will be used for the offset instead of recalculating it. This can occur in multiplayer rooms since the game loop will run the same collision checks for each user at the same tick.
		 * @type {{tick: number, offset: {x: number, y: number}}}
		 */
		this.lastOffset = null;
	}

	/**
	 * Returns the velocity of the entity.
	 */
	get vel() {
		return this.cur && this.prev ? {
			x: this.cur.x - this.prev.x,
			y: this.cur.y - this.prev.y
		} : {
			x: 0,
			y: 0
		};
	}

	/**
	 * Computes the position of the entity at the given physics tick.
	 * @param {{x: number, y: number}} original The initial position of the entity.
	 * @param {number} tick The current physics tick in the round.
	 */
	computePosition(original, tick) {
		if (this.lastOffset?.tick === tick) {
			// prevent returning a reference
			return Object.assign({}, this.cur);
		} else {
			this.prev = this.cur;
			const offset = {
				x: calc(this.func.x, this.args.x, this.ease.x, tick),
				y: calc(this.func.y, this.args.y, this.ease.y, tick)
			};

			this.lastOffset = {tick, offset};
			this.cur = {
				x: original.x + offset.x,
				y: original.y + offset.y
			};

			return this.cur;
		}
	}

	/**
	 * Returns the JSON representation of the Movement.
	 */
	toJSON() {
		return {
			x: {
				def: {[this.func.x.charAt(0).toUpperCase() + this.func.x.substring(1)]: this.args.x},
				ease: this.ease.x,
			},
			y: {
				def: {[this.func.y.charAt(0).toUpperCase() + this.func.y.substring(1)]: this.args.y},
				ease: this.ease.y,
			},
		};
	}

	/**
	 * Parse the given JSON into a Movement object.
	 */
	static parse({x, y}) {
		const type = {
			x: Object.keys(x.def)[0],
			y: Object.keys(y.def)[0],
		};
		const args = {
			x: x.def[type.x],
			y: y.def[type.y],
		};

		return new Movement(type.x.toLowerCase(), args.x, x.ease, type.y.toLowerCase(), args.y, y.ease);
	}
}
