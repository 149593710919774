import {PureComponent} from 'react';

import Button from '../base/button.js';
import {emitter as ui} from '../../scripts/game/editor.js';
import {focus} from '../../scripts/utils.js';

/**
 * A map from the given difficulty number to its corresponding string.
 */
const difficulties = {
	1: 'Easy',
	2: 'Medium',
	3: 'Hard',
	4: 'Extreme',
};

/**
 * Sometimes, the Metadata component is initialized **after** the level editor emits the `metadata` event. This is because the EditorMain component has either the Metadata component or the Entity component pre-rendered, and it must wait for the `metadata` event itself before it can decide which component to show. A consequence of this is that the Metadata component will not be initialized with the correct state.
 *
 * This caches the data returned by the `metadata` event, which the Metadata component can use to initialize its state.
 * @type {{title: string, width: number, height: number, difficulty: number, players: string}?}
 */
let metadata = null;
ui.addEventListener('metadata', e => metadata = e.detail);

/**
 * Represents the UI used to modify the editor level's metadata.
 */
export default class Metadata extends PureComponent {
	constructor(props) {
		super(props);
		this.state = {
			/**
			 * The title of the level.
			 */
			title: 'No title',

			/**
			 * The width of the level (in cells).
			 */
			width: 0,

			/**
			 * The height of the level (in cells).
			 */
			height: 0,

			/**
			 * The difficulty of the level, in the range [1 (easy), 4 (extreme)].
			 */
			difficulty: 1,

			/**
			 * The recommended number of players for the level, described as a string.
			 *
			 * The value can be in one of the following forms:
			 * - '3': 3 players
			 * - '2+': 2 or more players
			 * - '2-4': 2 to 4 players
			 */
			players: '1',
		};

		this.onMetadata = e => {
			this.setState({
				title: e.detail.title,
				width: e.detail.width,
				height: e.detail.height,
				difficulty: e.detail.difficulty,
				players: e.detail.players,
			});
		};
	}

	componentDidMount() {
		if (metadata) {
			this.setState(metadata);
			metadata = null;
		}

		ui.addEventListener('metadata', this.onMetadata);
	}

	componentWillUnmount() {
		ui.removeEventListener('metadata', this.onMetadata);
	}

	/**
	 * The user changed a field.
	 * @param {Event} e
	 */
	onChange(e) {
		switch (e.target.name) {
			case 'width':
			case 'height':
			case 'difficulty':
				const value = parseInt(e.target.value);
				this.setState({[e.target.name]: value});
				ui.change('metadata', {[e.target.name]: value});
				if (e.target.name === 'difficulty') focus();
				break;

			default:
				this.setState({[e.target.name]: e.target.value});
				ui.change('metadata', {[e.target.name]: e.target.value});
				break;
		}
	}

	render() {
		return (
			<>
				<h2 id="editor-info-header">Level metadata</h2>
				<div id="editor-title">
					<label>Title</label>
					<input type="text" className="editor-field" placeholder="level title" name="title" value={this.state.title} onChange={this.onChange.bind(this)}/>
				</div>
				<div id="editor-size">
					<label>Size</label>
					<input type="number" className="editor-x" placeholder="width (in cells)" name="width" min="1" max="600" step="1" value={this.state.width} onChange={this.onChange.bind(this)}/>
					<input type="number" className="editor-y" placeholder="height (in cells)" name="height" min="1" max="600" step="1" value={this.state.height} onChange={this.onChange.bind(this)}/>
				</div>
				<div id="editor-difficulty">
					<label>Difficulty</label>
					<div id="difficulty-selector">
						<input type="radio" name="difficulty" value="1" id="1" checked={this.state.difficulty === 1} onChange={this.onChange.bind(this)}/>
						<input type="radio" name="difficulty" value="2" id="2" checked={this.state.difficulty === 2} onChange={this.onChange.bind(this)}/>
						<input type="radio" name="difficulty" value="3" id="3" checked={this.state.difficulty === 3} onChange={this.onChange.bind(this)}/>
						<input type="radio" name="difficulty" value="4" id="4" checked={this.state.difficulty === 4} onChange={this.onChange.bind(this)}/>
						<p style={{margin: 0}}>{difficulties[this.state.difficulty]}</p>
					</div>
				</div>
				<div id="editor-players">
					<label>Players</label>
					<input type="text" className="editor-field" placeholder="recommended number of players" name="players" pattern="^[0-9]+(\\+|\\-[0-9]+)?$" value={this.state.players} onChange={this.onChange.bind(this)}/>
				</div>
				<Button onClick={this.props.onCancel.bind(this)} value="close"/>
			</>
		);
	}
}
