// TODO: still contains typescript errors

import {Container, Graphics, Sprite} from 'pixi.js';
import {AdvancedBloomFilter} from 'pixi-filters';

import {Finish, Portal, Spawn, Wall} from './entities.js';
import {app} from './global.js';

/**
 * Schema for level metadata.
 */
export interface LevelMetadata {
	/**
	 * The level's ID.
	 *
	 * The ID is an object with one key, which is either:
	 *
	 * - `Official`: Holds numeric index of the level in the official levels list.
	 * - `User`: Holds the level UUID.
	 */
	id: {Official: number};

	title: string;
	author: string;

	/**
	 * The width of the map in tiles.
	 */
	width: number;

	/**
	 * The height of the map in tiles.
	 */
	height: number;

	/**
	 * The difficulty of the level from 1 to 4, where 1 is the easiest and 4 is the hardest.
	 */
	difficulty: number;

	/**
	 * String representing the number of players the level is designed for.
	 */
	players: string;

	/**
	 * The score goals for a bronze, silver, gold, and platinum medal.
	 */
	scoreGoals: Array<number>;

	/**
	 * The time limit for the level in seconds.
	 */
	timeLimit: number;

	/**
	 * Array of supported game mode IDs.
	 */
	modes: Array<number>;
}

/**
 * Returns the default level metadata.
 */
export function defaultLevelMetadata(): LevelMetadata {
	return {
		id: {Official: 0},
		title: 'No title',
		author: 'No author',
		width: 60,
		height: 60,
		difficulty: 0,
		players: '0',
		scoreGoals: [0, 0, 0, 0],
		timeLimit: 0,
		modes: [0],
	};
}

/**
 * Represents a level's geometry and its metadata.
 */
export interface LevelData {
	/**
	 * The level's metadata.
	 */
	info: LevelMetadata;

	/**
	 * The level's walls.
	 */
	walls: Array<Wall>;

	/**
	 * The level's finish blocks.
	 */
	finishes: Array<Finish>;

	/**
	 * The level's spawns.
	 */
	spawns: Array<Spawn>;

	/**
	 * The level's portals.
	 */
	portals: Array<Portal>;
}

/**
 * Represents a level; contains level geometry and spawns.
 * @class
 */
export default class Level implements LevelData {
	/**
	 * Metadata for the level.
	 */
	info: LevelMetadata;

	/**
	 * All walls in the level.
	 */
	walls: Array<Wall>;

	/**
	 * All finish blocks in the level. Must be the same length as `spawns`.
	 */
	finishes: Array<Finish>;

	/**
	 * All spawns in the level. Must be the same length as `finishes`.
	 */
	spawns: Array<Spawn>;

	/**
	 * All portals in the level.
	 */
	portals: Array<Portal>;

	/**
	 * The sprite of the level. It contains all parts of the level that are
	 * always visible and don't move, which includes most walls and portals.
	 */
	private sprite: Sprite | null;

	/**
	 *
	 * @param info Metadata for the level.
	 * @param walls All walls in the level.
	 * @param finishes All finish blocks in the level. Must be the same length
	 * as `spawns`.
	 * @param spawns All spawns in the level. Must be the same length as
	 * `finishes`.
	 * @param portals All portals in the level.
	 */
	constructor(
		info: LevelMetadata = defaultLevelMetadata(),
		walls: Array<Wall> = [],
		finishes: Array<Finish> = [],
		spawns: Array<Spawn> = [],
		portals: Array<Portal> = [],
	) {
		this.info = info;
		this.walls = walls;
		this.finishes = finishes;
		this.spawns = spawns;
		this.portals = portals;

		this.sprite = null;
	}

	/**
	 * Returns the width and height of the level in pixels.
	 */
	get size() {
		return {
			width: this.info.width * 10,
			height: this.info.height * 10,
		};
	}

	/**
	 * Returns true if the level fits on the viewport's current size.
	 */
	get fits() {
		const size = this.size;
		return size.width <= window.innerWidth && size.height <= window.innerHeight;
	}

	/**
	 * Returns the amount to scale the level by to comfortably fit it on the screen. Returns 1 if the level already fits on the screen.
	 */
	get scale() {
		const size = this.size;
		const expected = {x: window.innerWidth / size.width, y: window.innerHeight / size.height};
		return expected.x < 1 || expected.y < 1
			? Math.min(expected.x, expected.y) * 0.9
			: 1;
	}

	/**
	 * Computes the amount of padding (in pixels) between the level's borders and the browser viewport's borders if it were placed in the center of the screen. If the level is larger than the viewport, the level is first zoomed out according to `this.scale`, and the padding is calculated based on that.
	 */
	get padding() {
		const size = this.size;
		const scale = this.scale;

		// if the level with width 'w' and centered in the viewport fits, the padding on the left and right side is: (window.innerWidth - w) / 2
		// similar principle for the top and bottom
		return {
			x: (window.innerWidth - size.width * scale) / (2 * scale),
			y: (window.innerHeight - size.height * scale) / (2 * scale),
		};
	}

	/**
	 * Generates the level's difficulty string. The difficulty is an integer from 0 to 4 (inclusive) and is indicated by a ratio of solid dots (●) to blank dots (○).
	 */
	generateDifficultyString() {
		const diff = this.info.difficulty;
		return '●'.repeat(diff) + '○'.repeat(4 - diff);
	}

	/**
	 * Returns the portal that the given portal is paired with. Portals are
	 * paired if they are the same color. There is currently no validation
	 * to ensure that the portal only has one pair.
	 * @param portal The portal to find the pair of.
	 */
	pairPortal(portal: Portal) {
		return this.portals.find(p => p.color === portal.color && p !== portal);
	}

	/**
	 * Generate the sprite of the level, including the external level borders, storing it at `this.sprite`. The origin (0, 0) of the sprite will be located at the top-leftmost point of the external level wall. Pre-generating the sprite like this makes it much faster to render.
	 */
	generateSprite() {
		const container = new Container();
		const graphics = new Graphics();

		// draw external walls outside the level
		const size = this.size;
		const padding = this.padding;

		graphics
			.beginFill(0, 0.7)
			.drawRect(
				-padding.x,
				-padding.y,
				// the external walls will just be the window size
				// if the level is larger than the viewport, we use the level size along with the padding we previously calculated
				Math.max(window.innerWidth, size.width + 2 * padding.x),
				Math.max(window.innerHeight, size.height + 2 * padding.y),
			)
			.beginHole()
			.drawRect(0, 0, size.width, size.height)
			.endHole()
			.endFill();

		for (const w of this.walls) {
			// the wall must have no movement
			if (!w.m) w.draw(graphics, 0);
		}

		const glowGraphics = new Graphics();
		glowGraphics.filters = [
			new AdvancedBloomFilter({
				threshold: 0,
				bloomScale: 1.2,
				brightness: 1.2,
				blur: 6,
				quality: 5,
			}),
		];

		for (const p of this.portals) {
			p.draw(glowGraphics);
		}

		container.addChild(graphics, glowGraphics);

		const texture = app.renderer.generateTexture(container);
		this.sprite = new Sprite(texture);
		this.sprite.x = -padding.x;
		this.sprite.y = -padding.y;
	}

	/**
	 * Generate an SVG of the level. The SVG only contains walls and portals.
	 * @param size The size of the SVG.
	 */
	generateSVG(size: number) {
		return (
			<svg viewBox={`0 0 ${this.info.width * 10} ${this.info.height * 10}`} width={size} height={size} fill="currentColor">
				{
					this.walls.map((w, i) => {
						const pos = w.getComputedPosition(0);
						const d = w.s.data;
						switch (w.s.type) {
							case 'rect':
								return <rect key={i} x={pos.x - d.w / 2} y={pos.y - d.h / 2} width={d.w} height={d.h}/>;

							case 'circle':
								return <circle key={i} cx={pos.x} cy={pos.y} r={d.r}/>;
						}
					})
				}
				{
					this.portals.map((p, i) =>
						<rect key={i} fill={`#${p.color.toString(16).padStart(6, '0')}`} x={p.x - p.w / 2} y={p.y - p.h / 2} width={p.w} height={p.h}/>
					)
				}
			</svg>
		);
	}

	/**
	 * Returns the JSON representation of the level.
	 */
	toJSON() {
		return {
			info: Object.assign({id: {Official: 0}}, this.info),
			walls: this.walls.map(w => w.toJSON()),
			finishes: this.finishes.map(f => f.toJSON()),
			spawns: this.spawns.map(s => s.toJSON()),
			portals: this.portals.map(p => p.toJSON()),
		};
	}

	/**
	 * Parse the given JSON into a Level.
	 */
	static parse({info, walls, finishes, spawns, portals}: LevelData) {
		return new Level(
			info,
			walls.map(data => Wall.parse(data)),
			finishes.map(({x, y, w, h}) => new Finish(x, y, w, h)),
			spawns.map(({x, y, t}) => new Spawn(x, y, t)),
			portals.map(({color, x, y, w, h}) => new Portal(color, x, y, w, h)),
		);
	}
}
