import {Graphics} from 'pixi.js';
import {DropShadowFilter} from 'pixi-filters';

import {keys} from './keys.js';
import {clamp, lerp} from '../utils.js';

/**
 * Represents the game camera.
 * @class
 */
class Camera {
	constructor() {
		/**
		 * The position of the camera.
		 */
		this.pos = {
			/**
			 * The position of the target the camera is focused on.
			 */
			actual: {x: 0, y: 0},

			/**
			 * The current position of the camera, which is interpolated to the actual position.
			 */
			display: {x: 0, y: 0},
		};

		this.bounds = null;

		/**
		 * The scale at which to zoom the camera. The unzoomed scaled is 1.
		 */
		this.scale = 1;

		/**
		 * The amount of pixels the camera moves upon user input.
		 */
		this.speed = 4;

		/**
		 * All graphics to be drawn by the camera.
		 */
		this.graphics = new Graphics();

		/**
		 * All graphics to be drawn by the camera with a drop shadow effect.
		 */
		this.shadowedGraphics = new Graphics();
		this.shadowedGraphics.filters = [new DropShadowFilter({quality: 2})];
	}

	/**
	 * Set a bounding box that the camera cannot pass through.
	 * @param {number} left The left coordinate of the bounding box.
	 * @param {number} right The right coordinate of the bounding box.
	 * @param {number} top The top coordinate of the bounding box.
	 * @param {number} bottom The bottom coordinate of the bounding box.
	 */
	setBounds(left, right, top, bottom) {
		this.bounds = {left, right, top, bottom};
	}

	/**
	 * Restrict the actual camera bounds to the bounds set by `Camera#setBounds`,
	 */
	restrictToBounds() {
		if (this.bounds) {
			if (this.bounds.left >= this.bounds.right) { // this condition allows the camera to sit in the middle of the map if it fits entirely on the screen
				this.pos.actual.x = (this.bounds.left + this.bounds.right) / 2;
			} else {
				this.pos.actual.x = clamp(this.pos.actual.x, this.bounds.left, this.bounds.right);
			}

			if (this.bounds.top >= this.bounds.bottom) {
				this.pos.actual.y = (this.bounds.top + this.bounds.bottom) / 2;
			} else {
				this.pos.actual.y = clamp(this.pos.actual.y, this.bounds.top, this.bounds.bottom);
			}
		}
	}

	/**
	 * Set the camera's actual position.
	 * @param {number} x The new x-position of the camera.
	 * @param {number} y The new y-position of the camera.
	 */
	setPos(x, y) {
		this.pos.actual.x = x;
		this.pos.actual.y = y;
		this.restrictToBounds();
	}

	/**
	 * Set the camera's actual and display positions, effectively teleporting the camera.
	 * @param {number} x The new x-position of the camera.
	 * @param {number} y The new y-position of the camera.
	 */
	teleport(x, y) {
		this.pos.actual.x = this.pos.display.x = x;
		this.pos.actual.y = this.pos.display.y = y;
		this.restrictToBounds();
	}

	/**
	 * Interpolates the camera's display position to its actual position.
	 */
	move() {
		this.pos.display.x = lerp(this.pos.display.x, this.pos.actual.x, 0.1);
		this.pos.display.y = lerp(this.pos.display.y, this.pos.actual.y, 0.1);
	}

	/**
	 * Increment the camera's actual position.
	 * @param {number} x The x-value to increment by.
	 * @param {number} y The y-value to increment by.
	 */
	updatePos(x = 0, y = 0) {
		this.pos.actual.x += x;
		this.pos.actual.y += y;
		this.restrictToBounds();
	}

	/**
	 * Reads user input and updates the camera's actual position.
	 */
	readInput() {
		let x = 0;
		let y = 0;

		// move left / right
		if (keys['a'] || keys['ArrowLeft']) x -= this.speed;
		if (keys['d'] || keys['ArrowRight']) x += this.speed;

		// move up / down
		if (keys['w'] || keys['ArrowUp']) y -= this.speed;
		if (keys['s'] || keys['ArrowDown']) y += this.speed;

		if (x || y) this.updatePos(x, y);
	}

	/**
	 * Given a point in the world, return the point in screen coordinates. For example, if the camera is centered on (0, 0), passing (0, 0) to this function will return (window.innerWidth / 2, window.innerHeight / 2).
	 * @param {{x: number, y: number}} point The point in world coordinates.
	 */
	toScreen(point) {
		return {
			x: point.x - this.pos.display.x + window.innerWidth / 2,
			y: point.y - this.pos.display.y + window.innerHeight / 2,
		};
	}

	/**
	 * Given a point in screen coordinates, return the point in world coordinates. For example, if the camera is centered on (0, 0), passing (window.innerWidth / 2, window.innerHeight / 2) to this function will return (0, 0).
	 * @param {{x: number, y: number}} point The point in screen coordinates.
	 */
	toWorld(point) {
		return {
			x: point.x + this.pos.display.x - window.innerWidth / 2,
			y: point.y + this.pos.display.y - window.innerHeight / 2,
		};
	}
}

/**
 * The global game camera. All graphics rendered to the camera are treated as "part of the game world" and will be translated accordingly.
 */
const camera = new Camera();
export default camera;