import {api} from './api.js';
import Packet from './packet.js';
import {parseData} from './rule.js';

/**
 * Represents the connection back to the server.
 */
class Connection {
	/**
	 * @param {WebSocket} socket The socket used to communicate with the server. Calling `Connection#connect` will create one automatically.
	 */
	constructor(socket = null) {
		this.socket = socket;

		/**
		 * The message cache. This is utilized if the client attempts to send messages when the connection is not ready. Once the connection is established, the messages will be sent.
		 * @type {Array<ArrayBufferLike>}
		 */
		this.cache = [];

		/**
		 * The unique UUID for this connection, generated by the server.
		 * @type {string}
		 */
		this.uuid = null;

		/**
		 * Connection ping.
		 */
		this.pingProp = 0;

		/**
		 * An array of callback functions that are called upon establishing a connection.
		 * @type {Array<function({serverID: string, tickInterval: number, updateTickInterval: number, uuid: string, url: string}, {username: string, color: number, movementStyle: string}): void>}
		 */
		this.connectCallbacks = [];

		/**
		 * A map of packet types to callback functions, used to map the packet data into a different type. They are called before the data is forwarded to `this.callbacks`. The packet returned by the callback should be a **new** Packet, not the original one.
		 *
		 * The callback function can also return a `Promise<Packet>`, in case it needs to do asynchronous work such as making requests to the API. The connection will appropriately handle the Promise's resolution.
		 * @type {Map<string, function(Packet): Packet | Promise<Packet>>}
		 */
		this.middleware = new Map();

		/**
		 * An array of callback functions that are called when receiving server packets.
		 *
		 * Each callback function also includes a priority given as a string. The priority is used to determine the order in which the callbacks are called. Callbacks with the same priority are called in the order they were registered. The available priority levels (sorted from highest to lowest) are:
		 *
		 * - `internal`: Indicates the callback is used by the WebSocket connection or for internal game structures.
		 * - `game`: Indicates the callback is used for game logic.
		 * - `ui`: Indicates the callback is used for UI logic.
		 * @type {Array<{priority: string, types: Array<string>?, callback: function(Packet): void}>}
		 */
		this.callbacks = [];

		/**
		 * A queue containing the last packets received from the server, where the oldest packet is at the front of the queue (index 0).
		 *
		 * Parsing of packets make take some time, in which other packets can arrive. This means that the order of packets emitted to the callback functions might not be the same as the order of packets received from the server. To solve this, incoming data from the server is first added to this queue, and then the queue is processed in order.
		 * @type {Array<MessageEvent>}
		 */
		this.queue = [];

		/**
		 * Whether the queue is currently being processed.
		 */
		this.processingQueue = false;

		/**
		 * The ID of the game mode of the room the client is in. The game mode usually must be known, since some packets are specific to certain game modes.
		 * @type {number}
		 */
		this.gameMode = null;
	}

	/**
	 * Is the connection ready to be used?
	 * @type {boolean}
	 * @readonly
	 */
	get ready() {
		return this.socket.readyState === 1;
	}

	/**
	 * Set an interval that periodically pings the server and updates the `pingProp` property. Does nothing if the interval is already set.
	 */
	startPing() {
		if (this._pingInterval) return;
		this._pingInterval = setInterval(async () => this.pingProp = await this.ping(), 1000);
	}

	/**
	 * Stop the ping interval.
	 */
	stopPing() {
		clearInterval(this._pingInterval);
		this._pingInterval = null;
	}

	/**
	 * Pings the server, returning a Promise with an integer that indicates the network ping. If there is no response, an error is thrown.
	 * TODO: try reconnecting if no response is detected for too long
	 * TODO: bug where server does respond, but we don't detect it and error is thrown
	 */
	async ping() {
		const before = Date.now();
		await this.send('ping');
		return Date.now() - before;
	}

	/**
	 * Resolves when the connection is ready. Resolves instantly if the connection is already ready.
	 */
	waitReady() {
		if (this.ready) return Promise.resolve();
		const self = this;

		return new Promise((resolve, _) => {
			function checkReady() {
				resolve();
				self.socket.removeEventListener('open', checkReady);
			}

			this.socket.addEventListener('open', checkReady);
		});
	}

	/**
	 * Attempts to establish a WebSocket connection to the server, resolving once the connection is open and any cached messages are sent.
	 * @param {string} url The URL to connect to to establish a webSocket connection. This is provided by the server.
	 */
	async connect(url) {
		if (this.socket) this.socket.close();
		clearInterval(this._pingInterval);

		this.socket = new WebSocket(url);
		await this.waitReady();

		this.startPing();

		// begin listening and responding to messages
		this.socket.addEventListener('message', async message => {
			this.queue.push(message);
			this.processQueue();
		});

		// send cached messages
		for (const message of this.cache) this.socket.send(message);
		this.cache = [];
	}

	/**
	 * Process the queue of packets received from the server.
	 */
	async processQueue() {
		if (this.queue.length === 0) return;
		if (this.processingQueue) return;

		this.processingQueue = true;

		while (this.queue.length > 0) {
			const message = this.queue.shift();
			const data = await Connection.decodeEvent(message);

			// ignore pongs
			const byte = new DataView(data).getUint8(0);
			if (byte === 0x02) continue;

			const packet = parseData(data, this.gameMode);

			const p2 = await (this.middleware.has(packet.type)
				? this.middleware.get(packet.type)(packet)
				: packet);

			for (const listener of this.callbacks) {
				if (!listener.types || listener.types.includes(packet.type)) {
					listener.callback(p2);
				}
			}
		}

		this.processingQueue = false;
	}

	/**
	 * Send data back to the server.
	 * @param {ArrayBufferLike} encoded The data to send.
	 * @param {boolean} waitForAcknowledgement Whether to wait for the server to respond.
	 * @return {Promise<Packet>?} A promise with the server's response. This is null if waitForAcknowledgement is false.
	 */
	sendRaw(encoded, waitForAcknowledgement = true) {
		if (this.socket.readyState !== 1) return Promise.reject('Not connected to server.');
		const self = this;
		this.socket.send(encoded);

		if (!waitForAcknowledgement) return null;

		return new Promise((resolve, reject) => {
			function complete(result) {
				resolve(result);
				clearTimeout(timeout);
				self.socket.removeEventListener('message', receiveMessage);
			}

			async function receiveMessage(message) {
				const data = await Connection.decodeEvent(message);

				// we want to see if the server has acknowledged our request
				// assuming the server response follows the protocol laid out in `network.md`, we need to check these conditions:
				// - the server's packet starts with `0xf0`
				// - the first byte of our packet matches the second byte of the server's packet
				const encodedBytes = encoded;
				const dataBytes = new Uint8Array(data);
				if (dataBytes[0] === 0xf0 && encodedBytes[0] === dataBytes[1]) {
					complete(parseData(data, self.gameMode));
				} else if (encodedBytes[0] === 0x01 && dataBytes[0] === 0x02) { // special case for pings
					complete();
				}
			}

			const timeout = setTimeout(() => {
				reject({message: 'Server did not respond in time.', data: encoded});
				self.socket.removeEventListener('message', receiveMessage);
			}, 10000);

			this.socket.addEventListener('message', receiveMessage);
		});
	}

	/**
	 * Send the given packet type and data to the server, and ensure that the send was successful.
	 *
	 * If the send fails, this function will cache the message, then attempt to reestablish the connection. When this succeeds, the message will be sent again.
	 * @param {string} type The type of the data.
	 * @param {*} data The data to send.
	 * @param {boolean} waitForAcknowledgement Whether to wait for the server to respond.
	 * @return {Promise<Packet>?} A promise with the server's response. This is null if waitForAcknowledgement is false.
	 */
	async send(type, data, waitForAcknowledgement = true) {
		const encoded = Connection.encode(type, data);

		try {
			return await this.sendRaw(encoded, waitForAcknowledgement);
		} catch (e) {
			this.cache.push(encoded);

			if (this.socket.readyState === 0) {
				console.error('Failed to send message, but we are already reconnecting...', e);
			} else {
				console.error('Failed to send message. Attempting to reconnect...', e);
				await this.connect(this.socket.url);
			}

			return null;
		}
	}

	/**
	 * Register the specified callback function to be called when a connection is established.
	 * @param {function({serverID: string, tickInterval: number, updateTickInterval: number, uuid: string, url: string}, {username: string, color: number, movementStyle: string}): void} callback The callback function that will be called when a connection is established.
	 */
	registerConnectionListener(callback) {
		this.connectCallbacks.push(callback);
	}

	/**
	 * Register the specified middleware function to be called when a packet with the specified event is received.
	 * @param {string} event The event to listen for.
	 * @param {function(Packet): Packet} callback The callback function that will be called when a packet with the specified event is received.
	 */
	registerMiddleware(event, callback) {
		this.middleware.set(event, callback);
	}

	/**
	 * Register the specified callback function to be called when server events are received.
	 * @param {string} priority The priority of the callback. See the `this.callbacks` field for more information.
	 * @param {Array<string>} types The event types to listen for. If null, all events will be listened for.
	 * @param {function(Packet): void} callback The callback function that will be called when server events are received.
	 */
	registerListener(priority, types = null, callback) {
		// a higher priority score = lower priority
		// slightly weird but it means higher scores get sorted to the end of the array
		const priorityScore = {
			'internal': 0,
			'game': 1,
			'ui': 2,
		};

		for (let i = 0; i < this.callbacks.length; ++i) {
			if (priorityScore[priority] < priorityScore[this.callbacks[i].priority]) {
				this.callbacks.splice(i, 0, {priority, types, callback});
				return;
			}
		}

		this.callbacks.push({priority, types, callback});
	}

	/**
	 * Given a MessageEvent, obtain the binary data that was sent.
	 * @param {MessageEvent} event
	 * @return {Promise<ArrayBuffer>}
	 */
	static async decodeEvent(event) {
		return await event.data.arrayBuffer();
	}

	/**
	 * Encode data to be sent to the server in binary if possible. See network.md for more details on the format.
	 * @param {string} type The type of the data.
	 * @param {*} data The data to encode.
	 * @return {ArrayBufferLike}
	 */
	static encode(type, data) {
		switch (type) {
			// client pings the server
			case 'ping':
				return new Uint8Array([0x01]);

			// client sends its input
			case 'input':
				const arr = new Uint8Array(1 + 4 + 2 + 1);
				const view = new DataView(arr.buffer);
				view.setUint8(0, 0xa3);
				view.setFloat32(1, data.tick); // physics tick
				view.setUint16(5, data.seq); // sequence number
				view.setUint8(7, data.keys); // keys being pressed (represented as a bitfield)
				return arr;

			// client sends a chat message
			case 'chat':
				const bytes = new TextEncoder().encode(data);
				return new Uint8Array([
					0xa0,
					bytes.byteLength >> 8, // length of message
					bytes.byteLength,
					...bytes // message content
				]);

			// client is ready to start the round
			case 'ready':
				return new Uint8Array([0xa1]);

			// non-binary packets are no longer supported
			default:
				throw new Error(`Packet has no binary encoding: ${type}`);
		}
	}
}

/**
 * The connection to the server.
 */
export const connection = new Connection();

/**
 * Appends the username and color to the `player_join` event.
 */
connection.registerMiddleware('player_join', async packet => {
	const {username, color, movementStyle} = await api().users[packet.data].get();
	return new Packet('player_join', {uuid: packet.data, username, color, movementStyle}, true);
});

/**
 * Appends the username and color to the `spectator_join` event.
 */
connection.registerMiddleware('spectator_join', async packet => {
	const {username, color, movementStyle} = await api().users[packet.data].get();
	return new Packet('spectator_join', {uuid: packet.data, username, color, movementStyle}, true);
});

/**
 * Appends the username and color of all users in the room to the `room_join` event.
 */
connection.registerMiddleware('room_join', async packet => {
	const users = await api().auth.rooms.me.users.get();
	return new Packet('room_join', {...packet.data, users}, true);
});

/**
 * Update the connection's game mode.
 */
connection.registerListener('internal', ['room_join', 'room_modify'], packet => connection.gameMode = packet.data.gameMode);

/**
 * Temporarily stop pinging while in a round.
 */
connection.registerListener('internal', ['round_in_progress', 'countdown'], () => connection.stopPing());

/**
 * Resume pinging after a round.
 */
connection.registerListener('internal', ['room_abort', 'round_end'], () => connection.startPing());

/**
 * A helper function to initialize the connection.
 * @param {{serverID: string, tickInterval: number, updateTickInterval: number, uuid: string, url: string}} response The response from the server upon logging in.
 */
export async function initConnection(response) {
	const {uuid, url} = response;

	connection.uuid = uuid;
	await connection.connect(url);
	const userData = await api().users[uuid].get();
	for (const listener of connection.connectCallbacks) listener(response, userData);

	// FIXME
	// if (serverID) {
	// 	if (serverID !== s) { // reconnected to an updated server
	// 		// TODO: not actually implemented
	// 		outdatedClient = true;
	// 	}
	// } else {
	// 	serverID = s;
	// }
}